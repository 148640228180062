export interface ImportsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface Import {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum ImportActionTypes {    
  PUT_IMPORT_REQUEST = "@@import/PUT_IMPORT_REQUEST",
  PUT_IMPORT_SUCCESS = "@@import/PUT_IMPORT_SUCCESS",
  PUT_IMPORT_ERROR = "@@import/PUT_IMPORT_ERROR",
  GET_IMPORT_HISTORY_REQUEST = "@@import/GET_IMPORT_HISTORY_REQUEST",
  GET_IMPORT_HISTORY_SUCCESS = "@@import/GET_IMPORT_HISTORY_SUCCESS",
  GET_IMPORT_HISTORY_ERROR = "@@import/GET_IMPORT_HISTORY_ERROR",
}

export interface ImportState {
    readonly importList: any;
    readonly loading: boolean;
    readonly pagination: any;
}
