import React from 'react';
import { Container } from 'reactstrap';
import Recommendations from './Recommendations';

const Recommendation = () => {
    document.title = "Recommendations | Zilter";
    return (
        <React.Fragment>
            <Recommendations />
        </React.Fragment>
    );
};
export default Recommendation;


