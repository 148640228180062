export interface FinancesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Finance {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum FinanceActionTypes {
    GET_FINANCEMETADATA_REQUEST = "@@finance/GET_FINANCEMETADATA_REQUEST",
    GET_FINANCEMETADATA_SUCCESS = "@@finance/GET_FINANCEMETADATA_SUCCESS",
    GET_FINANCEMETADATA_ERROR = "@@finance/GET_FINANCEMETADATA_ERROR",
    GET_FINANCE_REQUEST = "@@finance/GET_FINANCE_REQUEST",
    GET_FINANCE_SUCCESS = "@@finance/GET_FINANCE_SUCCESS",
    GET_FINANCE_ERROR = "@@finance/GET_FINANCE_ERROR",
    GET_CHANNEL_FINANCES_REQUEST = "@@finance/GET_CHANNEL_FINANCES_REQUEST",
    GET_CHANNEL_FINANCES_SUCCESS = "@@finance/GET_CHANNEL_FINANCES_SUCCESS",
    GET_CHANNEL_FINANCES_ERROR = "@@finance/GET_CHANNEL_FINANCES_ERROR",
    GET_UNIVERSITY_FINANCES_REQUEST = "@@finance/GET_UNIVERSITY_FINANCES_REQUEST",
    GET_UNIVERSITY_FINANCES_SUCCESS = "@@finance/GET_UNIVERSITY_FINANCES_SUCCESS",
    GET_UNIVERSITY_FINANCES_ERROR = "@@finance/GET_UNIVERSITY_FINANCES_ERROR",
    PUT_FINANCE_REQUEST = "@@finance/PUT_FINANCE_REQUEST",
    PUT_FINANCE_SUCCESS = "@@finance/PUT_FINANCE_SUCCESS",
    PUT_FINANCE_ERROR = "@@finance/PUT_FINANCE_ERROR",
    DELETE_FINANCE_REQUEST = "@@finance/DELETE_FINANCE_REQUEST",
    DELETE_FINANCE_SUCCESS = "@@finance/DELETE_FINANCE_SUCCESS",
    DELETE_FINANCE_ERROR = "@@finance/DELETE_FINANCE_ERROR",
    POST_FINANCE_REQUEST = "@@finance/POST_FINANCE_REQUEST",
    POST_FINANCE_SUCCESS = "@@finance/POST_FINANCE_SUCCESS",
    POST_FINANCE_ERROR = "@@finance/POST_FINANCE_ERROR",
    DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_REQUEST = "@@finance/DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_REQUEST",
    DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_SUCCESS = "@@finance/DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_SUCCESS",
    DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_ERROR = "@@finance/DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_ERROR",
    SET_COMMISSION_FIELDS_REQUEST = "@@finance/SET_COMMISSION_FIELDS_REQUEST",
    SET_COMMISSION_FIELDS_SUCCESS = "@@finance/SET_COMMISSION_FIELDS_SUCCESS",
    GET_COMMISSION_FIELDS_REQUEST = "@@finance/GET_COMMISSION_FIELDS_REQUEST",
    GET_COMMISSION_FIELDS_SUCCESS = "@@finance/GET_COMMISSION_FIELDS_SUCCESS",
    GET_FINANACE_RULES_REQUEST = "@@finance/GET_FINANACE_RULES_REQUEST",
    GET_FINANACE_RULES_SUCCESS = "@@finance/GET_FINANACE_RULES_SUCCESS",
    GET_FINANACE_RULES_ERROR = "@@finance/GET_FINANACE_RULES_ERROR",
    GET_FINANCE_DETAILS_REQUEST = "@@finance/GET_FINANCE_DETAILS_REQUEST",
    GET_FINANCE_DETAILS_SUCCESS = "@@finance/GET_FINANCE_DETAILS_SUCCESS",
    GET_FINANCE_DETAILS_ERROR = "@@finance/GET_FINANCE_DETAILS_ERROR",
    GET_SUBAGENT_FINANCE_DETAILS_REQUEST = "@@finance/GET_SUBAGENT_FINANCE_DETAILS_REQUEST",
    GET_SUBAGENT_FINANCE_DETAILS_SUCCESS = "@@finance/GET_SUBAGENT_FINANCE_DETAILS_SUCCESS",
    GET_SUBAGENT_FINANCE_DETAILS_ERROR = "@@finance/GET_SUBAGENT_FINANCE_DETAILS_ERROR",
    GET_SUBAGENT_FINANCE_REQUEST = "@@finance/GET_SUBAGENT_FINANCE_REQUEST",
    GET_SUBAGENT_FINANCE_SUCCESS = "@@finance/GET_SUBAGENT_FINANCE_SUCCESS",
    GET_SUBAGENT_FINANCE_ERROR = "@@finance/GET_SUBAGENT_FINANCE_ERROR",
    DELETE_SUBAGENT_FINANCE_REQUEST = "@@finance/DELETE_SUBAGENT_FINANCE_REQUEST",
    DELETE_SUBAGENT_FINANCE_SUCCESS = "@@finance/DELETE_SUBAGENT_FINANCE_SUCCESS",
    DELETE_SUBAGENT_FINANCE_ERROR = "@@finance/DELETE_SUBAGENT_FINANCE_ERROR",
    GET_SUBAGENT_FINANCES_REQUEST = "@@finance/GET_SUBAGENT_FINANCES_REQUEST",
    GET_SUBAGENT_FINANCES_SUCCESS = "@@finance/GET_SUBAGENT_FINANCES_SUCCESS",
    GET_SUBAGENT_FINANCES_ERROR = "@@finance/GET_SUBAGENT_FINANCES_ERROR",
}

export interface FinanceState {
    readonly details: Finance | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: FinancesMetadata[];
    readonly channelfinances: any,
    readonly universityfinances: any,
    readonly loading: boolean;
    readonly metaLoading: boolean;
    readonly dataLoading: boolean;
    readonly commissionFields: any;
    readonly rules: any;
    readonly ruledetails: any;
    readonly subagentfinances: any;
}
