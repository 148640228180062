import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import { contractGetRequest, contractsMetadataGetRequest, contractUpdateRequest } from 'store/contracts/action';

const Details = (props: any) => {
    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>       
                <Section 
                    detailsGetAction={contractGetRequest}
                    metaDatagetRequest={contractsMetadataGetRequest}
                    updateAction={contractUpdateRequest}
                    model={"contracts"}
                    reducerState={"contracts"}
                    translater={"contract"}
                    label={"Contract"}
                />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Details);