import produce from 'immer';
import { Reducer } from "redux";
import { ContractsActionTypes, ContractsState } from "./types";


export const initialState: ContractsState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false
}
const reducer: Reducer<ContractsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ContractsActionTypes.GET_CONTRACTMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case ContractsActionTypes.GET_CONTRACTMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case ContractsActionTypes.GET_CONTRACTMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case ContractsActionTypes.GET_CONTRACT_REQUEST: {
            return { ...state, loading: true };
        }
        case ContractsActionTypes.GET_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case ContractsActionTypes.GET_CONTRACT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case ContractsActionTypes.POST_CONTRACT_REQUEST: {
            return { ...state, loading: true };
        }
        case ContractsActionTypes.POST_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ContractsActionTypes.POST_CONTRACT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ContractsActionTypes.GET_CONTRACTS_REQUEST: {
            return { ...state, loading: true, list: [] };
        }
        case ContractsActionTypes.GET_CONTRACTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ContractsActionTypes.GET_CONTRACTS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list: []};
        } 
        case ContractsActionTypes.PUT_CONTRACT_REQUEST: {
            return { ...state, loading: true };
        }
        case ContractsActionTypes.PUT_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                CONTRACTlist: action.payload.content
            };
        }
        case ContractsActionTypes.PUT_CONTRACT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ContractsActionTypes.DELETE_CONTRACT_REQUEST: {
            return { ...state, loading: true };
        }
        case ContractsActionTypes.DELETE_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ContractsActionTypes.DELETE_CONTRACT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as ContractsReducer };
