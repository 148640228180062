import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import RulesList from './Rules';

const Commissions = () => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title="Commission Rules" pageTitle="Commission Rules"  icon={"ri-building-4-line"} />
                    <Row>
                        <RulesList />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Commissions;