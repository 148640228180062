import React from 'react';
import { isMobile } from 'react-device-detect';

const ImageComponent = (props: any) => {
    const { src } = props;
    const containerStyle = {
        width: '100%',
        height: '20%',        
    }

    const imgStyle = {
        maxHeight: 50,
        minHeight: 40,
        objectFit: 'cover' as any,
    }

    const imgMobileStyle = {
        maxHeight: 40,
        minHeight: 20,
        objectFit: 'cover' as any,
    }

    return (
        <div className="image-container" style={containerStyle}>
            <img src={src} alt="" style={isMobile ? imgMobileStyle :  imgStyle} />
        </div>
    );
};

export default ImageComponent;


