import {combineReducers} from "redux";

import {AuthReducer} from "./auth/reducer";
import {AuthState} from "./auth/types";

import { userReducer } from "./user/reducer";
import { UserState } from "./user/types";

import { RoleReducer } from "./role/reducer";
import { RoleState } from "./role/types";

import {FormReducer } from "./form/reducer";
import { FormState } from "./form/types";

import { studentReducer } from "./student/reducer";
import { StudentState } from "./student/types";

import { PropertiesReducer } from "./properties/reducer";
import { PropertiesState } from "./properties/types";

import { ApplicationsReducer } from "./applications/reducer";
import { ApplicationsState } from "./applications/types";

import { UniversityReducer } from "./university/reducer";
import { UniversityState } from "./university/types";

import { CourseReducer } from "./course/reducer";
import { CourseState } from "./course/types";

import { CalendarReducer } from "./calendarSync/reducer";
import { CalendarState } from "./calendarSync/types";

import { CurrencyReducer } from "./currency/reducer";
import { CurrencyState } from "./currency/types";

import LayoutReducer from "./layouts/reducer";


import { SourceReducer } from "./sources/reducer";
import { SourceState } from "./sources/types";

import { CompanyReducer } from "./companies/reducer";
import { CompanyState } from "./companies/types";

import { DocumentReducer } from "./documents/reducer";
import { DocumentState } from "./documents/types";

import { ChannelReducer } from "./channels/reducer";
import { ChannelState } from "./channels/types";

import { FinanceReducer } from "./finance/reducer";
import { FinanceState } from "./finance/types";

import { RecommendReducer } from "./recommendation/reducer";
import { RecommendState } from "./recommendation/types";

import { InvoiceReducer } from "./invoices/reducer";
import { InvoiceState } from "./invoices/types";

import { BanksReducer } from "./banks/reducer";
import { BanksState } from "./banks/types";

import { NotesReducer } from "./notes/reducer";
import { NotesState } from "./notes/types";

import { TasksReducer } from "./tasks/reducer";
import { TasksState } from "./tasks/types";

import { CustomFormReducer } from "./customform/reducer";
import { CustomFormState } from "./customform/types";

import { SubagentReducer } from "./subagents/reducer";
import { SubagentState } from "./subagents/types";

import { ViewReducer } from "./views/reducer";
import { ViewState } from "./views/types";

import { whatsappReducer } from "./whatsapp/reducer";
import { WhatsappState } from "./whatsapp/types";

import { ProductsReducer } from "./products/reducer";
import { ProductsState } from "./products/types";

import { DealsReducer } from "./deals/reducer";
import { DealsState } from "./deals/types";

import { TemplatesReducer } from "./templates/reducer";
import { TemplatesState } from "./templates/types";

import { ReportReducer } from "./report/reducer";
import { ReportState } from "./report/types";

import { PricingReducer } from "./pricing/reducer";
import { PricingState } from "./pricing/types";

import { ImportState } from "./import/types";
import { ImportReducer } from "./import/reducer";

import { ContractsState } from "./contracts/types";
import { ContractsReducer } from "./contracts/reducer";

import { AuthorizationlettersState } from "./authorizationletters/types";
import { AuthorizationlettersReducer } from "./authorizationletters/reducer";

import { AgencychangeformsState } from "./agencychangeforms/types";
import { AgencychangeformsReducer } from "./agencychangeforms/reducer";

import { EmailsState } from "./emails/types";
import { EmailsReducer } from "./emails/reducer";

import { MeetingsState } from "./meetings/types";
import { MeetingsReducer } from "./meetings/reducer";

import { CallsState } from "./calls/types";
import { CallsReducer } from "./calls/reducer";


import { CommissionsState } from "./commissions/types";
import { CommissionsReducer } from "./commissions/reducer";

export interface ApplicationState {
    auth: AuthState;
    user: UserState;
    role: RoleState;
    form: FormState;
    student: StudentState;
    properties: PropertiesState;
    applications: ApplicationsState;
    universities: UniversityState;
    courses: CourseState;
    calendarSync: CalendarState;
    sources: SourceState;
    channels: ChannelState;
    document: DocumentState;
    finance: FinanceState;
    companies: CompanyState;
    invoices: InvoiceState;
    recommend: RecommendState;
    banks: BanksState,
    customform: CustomFormState;
    subagents: SubagentState;
    views: ViewState;
    currency: CurrencyState;
    whatsapp: WhatsappState;
    products: ProductsState;
    deals: DealsState;
    templates: TemplatesState;
    report: ReportState;
    import: ImportState;
    pricingLink: PricingState;
    contracts: ContractsState,
    authorizationletters: AuthorizationlettersState,
    agencychangeforms:AgencychangeformsState,
    notes:NotesState,
    tasks:TasksState,
    emails:EmailsState,
    meetings:MeetingsState,
    calls:CallsState,
    commissions: CommissionsState
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = () =>
    combineReducers({
        auth: AuthReducer,
        Layout: LayoutReducer,
        user: userReducer,
        role: RoleReducer,
        form: FormReducer,
        student: studentReducer,
        properties: PropertiesReducer,
        applications: ApplicationsReducer,
        courses: CourseReducer,
        universities: UniversityReducer,
        channels: ChannelReducer,
        sources: SourceReducer,
        calendarSync: CalendarReducer,
        document: DocumentReducer,
        finance: FinanceReducer,
        companies: CompanyReducer,
        invoices: InvoiceReducer,
        recommend: RecommendReducer,
        banks: BanksReducer,
        customform: CustomFormReducer,
        subagents: SubagentReducer,
        views: ViewReducer,
        currency: CurrencyReducer,
        whatsapp: whatsappReducer,
        products: ProductsReducer,
        deals: DealsReducer,
        templates: TemplatesReducer,
        report: ReportReducer,
        pricingLink: PricingReducer,
        import: ImportReducer,
        contracts: ContractsReducer,
        authorizationletters: AuthorizationlettersReducer,
        agencychangeforms:AgencychangeformsReducer,
        notes: NotesReducer,
        tasks: TasksReducer,
        emails: EmailsReducer,
        meetings: MeetingsReducer,
        calls: CallsReducer,
        commissions: CommissionsReducer
    });
