import { L } from "@fullcalendar/list/internal-common";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import SelectDropdown from "./SelectDropdown";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    Input,
    Col,
    Row,
    InputGroup,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { SetInvoceData } from "store/auth/action";
import { isJson } from "utils";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";

const StudentsList: React.FC<any> = ({ handleRuleSelect, updatedRow, disabled, handleselect, mainList, show, onCloseClick, selectedlist, ChannelDetail, onhandleChange, paymentHandle }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const navigate = useNavigate();

    const isChecked = (id: any) => {
        if (selectedlist && selectedlist.length) {
            return selectedlist.some((course: any) => {
                let valuesJson = course.valuesJson && isJson(course.valuesJson) ? JSON.parse(course.valuesJson) : {}
                return valuesJson.course_name === id;
            });
        }
    };
    const createStudents = () => {
        let details = ChannelDetail && ChannelDetail.valuesJson && isJson(ChannelDetail.valuesJson) ? JSON.parse(ChannelDetail.valuesJson) : {};
        let channel = { ChannelDetail, ...details, id: id }
        dispatch(SetInvoceData(selectedlist, channel))
        navigate("/channels/invoices/create-invoice")
    }
    const onSelectValue = (itemdetails: any, newValues: any) => {

    }

    const settlementOptions: any = [{
        label: 'Bursary',
        value: 'bursary',
    },
    {
        label: 'Discount',
        value: 'discount',
    },
    {
        label: 'Clawback',
        value: 'clawback',
    },
    {
        label: 'Dispute',
        value: 'dispute',
    }]

    const onSearchHandle = () => {

    }


    return (<>
        <Modal
            direction="end"
            isOpen={show}
            toggle={onCloseClick}
            fullscreen
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Select Students For Invoice
            </ModalHeader>
            <ModalBody>
                <Row className='hstack gap-3'>
                    <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                        <div className="search-box">
                            <Input className='border-0' placeholder="Search..." style={{ padding: '1.2rem 0.9rem' }} onChange={onSearchHandle} value={search}></Input>
                            {search === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => setSearch("")}></i>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {mainList && mainList.length ?
                        <div className="table-responsive">
                            <Table responsive={true} hover className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Student
                                        </th>
                                        <th>
                                            University
                                        </th>
                                        <th>
                                            Course
                                        </th>
                                        <th>
                                            Commission Schedule
                                        </th>
                                        <th>
                                            Payment No
                                        </th>
                                        <th>
                                            Expected Commission
                                        </th>
                                        <th>
                                            Actual Commission
                                        </th>
                                        <th>
                                            Difference
                                        </th>
                                        <th>
                                            Settlement type
                                        </th>
                                        <th>
                                            Comments
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="task-list">
                                    {mainList && mainList.length ? mainList.map((item: any, key: any) => {
                                        let itemdetails = { ...item.valuesJson, ...item };
                                        let options: any = [];
                                        let x = item?.commissions?.length ? item?.commissions.map((rule: any) => {
                                            if (rule?.ruleApplied && rule?.id === id) {
                                                options.push({ label: rule?.ruleApplied?.name, value: rule?.ruleApplied?.id, rule: rule })
                                            }
                                        }) : []
                                        let option: any = options?.length ? options.find((obj: any) => obj?.value === item?.commission?.id || obj?.value === item?.channels?.ruleApplied?.id) : null                                       
                                        const dataArray = option?.rule?.paymentSchedule ? Object.keys(option?.rule?.paymentSchedule).map(key => option?.rule?.paymentSchedule[key]) : [];
                                        let paymentOptions: any = [];
                                        let count = 1;
                                        dataArray?.length && dataArray?.map((item: any, i: any) => {
                                            [...Array(item?.paymentCount)].map((_, index) => {
                                                const commissionKey = `payment${index + 1}Commission`;
                                                paymentOptions.push({
                                                    label: `Payment ${count}`,
                                                    value: `payment${count}`,
                                                    commission: item[commissionKey],                                                    
                                                });
                                                count++;
                                            })
                                        })
                                        return <tr key={key} style={{ maxHeight: 100 }}>
                                            <td>
                                                <div className="d-flex align-items-start">
                                                    <div className="flex-shrink-0 me-0">
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input me-0"
                                                                type="checkbox"
                                                                value={item.id}
                                                                id={"todo" + item.id}
                                                                onChange={(e) => handleselect(item, e.target.checked, option?.rule)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label >{itemdetails["student"] && itemdetails["student"]["name"] ? itemdetails["student"]["name"] : 'NA'}</label>
                                            </td>
                                            <td><label >{itemdetails["institution_name"] && itemdetails["institution_name"]["name"] ? itemdetails["institution_name"]["name"] : 'NA'}</label></td>
                                            <td><label >{itemdetails["courses"] && itemdetails["courses"]["name"] ? itemdetails["courses"]["name"] : 'NA'}</label></td>
                                            <td>
                                                <ButtonGroup className="w-100">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            tag="button"
                                                            title={option && option.label ? option.label : 'Select Commission Rule...'}
                                                            className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary border-dark border-opacity-25"
                                                            style={{ width: 200, color: 'inherit' }}
                                                        >
                                                            <span className="text-nowrap text-truncate text-nowrap-ellipsis">{option && option.label ? option.label : 'Select Commission Rule...'}</span> <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdownmenu-primary border-dark border-opacity-50">
                                                            <SimpleBar style={{ maxHeight: 300, width: 250 }} >
                                                                {options && options.length ? options.map((option: any, index: number) => (
                                                                    <DropdownItem
                                                                        key={index}
                                                                        onClick={(e) => handleRuleSelect(item, option)}
                                                                    >
                                                                        {option ? option.label : ''}
                                                                    </DropdownItem>
                                                                )) : null}
                                                            </SimpleBar>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </ButtonGroup>
                                            </td>
                                            <td>
                                                <SelectDropdown
                                                    title="Select"
                                                    multi={true}
                                                    options={paymentOptions}
                                                    placeholder={'Select number of Payment..'}
                                                    property={{}}
                                                    // values={itemselectedOption}
                                                    disabled={!dataArray?.length}
                                                    onChange={(newValues: any) => { paymentHandle(newValues, 'paymentnos', item.id, option) }}
                                                    isSearchable
                                                />
                                            </td>
                                            <td>
                                                {option?.rule?.commission}
                                            </td>
                                            <td>
                                                <Input value={item?.invoicedCommission} placeholder="Enter actual Commission" onChange={(e) => onhandleChange(e.target.value, 'invoicedCommission', item.id, option)} />
                                            </td>
                                            <td>
                                                {item?.difference}
                                            </td>
                                            <td>
                                                <SelectDropdown
                                                    title="Select"
                                                    options={settlementOptions}
                                                    placeholder={'Select Type...'}
                                                    property={{}}
                                                    values={settlementOptions.find((option: any) => option.value === itemdetails?.settlementtype)}
                                                    onChange={(newValue: any) => { onhandleChange(newValue, 'settlementType', item.id) }}
                                                    isSearchable
                                                />
                                            </td>
                                            <td style={{ minWidth: 200 }}>
                                                <FormBuilder
                                                    type={"textarea"}
                                                    rows={3}
                                                    defaultValue={item?.comment ? item?.comment : ''}
                                                    value={item?.comment ? item?.comment : ''}
                                                    name={"comment"}
                                                    placeholder={"Enter Comment here..."}
                                                    className="w-100 h-100"
                                                    onChange={(value: any) => onhandleChange(value, 'comment', item.id)}
                                                    isEditState={true}
                                                />
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </Table>
                        </div>
                        : <div className="hstack align-items-center justify-content-center"
                            style={{ minHeight: 200 }}>
                            <h5 className="fw-bold">No Students to create Invoice</h5>
                        </div>}


                </Row>
            </ModalBody>
            {mainList && mainList.length ?
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createStudents()}
                        disabled={disabled}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        Cancel
                    </Button>
                </div>
                : null}
        </Modal>
    </>
    );
};

export default StudentsList;
