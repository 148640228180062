import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup, Modal, ModalHeader, ModalBody, Label, Badge } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import { withTranslation } from 'react-i18next';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { viewGetRequest } from 'store/views/action';
import QuickFilter from '../../Filter/QuickFilter';
import FeatherIcon from "feather-icons-react";
import UserAvatar from '../../UserAvtar/ListingUserAvatar';
import EditModal from './innerComponent/EditModal';
import { checkTheyOwn, handleSelectValue, isJson } from 'utils';
import { documentDownload } from 'store/documents/action';
import AddEntryModal from './innerComponent/AddEntryModal';
import { taskUpdateRequest } from 'store/tasks/action';

interface ReducerState {
    [key: string]: any;
}

interface FiltersData {
    filters: any[];
    sorts: any[];
}


const Listing = (props: any) => {
    const { viewId, getListAction, deleteAction, detailsGetAction, reducerState, model, createAction, parent, parentId, checkbox, parentField, initializeData, details, selectedTask, modal_positionTop, setmodal_positionTop, studentId, applicationId, mainModel, tick } = props;

    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ['metaDataFields', 'list', 'dataLoading', 'pagination', "metaLoading"]);
    const derivedState: any = useSelector(reducer);
    const { metaDataFields, list, dataLoading, metaLoading } = derivedState;
    let paginationDetails = derivedState.pagination;

    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const viewDetails: any = useSelector((state: ApplicationState) => state.views.view);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const viewsloading = useSelector((state: ApplicationState) => state.tasks.loading);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [columns, setColumns] = useState<any>([])
    const [view, setView] = useState<any>([])
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [sortBy, setSortBy] = useState<any>("dueDate")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [search, setSearch] = useState<string>("");
    const [record, setRecord] = useState<any>({});
    const [metaData, setMetaData] = useState([]);
    const [ListData, setList] = useState([]);
    const [studentsJson, setstudentsJson] = useState<any>([]);
    const [fields, setFields] = useState<any>(null);
    const [communicate, setCommunicate] = useState<any>(null);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [pagesList, setPagesList] = useState<any>([]);
    const [filter, setFilter]: any = useState(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [isCreateModal, setCreateOpen] = useState<boolean>(false);
    const [updateRow, setUpdateRow] = useState<any>(null);
    const [statusForm, setStatusForm] = useState<any>(null);
    const [url, setUrl] = useState('')
    const [selectedSearchField, setSelectedSearchField]: any = useState({ value: 'all', label: 'All' });
    const [leftFields, setLeftFields] = useState<any>([]);
    const [modalTodo, setModalTodo] = useState(false)
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    // Handle PageSize Change
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value, page: 0 })
    }

    // Set access based on user role and permissions
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCommunicate("all")
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                setAccess(access)
            }
            let communicateaccess = subLevelPermissionsList?.communicate;
            if (communicateaccess && communicateaccess.length) {
                communicateaccess.map((item: any, index: any) => {
                    if (item.name.toLowerCase() == 'communicate') {
                        item.value == "" || item.value == "none" ? setCommunicate(null) : setCommunicate(item.value)
                    }

                })
            }
        }
    }, [subLevelPermissionsList, userProfile])

    // Update metadata fields
    useEffect(() => {
        let index = -1;
        let dataFields = metaDataFields
		dataFields?.length && dataFields?.map((field: any, i: any) => {
			let item = field &&
				field?.valuesJson && isJson(field.valuesJson)
				? JSON.parse(field.valuesJson)
				: field.valuesJson;
			if (item?.key === 'title') return index = i;
            const elem = dataFields.splice(index, 1)[0];
            dataFields.unshift(elem);
		});
        setMetaData(dataFields);
    }, [metaDataFields]);

 
    // Fetch list data with filters and pagination
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }
                // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData: FiltersData = {
                filters: [{
                    ...filter,
                    search: null,
                    parent:[]
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            if (parent?.length > 0 ) {
                filtersData.filters[0].parent = parent;
            }
            dispatch(getListAction(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi, updatedCurrency, parentField])

    // Clear filter selections
    const clearFilter = () => {
        setSearch("")
        setSortBy("createdAt")
        setSortOrder("desc")
        setPagination({
            ...pagination,
            page: 0,
            first: true,
            last: false,
        })
    }

    // Handle search input change
    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    // Apply filter changes
    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }

    // Handle row click
    const handleLeadClick = (lead: any) => {
        setRecord(lead)
        dispatch(detailsGetAction(lead.id))
    };


    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

    // Handle delete action
    const onDelete = () => {
        const handleSuccess = (body: any) => {
            initializeData && initializeData([])
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(deleteAction(record.id, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])


    // Check view access permissions
    const handleAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }

    // Check edit access permissions
    const handleEditAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };
    
    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", key); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }

    const Status = ({ status }: any) => {
        switch (status) {
            case "NEW":
                return <span className="badge bg-info-subtle  text-info text-uppercase">{status}</span>;
            case "PENDING":
                return <span className="badge bg-warning-subtle  text-warning text-uppercase">{status}</span>;
            case "INPROGRESS":
                return <span className="badge bg-secondary-subtle text-secondary  text-uppercase">{status}</span>;
            case "COMPLETED":
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
            default:
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
        }
    };
    const Priority = ({ priority }: any) => {
        switch (priority) {
            case "HIGH":
                return <span className="badge bg-danger text-uppercase">{priority}</span>;
            case "MEDIUM":
                return <span className="badge bg-warning text-uppercase">{priority}</span>;
            case "LOW":
                return <span className="badge bg-success text-uppercase">{priority}</span>;
            default:
                return <span className="badge bg-success text-uppercase">{priority}</span>;
        }
    };


    // Rendering Cell value
    const renderCell = (cellProps: any, fields: any) => {
        let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
        let rendervalue = cellProps.row.original[fields.key];
        let isMergeRender = false;
        if(fields.key === 'title') {
            return <div>
                <div style={{ width: "180px" }} className="d-flex align-items-center">
                    <div className="text-nowrap text-truncate text-nowrap-ellipsis">
                        <label>{cellProps.row.original.title}</label>
                    </div>
                    {moment(cellProps.row.original.due_date).isBefore(moment()) && cellProps.row.original.status?.toLowerCase() !== "completed" ? (
                        <span className="align-self-baseline ml-2">
                            <Badge color="warning" pill>Overdue</Badge>
                        </span>
                    ) : null}
                </div>
            </div>
        }
        if(fields.key === 'status') {
            return <Status status={cellProps.row.original[fields.key].toUpperCase()} />
        }
        else if(fields.key === 'priority') {
            return <Priority priority={cellProps.row.original[fields.key].toUpperCase()} />
        }
        else if (fields?.mergeColumns?.columns) {
            let value = fields?.mergeColumns?.columns
            let values = value.split(",")
            let newValue = "";
            values?.length && values.map((item: any) => {
                newValue = cellProps.row.original[item] ? `${newValue ? newValue : ""} ${cellProps.row.original[item]}${fields.mergeColumns?.separator}` : ""
            })
            rendervalue = cellProps.row.original[fields.key] || newValue;
            isMergeRender = true
        }
        else if (fields.tableRenderType == 'hyperlink' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.key])} />
            </div>
        }
        else if (fields.type === 'file' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(cellProps.row.original[fields.key], `${fields.key}_${cellProps.row.original.id}`)} />
            </div>
        }
        else if (fields.isMulti || fields.type == 'selectboxes') {
            let value = cellProps.row.original?.[fields.key]
            let arr = value && value.length && value.map((item: any) => {
                if (item?.value) return item.value
                else return item
            })
            rendervalue = <div>
                {arr?.length && arr.map((item: any) => {
                    let label = handleSelectValue(item, fields.values);
                    return <div className='ms-0'>
                        <span className="badge bg-success">{label}</span>
                    </div>
                })}
            </div>
        }
        else if (fields.tableRenderType == 'tag') {
            let value = cellProps.row.original?.[fields.key]
            let intakearray = value?.split(",")
            return <div>
                {intakearray?.length && intakearray.map((item: any) => {
                    return <div className='ms-0'>
                        <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                    </div>
                })}
            </div>
        }
        else if (fields?.key && fields?.optionLabel) {
            rendervalue = cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] ? cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] : 'NA'
        }
        else if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
            rendervalue = handleSelectValue(cellProps.row.original[fields.key], fields.values);
        }
        else if (fields.type === "texteditor") {
            return <span className="text-ellipsis"><div className='text-truncate-two-lines' style={{ maxWidth: 200, overflow: 'hidden', maxHeight: 100 }} dangerouslySetInnerHTML={{ __html: cellProps?.row?.original?.[fields.key] }} /></span>
        }
        else if (fields.type === "datetime") {
            return <span className="text-ellipsis">{cellProps.row.original[fields.key] ? moment(cellProps.row.original[fields.key]).format('lll') :
                ''}</span>
        }
        else if (fields.type === "textarea") {
            return <span className="text-ellipsis"><span className='text-truncate-two-lines' style={{ maxWidth: 200, overflow: 'hidden', maxHeight: 100 }}>{cellProps?.row?.original?.[fields.key]}</span></span>
        }
        return <div className="d-flex align-items-center justify-content-between">                                      {
            fields.isPrimary == true ?
                <Row style={{ width: 300 }} className='justfy-content-start align-items-center'>
                    <Col md={12} lg={12} className={'hstack justify-content-between'}>
                        <Link to={`${url}/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis">
                            <div className='hstack gap-1 '>
                                <UserAvatar img={record && record.img} firstName={cellProps.row.original[fields.key] || cellProps.row.original['first_name']} />
                                {isMergeRender ? rendervalue : cellProps.row.original[fields.key]}
                            </div>
                        </Link>
                    </Col>
                </Row>
                :
                <div>
                    {rendervalue && typeof rendervalue !== 'object' ? rendervalue : null}
                </div>
        }
        </div>
    }

    // Generate view columns
    const viewsColumns = () => {
        let columns: any[] = [];
        fields && fields.length && fields.map((item: any) => {
            const { extras } = item;
            if (metaData && metaData.length > 0) {
                metaData.map((fieldName: any) => {
                    if (fieldName.id == item.id) {
                        let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : fieldName?.valuesJson ? fieldName?.valuesJson : {}
                        let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                        let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                        if (isFieldviewaccess) {
                            let newColumn = {
                                Header: _.startCase(fields.label),
                                accessor: fields.key,
                                id: fields.key,
                                fieldid: fieldName.id,
                                filterable: true,
                                isSortable: false,
                                disableFilters: true,
                                valueJson: fields,
                                meta: fieldName,
                                show: extras.show,
                                isEditAccess: isFieldEditAccess,
                                isPrimary: fields.isPrimary,
                                Cell: (cellProps: any) => {
                                    return renderCell(cellProps, fields);
                                }
                            }
                            columns.push(newColumn)
                        }
                    }
                });
            }
        })
        if (metaData && metaData.length > 0 && fields && fields.length) {
            const result = metaData.filter((item: any) => !fields.some((field: any) => (item.id === field.id)))
            if (result && result.length > 0) {
                result.map((fieldName: any) => {
                    let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                    let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                    let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                    let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                    if (isFieldviewaccess) {
                        let newColumn = {
                            Header: _.startCase(fields.label),
                            accessor: fields.key,
                            id: fields.key,
                            fieldid: fieldName.id,
                            filterable: true,
                            isSortable: false,
                            disableFilters: true,
                            valueJson: fields,
                            meta: fieldName,
                            show: fields.tablecolumn,
                            isEditAccess: isFieldEditAccess,
                            isPrimary: fields.isPrimary,
                            Cell: (cellProps: any) => {
                                renderCell(cellProps, fields);
                            }
                        }
                        columns.push(newColumn)
                    }
                });
            }
        }
        return columns;
    }

    // Generate default view columns
    const defaultViewColumn = () => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : fieldName?.valuesJson ? fieldName?.valuesJson : {}
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                if (isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        fieldid: fieldName.id,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: fields.tablecolumn,
                        isEditAccess: isFieldEditAccess,
                        isPrimary: fields.isPrimary,
                        Cell: (cellProps: any) => {
                            return renderCell(cellProps, fields);
                        }
                    }
                    columns.push(newColumn)
                }
            });
        }
        return columns
    }

    useEffect(() => {
        let columns: any[] = [];
        if (viewId) columns = viewsColumns();
        else columns = defaultViewColumn();
        const nameColumnIndex = columns.findIndex(column => column.isPrimary == true);
        if (nameColumnIndex !== -1) {
            const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
            columns.unshift(nameColumn); // Add it to the beginning
        }
        if (columns && columns.length > 0) {
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        if (metaData && metaData.length > 0) {
            let datafields: any = [];
            metaData.forEach((fieldName: any) => {
                let field = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {};
                if (field.filter && (field.type === 'textfield' || field.type === 'textarea')) {
                    datafields.push({ label: field.label, value: field.key })
                }
            })
            setFieldOptions(datafields)
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
        setLeftFields(columns)
    }, [metaData, userProfile, fields, communicate, viewId]);



    const execute = () => {
        let a = isEqual(list, studentsJson)
        return !a
    }

    // Update list data
    useEffect(() => {
        const parsedData: any = list && list.length ? list.map((item: any) => {
            const valuesJson = item.valuesJson;
            let values = { ...valuesJson, ...item}
            return values
        }) : []
        let data = parsedData && parsedData.length ? parsedData : []
        setList(data)
        setstudentsJson(list)
    }, [execute()])


    // Handle sort changes
    const handleSort = (columnName: any) => {
        setPagination({ ...pagination, page: 0 })
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
        }
    };

     const handleCompleteTask = (item: any) => {    
        let assigned_by= userProfile?.id
        let status = null;
        if (tick) {
            status = "completed";
        } else {
            status = "new";
        }
        const data = {
            status: status,
            assigned_by:assigned_by
        }
        const handleSuccess = (): void => {
            setmodal_positionTop(false);
            setTriggerApi((triggerApi: boolean) => !triggerApi)       
            };
        const handleError = (): void => {
            // Handle error if needed
        };
        dispatch(taskUpdateRequest(item?.id, data, handleSuccess, handleError));
    }


    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            <Modal isOpen={modal_positionTop} toggle={() => setmodal_positionTop(!modal_positionTop)} className="modal-dialog-top">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirmation</h5>
                        <button type="button" className="btn-close" onClick={() => setmodal_positionTop(!modal_positionTop)}></button>
                    </div>
                    { }
                    <div className="modal-body">
                        {tick ? (
                            <p>Are you sure you want to complete the task "{selectedTask?.title}"?</p>
                        ) :
                            <p>Are you sure you want to mark "{selectedTask?.title}" as New?</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <Button color="success"
                            onClick={() => handleCompleteTask(selectedTask)}
                        >Yes</Button>
                        <Button color="danger"
                            onClick={() => setmodal_positionTop(false)}
                        >No</Button>
                    </div>
                </div>
            </Modal>
            {editModal && <EditModal
                show={editModal}
                isEditOpen={editModal}
                onCloseClick={() => setEditOpen(false)}
                details={record} 
                model={model} 
                taskDetails={record} 
                setIsEditOpen={setEditOpen} 
                isEditTask={editModal}
                setTriggerApi={setTriggerApi}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId} 
                metaDataFields={metaDataFields}
            />}
            {isDeleteOpen && <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />}
             {isCreateModal && <AddEntryModal
                show={isCreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={metaData}
                setTriggerApi={setTriggerApi}
                props={props}
                createAction={createAction}
                parent={parent}
                parentId={parentId}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId} 
            />}
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                            <Row className='hstack gap-3 justify-content-between'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <InputGroup className='border rounded'>
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-light">
                                                        {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setSelectedSearchField({ value: 'all', label: 'All' })}>All</DropdownItem>
                                                        <DropdownItem divider />
                                                        {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                            <Input className='border-0' placeholder="Search..." style={{ padding: '1.2rem 0.9rem' }} onChange={onSearchHandle} value={search}></Input>
                                        </InputGroup>
                                        {search === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => setSearch("")}></i>}
                                    </div>
                                </Col>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3} className='hstack justify-content-end'>
                                    <Button
                                        onClick={() => setCreateOpen(true)}
                                        color="primary"
                                        className="btn btn-primary btn-label">
                                        <i className="ri-task-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t("student.add_tasks")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className=''>
                                {metaData && metaData.length ?
                                    <QuickFilter
                                        dataFields={metaData}
                                        columns={columns}
                                        translation={props}
                                        onFilter={onFilter}
                                        clearFilterParent={clearFilter}
                                        filter={filter}
                                        parentField={parentField}
                                        parentId={parentId}
                                    /> : ""}
                            </Row>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={ListData || []}
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    isPageSizeChange={true}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    customPageSize={pagination.size}
                                    onChangePageSize={onChangePageSize}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    pagesList={pagesList}
                                    props={props}
                                    access={access}
                                    viewsloading={viewsloading}
                                    dataLoading={dataLoading}
                                    metaLoading={metaLoading}
                                    model={model}
                                    parent={parent}
                                    updateRow={updateRow}
                                    url={url}
                                    checkbox={checkbox}
                                    setEditOpen={setEditOpen}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Listing);


