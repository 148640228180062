import React, { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Select, { SelectProps } from 'react-dropdown-select';
import { Button, ButtonGroup, Input, Label } from "reactstrap";

// Define the type for the options used in the Select component
interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

// Props for the SelectDropdown component
interface SelectDropdownProps {
    title: string;
    options: Option[];
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    property?: any;
    values?: Option[];
    isSearchable?: boolean;
    optionsLoading?: boolean;
    multi?: boolean;
    onFocus?: (property: any) => void;
    onChange?: (selectedValues: any) => void;
    onClearFilter?: (property: any) => void;
    handleSearch?: (property: any) => void;
    group?: boolean;
}

// Props for the custom content renderer
interface ContentRendererProps {
    props: SelectProps<Option>;
    state: {
        values: Option[];
        search: string;
    };
}

// Props for the custom dropdown renderer
interface DropdownRendererProps {
    props: SelectProps<Option>;
    state: {
        values: Option[];
        search: string;
    };
    methods: {
        areAllSelected: () => boolean;
        clearAll: () => void;
        selectAll: (options: Option[]) => void;
        setSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
        addItem: (option: Option) => void;
        isSelected: (option: Option) => boolean;
    };
}

// Functional component that renders the custom dropdown with actions
const SelectDropdown: React.FC<SelectDropdownProps> = ({
    title, options, placeholder, isSearchable, values: initialValues, onFocus, onChange, optionsLoading, multi, group, handleSearch, ...rest
}) => {
    const [selectedValues, setSelectedValues] = useState<Option[]>([]);
    const prevValuesRef: any = useRef();

    // Initialize selected values from props if provided
    useEffect(() => {
        if (initialValues?.length) {
            setSelectedValues(initialValues);
        } else {
            setSelectedValues([])
        }
    }, [initialValues]);

    // Call onChange callback when selectedValues state changes
    useEffect(() => {
        // Only update if values has changed
        if (JSON.stringify(selectedValues) !== JSON.stringify(prevValuesRef.current)) {
            if (onChange) {
                onChange(selectedValues);
            }
            // Store a deep copy to avoid mutating references
            prevValuesRef.current = cloneDeep(selectedValues);
        }
    }, [selectedValues]);

    // Handle selection and deselection of options
    const handleSelection = (option: Option) => {
        setSelectedValues((prevValues) =>
            prevValues.some((selected) => selected.value === option.value)
                ? prevValues.filter((selected) => selected.value !== option.value)
                : [...prevValues, option]
        );
    };

    // Custom renderer for the button content inside the dropdown
    const renderContent = ({ state }: ContentRendererProps) => (
        <ButtonGroup>
            <Button
                size='sm'
                onFocus={onFocus}
                color="primary"
                className={`d-flex w-100 btn-soft-primary ${selectedValues.length ? "rounded-end-0" : ""} btn-custom`}
            >
                <div className="fs-16 fw-semibold text-truncate" title={selectedValues.length ? `(${selectedValues.length}) ${placeholder}` : placeholder} >
                    {selectedValues.length ? `(${selectedValues.length}) ${placeholder}` : placeholder}
                </div>
                <i className="ri-arrow-down-s-line label-icon align-middle fs-16 fw-semibold ms-2"></i>
            </Button>
        </ButtonGroup>
    );

    // Custom renderer for the dropdown menu
    const renderDropdown = ({ props, state, methods }: DropdownRendererProps) => {
        const searchPattern = new RegExp(state.search, 'i');
        const searchField = props.searchBy as keyof Option;
        const labelField = props.labelField as keyof Option;
        const valueField = props.valueField as keyof Option;

        return (
            <div className='dropdown-content'>
                {isSearchable && (
                    <div className="form-icon left p-2">
                        <Input
                            type="text"
                            className="form-control form-control-icon fs-16"
                            placeholder="Search..."
                            value={state.search}
                            onChange={(e) =>{methods.setSearch(e);handleSearch && handleSearch(e.target.value)}}
                        />
                        <i className="fs-16 ri-search-line text-muted"></i>
                    </div>
                )}
                {optionsLoading ? <p className="d-flex align-items-center justify-content-center p-2 text-center text-muted">Loading...</p> : <ul className="options-list">
                    {props.options
                        .filter((item) =>
                            searchPattern.test(item[searchField] as string || item[labelField] as string || '')
                        )
                        .map((option) => {
                            if (!props.keepSelectedInList && methods.isSelected(option)) return null;

                            const isChecked = selectedValues.some((value) => value.value === option.value);

                            return (
                                <li
                                    key={option[valueField] as string}
                                    onClick={() => !option.disabled ? handleSelection(option) : null}
                                    className="d-flex p-0 cursor-pointer"
                                >
                                    <Button
                                        color="primary"
                                        className="d-flex align-items-center btn btn-ghost-primary waves-effect waves-light py-2 w-100 rounded-0"
                                    >
                                        {multi ? <Input
                                            className="cursor-pointer"
                                            type="checkbox"
                                            checked={isChecked}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                if (!option.disabled) {
                                                  handleSelection(option);
                                                }
                                            }}
                                        /> : null}
                                        <Label className="cursor-pointer text-truncate w-100 mb-0 text-left pt-1">
                                            {option[labelField] as string}
                                        </Label>
                                    </Button>
                                </li>
                            );
                        })}
                </ul>}
            </div>
        );
    };

    // Custom renderer for the dropdown menu
    const renderDropdownGroup = ({ props, state, methods }: DropdownRendererProps) => {
        const searchPattern = new RegExp(state.search, 'i');
    
        return (
            <div className='dropdown-content'>
                {isSearchable && (
                    <div className="form-icon left p-2">
                        <Input
                            type="text"
                            className="form-control form-control-icon fs-16"
                            placeholder="Search..."
                            value={state.search}
                            onChange={methods.setSearch}
                        />
                        <i className="fs-16 ri-search-line text-muted"></i>
                    </div>
                )}
                {optionsLoading ? (
                    <p className="d-flex align-items-center justify-content-center p-2 text-center text-muted">
                        Loading...
                    </p>
                ) : (
                    <ul className="d-flex flex-column gap-3 options-list">
                        {props.options.map((group: any) => (
                            <li key={group.label} className="dropdown-group">
                                <div className="dropdown-group-label px-2 fw-semibold text-muted">
                                    {group.label} {/* Non-clickable parent label */}
                                </div>
                                <ul className="nested-options-list p-0">
                                    {group.options
                                        .filter((option: any) =>
                                            searchPattern.test(option.label || '')
                                        )
                                        .map((option: any) => {
                                            if (!props.keepSelectedInList && methods.isSelected(option)) return null;
    
                                            const isChecked = selectedValues.some((value) => value.value === option.value);
    
                                            return (
                                                <li
                                                    key={option.value}
                                                    onClick={() => !option.disabled ? handleSelection(option) : null}
                                                    className="d-flex p-0 cursor-pointer"
                                                >
                                                    <Button
                                                        color="primary"
                                                        className="d-flex align-items-center btn btn-ghost-primary waves-effect waves-light py-1 w-100 rounded-0"
                                                    >
                                                        {multi && (
                                                            <Input
                                                                className="cursor-pointer"
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onClick={() => !option.disabled ? handleSelection(option) : null}
                                                            />
                                                        )}
                                                        <Label className="cursor-pointer text-truncate w-100 mb-0 text-left pt-1">
                                                            {option.label}
                                                        </Label>
                                                    </Button>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    };
    

    return (
        <div className='d-flex dts-select-dropdown'>
            <Select
                className="react-dropdown-select p-0 border-0 d-flex"
                multi={multi}
                closeOnSelect={!multi}
                contentRenderer={renderContent}
                dropdownRenderer={group ? renderDropdownGroup : renderDropdown}
                options={options}
                values={selectedValues}
                dropdownHandle={false}
                onChange={(e) => console.log(e)}
                {...rest}
            />
            {selectedValues.length > 0 && (
                <Button
                    size='sm'
                    onClick={() => setSelectedValues([])}
                    color="danger"
                    className="btn-icon-custom btn-soft-danger rounded-start-0"
                >
                    <i className="ri-close-line label-icon align-middle fs-5 fw-semibold"></i>
                </Button>
            )}
        </div>
    );
};

export default SelectDropdown;
