
import { useEffect, useState } from "react";
import {
    Col,
    Input,
    Label,
    Row
} from "reactstrap";

const BasicInfo = ({ setEditInputs, EditInputs, setFieldJson, fieldJson }: any) => {
    const [rules, setRules] = useState<any>({
        show_informs: false,
        required: false,
        unique: false,
        minLimit: { restrictminLimit: false, value: 1 },
        maxLimit: { restrictmaxLimit: false, value: 30 },
        restrictspecialcharacter: false,
        restrictalpha: false
    })

    const onChanged = (target: any, name: any) => {
        if (name == "hidden") {
            setRules({ ...rules, [name]: !target.checked })
            let newfield = { ...fieldJson, hidden: !target.checked }
            setEditInputs({ ...EditInputs, hidden: !target.checked })
            setFieldJson(newfield)
        }
        else if (name == "quickCreate") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, quickCreate: target.checked }
            setEditInputs({ ...EditInputs, quickCreate: target.checked })
            setFieldJson(newfield)
        }
        else if (name == "unique") {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, unique: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
        else if (name == "required") {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, required: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
        else if (name == "filter") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, filter: target.checked }
            setEditInputs({ ...EditInputs, filter: target.checked })
            setFieldJson(newfield)
        }
        else if (name == "tablecolumn") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, tablecolumn: target.checked }
            setEditInputs({ ...EditInputs, tablecolumn: target.checked })
            setFieldJson(newfield)
        }        
        else if (name == 'enableTime') {
            // setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.widget
            let newuniquevalidation = { ...newvalidate, enableTime: target.checked }
            let newfield = { ...fieldJson, widget: newuniquevalidation }
            let newrules = { ...rules, enableTime: target.checked }
            setEditInputs({ ...EditInputs, widget: newuniquevalidation })
            setFieldJson(newfield)
        }
        else {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, [name]: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
    }

    useEffect(() => {
        setEditInputs({ ...EditInputs, rules })
    }, [rules]);
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">
                    {EditInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div className="fs-18 mb-1 fw-bold">
                        Set Property rules
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                        Property visibility
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'hidden')} checked={!EditInputs.hidden} />
                        <Label className="fs-16 mb-0 fw-medium">Show in forms, pop-up forms and bots</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'quickCreate')} checked={EditInputs.quickCreate} />
                        <Label className="fs-16 mb-0 fw-medium">Show in quick create forms</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'tablecolumn')} checked={EditInputs.tablecolumn} />
                        <Label className="fs-16 mb-0 fw-medium">Show in table</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'filter')} checked={EditInputs.filter} />
                        <Label className="fs-16 mb-0 fw-medium">Add in Filters</Label>
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                        Validation rules
                        <p className="fs-14 mb-0 fw-medium">Specify what values are allowed for this property. These rules apply when creating, editing, and importing records with the CRM</p>
                    </div>
                    <div className="hstack align-content-center gap-1 mt-1" onClick={(e) => onChanged(e.target, 'required')}>
                        <Input className="mb-1 form-check-input" type="checkbox" checked={EditInputs.validate.required} />
                        <Label className="fs-16 mb-0 fw-normal">Set as mandatory field value for this property </Label>
                    </div>                    
                    {EditInputs.type === 'datetime' ?
                        <div className="hstack align-content-center gap-1 mb-1" onClick={(e) => onChanged(e.target, 'enableTime')}>
                            <Input className="mb-1 form-check-input" type="checkbox" checked={EditInputs?.widget?.enableTime} />
                            <Label className="fs-16 mb-0 fw-normal">Enable Time in Date </Label>
                        </div>
                    : null}
                </Col>
            </Row>
        </div>
    );
};

export default BasicInfo;
