import React, { Component, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Details from "Components/Common/RecordDetails/Details";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { applicationDelete, applicationGetRequest, applicationsListGetRequest, applicationsMetadataGetRequest, applicationUpdateRequest } from "store/applications/action";

const Students = (props: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
    }, [])
    
    return (
        <React.Fragment>
           <Details 
                getListAction={applicationsListGetRequest}
                deleteAction={applicationDelete}
                getDetailsAction={applicationGetRequest}
                updateAction={applicationUpdateRequest}
                model={"applications"}
                translater={"applications"}
                reducerState={"applications"}
           />
        </React.Fragment>
    );
};

export default withTranslation()(Students);
