import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Dashboards from './Dashboards';

const dashboard = () => {
    
    document.title="DTS | Zilter - Dashboards";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title="dashboard" pageTitle="Dashboard" />
                    <Row>
                        <Dashboards />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default dashboard;