import React, { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { Table, Row, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, ButtonGroup, UncontrolledDropdown } from "reactstrap";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import { checkTheyOwn, isJson, convertCurrency } from "utils";
import { useDispatch } from "react-redux";
import { currencyGetRequest } from "store/currency/action";
import { Link } from "react-router-dom";

interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    theadClass?: any;
    isPageSizeChange?: any;
    pagesList: any;
    trClass?: any;
    thClass?: any;
    customPageSize?: any;
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    onChangePageSize: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy: string;
    sortOrder: string;
    props: any;
    access: any;
    viewsloading: any;
    dataLoading: boolean;
    metaLoading: any;
    model: any;
    parent: any;
    updateRow: any;
    onChange: (value: any, key: any, data: any, id: any) => void;
    url: any;
    checkbox: any;
    updatedCurrency: any;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    tableClass,
    theadClass,
    trClass,
    thClass,
    customPageSize,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagination,
    onChangePageSize,
    handleSort,
    sortOrder,
    sortBy,
    props,
    access,
    viewsloading,
    dataLoading,
    metaLoading,
    onChange,
    updateRow,
    checkbox,
    updatedCurrency,
    url,
    model
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: customPageSize
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const dispatch: any = useDispatch();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updateCurrecyState = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [open, setOpen] = useState("");
    const [deleteaccess, setDeleteAccess] = useState<any>(null);
    const [assignRates, setAssignRates] = useState<any>(null)
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    const [parsedRates, setParsedRates] = useState<any>(null)

    useEffect(() => {
        dispatch(currencyGetRequest())
    }, [])

    useEffect(() => {

        if (updatedCurrency) {
            setSelectedCurrency(updatedCurrency.selectedCurrency);
            setCurrencySymbol(updatedCurrency.selectedCurrencySymbol)
        }
    }, [updatedCurrency]);

    useEffect(() => {
        if (conversionRates) {
            setAssignRates(conversionRates)
        }
    }, [conversionRates])

    useEffect(() => {
        if (assignRates && assignRates?.length > 0) {
            const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

            // Check if `targetCurrencyJson` exists and is a valid JSON string
            const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                ? JSON.parse(targetCurrencyJson)
                : {};

            // Set the parsed rates only if convertJson is valid
            setParsedRates(convertJson?.conversion_rates);
        }
    }, [assignRates]);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setView(true)
            setEdit("all")
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false) : setView(true)
                            break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.value)
                            break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setView(false)
            setEdit(false)
            setDeleteAccess(false)
        }
    }, [access, userProfile])

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    return (
        <div>
            {metaLoading || viewsloading || dataLoading || (!page.length && data.length) ?
                <DtsTablePlaceholder
                    columns={columns}
                    theadClass={theadClass}
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                :
                <div>
                    <Row className="align-items-center mb-1 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${(pagination.offset ? pagination.offset : 0) + 1}-${Math.min((pagination.offset ? pagination.offset : 0) + pagination.size, pagination.elements)} ${props.t("pagination.of")} ${pagination.elements} ${props.t("pagination.results")}` : `0-0 of 0 results`}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e: any) => pageChanged(e)}
                                pageSize={pagination.size}
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e: any) => onChangeInSelect(e)}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
                    {!metaLoading && !dataLoading && !viewsloading && page && page.length ?
                        <div>
                            <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                                <thead className={theadClass}>
                                    {headerGroups.map((headerGroup: any) => (
                                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                            {checkbox && <th className={thClass + " fw-bold"} >Checkbox</th>}
                                            {headerGroup.headers.map((column: any) => (
                                                <th key={column.id} className={thClass + " fw-bold"}
                                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                                >
                                                    <div onClick={() => handleSort(column.id)} className={"cursor-pointer" + `${column.accessor == 'course_name' ? '' : ' otherth'}`} title={column.Header}>
                                                        {column.render("Header")}
                                                        {sortBy === column.id && (
                                                            <span>
                                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                            </span>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}
                                            <th className={thClass + " fw-bold"} >
                                                {props.t("student.actions")}
                                            </th>
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} style={{ minHeight: 300 }}>
                                    {page.map((row: any) => {
                                        prepareRow(row);
                                        let they_own = checkTheyOwn(row.original, userProfile?.id)
                                        return (
                                            <Fragment key={row.getRowProps().key}>
                                                <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                                {checkbox && <td>{checkbox(row)}</td>}
                                                    {row.cells.map((cell: any) => {
                                                        let fields = cell.column.valueJson;
                                                        if (cell.column.id === 'student_status' || cell.column.id === 'application_status') {
                                                            let status_key = model == "students" ? "student_status" : "application_status"
                                                            const currentValue = row.original.id === updateRow?.id ? updateRow?.[status_key] : row.original[status_key];
                                                            const { statuses, status } = getAvailableStatuses(fields?.process_flows, currentValue, row?.original?.process_flow);
                                                            return <td>                                                                
                                                                <ButtonGroup>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="button"
                                                                            // disabled={!(edit && (edit?.toLowerCase() === "all" || (edit?.toLowerCase() === "they_own" && they_own)))}
                                                                            className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary"
                                                                            style={{ width: '160px', color: 'inherit' }}
                                                                        >
                                                                            {status?.label} <i className="mdi mdi-chevron-down"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdownmenu-primary">
                                                                            {statuses.map((status: any, index: number) => (
                                                                                <DropdownItem
                                                                                    key={index}
                                                                                    onClick={(e) => onChange(status, fields.key, row.original, row.original.id)}
                                                                                >
                                                                                    {status?.label}
                                                                                </DropdownItem>
                                                                            ))}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </ButtonGroup>
                                                                
                                                            </td>
                                                        }
                                                        return (
                                                            <td 
                                                                key={cell.id} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className="align-items-center justify-content-between" 
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })
                                                    }                                                                                                          
                                                     <td>
                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                            <li className="list-inline-item">
                                                                <Dropdown isOpen={open == row.original.id} toggle={() => open == row.original.id ? setOpen("") : setOpen(row.original.id)}>
                                                                    <DropdownToggle
                                                                        href="#"
                                                                        className="btn btn-soft-primary btn-sm dropdown"
                                                                        tag="button"
                                                                    >
                                                                        <i className="ri-more-fill"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu container="body" className="dropdown-menu-center">
                                                                        { view ?
                                                                            <DropdownItem className="dropdown-item">
                                                                            <Link to={`${url}/${row.original.id}`} style={{textDecoration: 'none'}}
                                                                            >
                                                                                <button className="btn btn-sm btn-soft-info remove-list fs-12"
                                                                                >
                                                                                    <i className="ri-eye-fill"></i>{" "}
                                                                                </button>{" "}
                                                                                {props.t("student.view")}
                                                                            </Link></DropdownItem>
                                                                            : null}
                                                                        {edit && (edit?.toLowerCase() === "all" || (edit?.toLowerCase() === "they_own" && they_own)) ?
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                            >
                                                                                <Link to={`${url}/${row.original.id}`} style={{textDecoration: 'none'}}>
                                                                                    <button className="btn btn-sm btn-soft-secondary remove-list"
                                                                                    >
                                                                                        <i className="ri-pencil-fill"></i>{" "}
                                                                                    </button>{" "}
                                                                                    {props.t("student.edit")}
                                                                                </Link>
                                                                            </DropdownItem> :
                                                                            null}
                                                                        {deleteaccess && (deleteaccess?.toLowerCase() === "all" || (deleteaccess?.toLowerCase() === "they_own" && they_own)) ?
                                                                            <DropdownItem
                                                                                className="dropdown-item remove-item-btn fs-12"

                                                                                onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true); }}
                                                                            >
                                                                                <button className="btn btn-sm btn-soft-danger remove-list"
                                                                                >
                                                                                    <i className="ri-delete-bin-fill"></i>
                                                                                </button>  {" "}
                                                                                {props.t("student.delete")}
                                                                            </DropdownItem> : null}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table></div> :
                        <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                            <thead className={theadClass}>
                                {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                                    <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column: any) => (
                                            <th key={column.id} className={thClass + " fw-bold"}
                                                {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                            >
                                                <div onClick={() => handleSort(column.id)} className={"cursor-pointer" + `${column.accessor == 'course_name' ? '' : ' otherth'}`} title={column.Header}>
                                                    {column.render("Header")}
                                                    {sortBy === column.id && (
                                                        <span>
                                                            {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                        </span>
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                        <th className={thClass + " fw-bold"} >
                                            {props.t("pagination.actions")}
                                        </th>
                                    </tr>
                                )) : null}
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={headerGroups && headerGroups.length && headerGroups[0].headers.length + 1} className="p-5 fw-semibold fs-16 text-center">
                                        <div className="hstack p-5 justify-content-center fw-semibold fs-16 text-center w-100">
                                            {props.t("pagination.data_error")}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>}
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${(pagination.offset ? pagination.offset : 0) + 1}-${Math.min((pagination.offset ? pagination.offset : 0) + pagination.size, pagination.elements)} ${props.t("pagination.of")} ${pagination.elements} ${props.t("pagination.results")}` : `0-0 of 0 ${props.t("pagination.results")}`}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e: any) => pageChanged(e)}
                                pageSize={pagination.size}
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e: any) => onChangeInSelect(e)}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
                </div>}
        </div>
    );
};

export default withTranslation()(TableContainer);