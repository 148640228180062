import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Common/ChartsDynamicColor";


const SentimentsLineChart = ({ sentiments }: any) => {
    const scale = sentiments.AGENT.map((sentiment: any) => sentiment.EndOffsetMillis)
    const agents = sentiments.AGENT.map((sentiment: any) => sentiment.Score)
    const customer = sentiments.CUSTOMER.map((sentiment: any) => sentiment.Score)
    const dataColors = '["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning", "--vz-info"]'
    var LinewithDataLabelsColors = getChartColorsArray(dataColors);
    var series = [{
        name: "Customer",
        data: customer
    },
    {
        name: "Agent",
        data: agents
    }
    ];
    var options = {
        chart: {
            height: 380,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        colors: LinewithDataLabelsColors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'straight'
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2
            },
            borderColor: '#f1f1f1'
        },
        markers: {
            style: 'inverted',
            size: 6
        },
        xaxis: {
            categories: scale,
            title: {
                text: 'Time'
            }
        },
        yaxis: {
            title: {
                text: 'Sentiments'
            },
            min: -5,
            max: 5
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }]
    };
    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Student and Agent sentiment trend</h4>
                </CardHeader>
                <CardBody className="p-0">
                    <ReactApexChart dir="ltr"
                        options={options}
                        series={series}
                        type="line"
                        height="380"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default SentimentsLineChart;