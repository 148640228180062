import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommissionsListing from './Finance';

const Commissions = () => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>                         
            <Row>
                <CommissionsListing />
            </Row>
        </React.Fragment>
    );
};

export default Commissions;