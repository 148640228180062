import {UserActionTypes, User} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const userGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: UserActionTypes.GET_USER_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: UserActionTypes.GET_USER_ERROR,
                    payload: body
                });
                handleError && handleError(body);
            } else {
                dispatch({
                    type: UserActionTypes.GET_USER_SUCCESS,
                    payload: body
                });
                handleSuccess && handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

export const tenantGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: UserActionTypes.GET_TENANT_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/tenants/`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: UserActionTypes.GET_TENANT_ERROR,
                    payload: body
                });
                handleError && handleError(body);
            } else {
                dispatch({
                    type: UserActionTypes.GET_TENANT_SUCCESS,
                    payload: body
                });
                handleSuccess && handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

export const tenantGetOpenRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: UserActionTypes.GET_TENANT_OPEN_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/tenants/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: UserActionTypes.GET_TENANT_OPEN_ERROR,
                    payload: body
                });
                handleError && handleError(body);
            } else {
                dispatch({
                    type: UserActionTypes.GET_TENANT_OPEN_SUCCESS,
                    payload: body
                });
                handleSuccess && handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_DOMAIN}/users`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const usersListGetRequest: AppThunk = (queryParams, page, pageSize, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.GET_USERS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/users?${queryParams?queryParams:""}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.GET_USERS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: UserActionTypes.GET_USERS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Users fetched successfully");
        }
    };
};

export const userUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.PUT_USER_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );  
        const responseData: any = await response.json()
        if(responseData?.status.includes("[AUTHENTICATIONFAILED]"))   {
        }                         
        const error = {
            204: {
                "message": "User updated Successfully",
                "success": true
            },
            200: {
                "message": "User updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, UserActionTypes.PUT_USER_SUCCESS, UserActionTypes.PUT_USER_ERROR, error, handleSuccess, handleError, responseData);
    
    };
};

export const accountAddRequest: AppThunk = (
    id: string,
    data: any,
    handleSuccess: (response: any) => void,
    handleError: (error: any) => void
) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const {
            auth: { token },
        } = getState();

        dispatch({
            type: UserActionTypes.PUT_ACCOUNT_REQUEST,
        });

        if (!token) {
            console.error('Token is missing or null. Unable to authenticate.');
            if (handleError) {
                handleError({
                    message: '[AUTHENTICATIONFAILED] Token is missing',
                });
            }
            return;
        }

        const headers = new Headers({
            'Content-Type': 'application/json',
            token,
        });

        try {
            const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            if (
                responseData?.status?.includes('[AUTHENTICATIONFAILED]') || 
                responseData?.status?.includes('AUTHENTICATE failed.')
              ) {
                if (handleError) {
                    handleError(responseData);
                }
                return;
            }

            const successMessages = {
                200: {
                    message: 'User updated successfully',
                    success: true,
                },
                204: {
                    message: 'User updated successfully',
                    success: true,
                },
            };

            await responseHandler(
                response,
                dispatch,
                UserActionTypes.PUT_ACCOUNT_SUCCESS,
                UserActionTypes.PUT_ACCOUNT_ERROR,
                successMessages,
                handleSuccess,
                handleError
            );
        } catch (error) {
            console.error('Error updating account:', error);
            if (handleError) {
                handleError(error);
            }
        }
    };
};


export const tenantUpdateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.PUT_TENANT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/tenants/`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
                                       
        const error = {
            204: {
                "message": "Details updated Successfully",
                "success": true
            },
            200: {
                "message": "Details updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, UserActionTypes.PUT_TENANT_SUCCESS, UserActionTypes.PUT_TENANT_ERROR, error, handleSuccess, handleError);
    
    };
};
export const userDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();

        dispatch({
            type: UserActionTypes.DELETE_USER_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "User deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "User deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, UserActionTypes.DELETE_USER_SUCCESS, UserActionTypes.DELETE_USER_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: UserActionTypes.DELETE_USER_ERROR
            });
            handleSuccess();
        }
    };
};
export const resendOtp: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.POST_INVITE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/resendOtp`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.POST_INVITE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: UserActionTypes.POST_INVITE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};
export const userInviteRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.POST_INVITE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/invite`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.POST_INVITE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: UserActionTypes.POST_INVITE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const userRoleUpdateRequest: AppThunk = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {    
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.PUT_ROLE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );       
                                       
        const error = {
            204: {
                "message": "User role updated Successfully",
                "success": true
            },
            200: {
                "message": "User role updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, UserActionTypes.PUT_ROLE_SUCCESS, UserActionTypes.PUT_ROLE_ERROR, error, handleSuccess, handleError);
    
    };
};