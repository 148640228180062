import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";


interface TableContainerProps {
    columns?: any;
    tableClass?: any;
    theadClass?: any;
    thClass?: any;
    rows?: any;
    cols?: any;
}

const FromLoader = ({
    rows,
    cols
}: TableContainerProps) => {
    let innitialrows= rows || 5 
    let placeholdercolumn = cols || 1;
    return (
        <div>
             {[...Array(innitialrows)].map((_, index) => {
                        return <div style={{width: '100%', padding: 10}} className="vstack gap-4">
                         {[...Array(placeholdercolumn)].map((_, index) => {
                            return <div key={index} className="placeholder-glow">
                                <span className="placeholder col-3"></span>
                                <span className="placeholder col-12"></span>
                            </div>
                        })}</div>}
                    )}
                    <div>
                        <Link to="#"  tabIndex={1} className="btn btn-success btn-sm disabled placeholder placeholder-sm col-12 opacity-25"></Link>
                    </div>  
        </div>
    );
};

export default withTranslation()(FromLoader);