import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PropertiesListing from './Properties';

const Properties = () => {
	document.title = "DTS | Zilter - Fields";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Fields" pageTitle="Fields" />
                    <Row>
                        <PropertiesListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Properties;