export interface Auth {
    id: string,
    name: string,
    tenantId: {
        id: string,
        email: string
    },
    email: string,
    password: string,
    companyName: string,
    website: string,
    phoneNumber: string,
    role: {
        permissions: any,
        title: any,
        id: any
    },
    otp: string,
    alternatePhoneNumber: string,
    accountStatus: string,
    confirmationStatus: string,
    profilePic: string;
    createdTime: string,
    updatedTime: string,
    noOfEmployees: string,
    currentStage: any,
    tenant: {
        companyName: string,
        email: string,
        noOfEmployees: string,
        website:string,
        id: string
    }
    salt: [
        string
    ],
    subAgent: any,
    values_json: any
    subscription?: any,
}

export interface MultiAssociation {
    MultiToken: any
}

export enum AuthActionTypes {
    GET_REQUEST = "@@auth/GET_REQUEST",
    GET_SUCCESS = "@@auth/GET_SUCCESS",
    GET_ERROR = "@@auth/GET_ERROR",
    POST_SIGNIN_REQUEST = "@@auth/POST_SIGNIN_REQUEST",
    POST_SIGNIN_SUCCESS = "@@auth/POST_SIGNIN_SUCCESS",
    POST_SIGNIN_ERROR = "@@auth/POST_SIGNIN_ERROR",
    POST_SIGNUP_REQUEST = "@@auth/POST_SIGNUP_REQUEST",
    POST_SIGNUP_SUCCESS = "@@auth/POST_SIGNUP_SUCCESS",
    POST_SIGNUP_ERROR = "@@auth/POST_SIGNUP_ERROR",
    POST_VERIFY_REQUEST = "@@auth/POST_VERIFY_REQUEST",
    POST_VERIFY_SUCCESS = "@@auth/POST_VERIFY_SUCCESS",
    POST_VERIFY_ERROR = "@@auth/POST_VERIFY_ERROR",
    POST_UPDATE_USER_REQUEST = "@@auth/POST_UPDATE_USER_REQUEST",
    POST_UPDATE_USER_SUCCESS = "@@auth/POST_UPDATE_USER_SUCCESS",
    POST_UPDATE_USER_ERROR = "@@auth/POST_UPDATE_USER_ERROR",
    POST_JOIN_USER_REQUEST = "@@auth/POST_JOIN_USER_REQUEST",
    POST_JOIN_USER_SUCCESS = "@@auth/POST_JOIN_USER_SUCCESS",
    POST_JOIN_USER_ERROR = "@@auth/POST_JOIN_USER_ERROR",
    POST_FORGOT_REQUEST = "@@auth/POST_FORGOT_REQUEST",
    POST_FORGOT_SUCCESS = "@@auth/POST_FORGOT_SUCCESS",
    POST_FORGOT_ERROR = "@@auth/POST_FORGOT_ERROR",
    POST_RESEND_REQUEST = "@@auth/POST_RESEND_REQUEST",
    POST_RESEND_SUCCESS = "@@auth/POST_RESEND_SUCCESS",
    POST_RESEND_ERROR = "@@auth/POST_RESEND_ERROR",
    GET_SIGNOUT = "@@auth/GET_SIGNOUT",
    SET_INVOICE_STUDENTS= "@@auth/SET_INVOICE_STUDENTS",
    RESET_STUDENTS = "@@auth/RESET_STUDENTS",
    GET_USER_REQUEST = "@@auth/GET_USER_REQUEST",
    GET_USER_SUCCESS = "@@auth/GET_USER_SUCCESS",
    GET_USER_ERROR = "@@auth/GET_USER_ERROR",
    GET_OPTIONS_REQUEST = "@@auth/GET_OPTIONS_REQUEST",
    GET_OPTIONS_SUCCESS = "@@auth/GET_OPTIONS_SUCCESS",
    GET_OPTIONS_ERROR = "@@auth/GET_OPTIONS_ERROR",
    GET_PROPERTIES_OPTIONS_REQUEST = "@@auth/GET_PROPERTIES_OPTIONS_REQUEST",
    GET_PROPERTIES_OPTIONS_SUCCESS = "@@auth/GET_PROPERTIES_OPTIONS_SUCCESS",
    GET_PROPERTIES_OPTIONS_ERROR = "@@auth/GET_PROPERTIES_OPTIONS_ERROR",
    GET_PROPERTIES_REQUEST = "@@auth/GET_PROPERTIES_REQUEST",
    GET_PROPERTIES_SUCCESS = "@@auth/GET_PROPERTIES_SUCCESS",
    GET_PROPERTIES_ERROR = "@@auth/GET_PROPERTIES_ERROR",
    GET_MODELS_REQUEST = "@@auth/GET_MODELS_REQUEST",
    GET_MODELS_SUCCESS = "@@auth/GET_MODELS_SUCCESS",
    GET_MODELS_ERROR = "@@auth/GET_MODELS_ERROR",
    POST_RESPONSE_SUCCESS = "@@auth/POST_RESPONSE_SUCCESS",
    POST_RESETPASSWORD_USER_REQUEST = "@@auth/POST_RESETPASSWORD_USER_REQUEST",
    POST_RESETPASSWORD_USER_SUCCESS = "@@auth/POST_RESETPASSWORD_USER_SUCCESS",
    POST_RESETPASSWORD_USER_ERROR = "@@auth/POST_RESETPASSWORD_USER_ERROR",
    GET_VERIFY_PASSWORD_LINK_REQUEST ="@@auth/GET_VERIFY_PASSWORD_LINK_REQUEST",
    GET_VERIFY_PASSWORD_LINK_SUCCESS ="@@auth/GET_VERIFY_PASSWORD_LINK_SUCCESS",
    GET_VERIFY_PASSWORD_LINK_ERROR ="@@auth/GET_VERIFY_PASSWORD_LINK_ERROR",
    GET_VERIFY_JOIN_LINK_REQUEST ="@@auth/GET_VERIFY_JOIN_LINK_REQUEST",
    GET_VERIFY_JOIN_LINK_SUCCESS ="@@auth/GET_VERIFY_JOIN_LINK_SUCCESS",
    GET_VERIFY_JOIN_LINK_ERROR ="@@auth/GET_VERIFY_JOIN_LINK_ERROR",
    POST_GOOGLECALENDARSYNC_REQUEST = "@@calendar/POST_GOOGLECALENDARSYNC_REQUEST",
    POST_GOOGLECALENDARSYNC_SUCCESS = "@@calendar/POST_GOOGLECALENDARSYNC_SUCCESS",
    POST_GOOGLECALENDARSYNC_ERROR = "@@calendar/POST_GOOGLECALENDARSYNC_ERROR",
    POST_SUBLEVEL_PERMISSION_SUCCESS = "@@auth/POST_SUBLEVEL_PERMISSION_SUCCESS",
    POST_SUBLEVEL_PERMISSION_ERROR = "@@auth/POST_SUBLEVEL_PERMISSION_ERROR",
    POST_SUBLEVEL_PERMISSION_REQUEST = "@@auth/POST_SUBLEVEL_PERMISSION_REQUEST",
    GET_MULTIPLE_USER_SUCCESS = "@@auth/GET_MULTIPLE_USER_SUCCESS",
    GET_MULTIPLE_USER_ERROR = "@@auth/GET_MULTIPLE_USER_ERROR",
    GET_MULTIPLE_USER_REQUEST = "@@auth/GET_MULTIPLE_USER_REQUEST",
    POST_SET_TOKEN = "@@auth/POST_SET_TOKEN"
}

export interface AuthState {
    readonly loading: boolean;
    readonly userProfile: Auth;
    readonly errors?: string;
    readonly response?: any;
    readonly token?: string | null;
    readonly user_Pool_id?: string;
    readonly isUserLoggedin: boolean;
    readonly optionData: any;
    readonly optionsLoading: boolean;
    readonly optionmetaData: any;
    readonly models: any;
    readonly subLevelPermissionsList: any;
    readonly permissionloading: any;
    readonly parentPermmission: any;
    readonly meloading: any;
    readonly MultiToken: any;
    readonly propertiesOptionData: any;
}
