import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ViewsTab from 'Components/Common/Views/ViewsTab';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import University from './University';
import { universitiesMetadataGetRequest } from 'store/university/action';

const Universities = (props: any) => {    
    const metaData = useSelector((state: ApplicationState) => state.universities.metaDataFields);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(universitiesMetadataGetRequest("institutes", filters));
    }, [])
    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <Row>
                        <ViewsTab 
                            component={University}
                            metadata={metaData}
                            model={"institutes"}
                            isBulkUpload={true}
                            title={props.t("institute.institutes")} 
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Universities);

