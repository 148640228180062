import React, { useEffect, useState, useCallback } from 'react';
import { Card, Col, Input, Row, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';
import DeleteModal from './innerComponent/DeleteModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { useNavigate, useParams } from 'react-router-dom';
import { financeChannelListGetRequest, financeDelete, financeGetRequest, setCommissionFields } from "store/finance/action";
import Upload from './Upload/Upload';
import UpdateModal from './innerComponent/UpdateModal';
import { coursesMetadataGetRequest } from 'store/course/action';
import staticfields from './Fields.json'
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';
import { isJson } from 'utils';
import UserAvatar from 'Components/Common/UserAvtar/ListingUserAvatar';

const Finance = (props: any) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [createModal, setCreateModal] = useState<boolean>(false);
    const financeList = useSelector((state: ApplicationState) => state.finance.channelfinances);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.finance.pagination);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const coursesMetadata = useSelector((state: ApplicationState) => state.courses.metaDataFields);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [columns, setColumns] = useState<any>([])
    const [channeldatafields, setDatafields] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [fileInfo, setFileInfo] = useState<any>({});
    const [finance, setFinance] = useState([]);
    const [financeJson, setfinanceJson] = useState<any>([]);
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] = useState<any>({
        pageSize:10
    });
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [isUpdateModalOpen, setUpdateModal] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [levels, setLevels] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    useEffect(() => {
        dispatch(coursesMetadataGetRequest("courses", { pageSize: 500 }, 0));
    }, [])

    useEffect(() => {
        coursesMetadata && coursesMetadata.length && coursesMetadata.map((item: any) => {
            let field = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
            if (field && field.key == "level") {
                let options = field.values;
                let levelsfields: any = []
                options && options.map((item: any) => {
                    let level = {
                        "label": item.label,
                        "key": item?.label?.toLowerCase(),
                        "placeholder": `Enter ${item.label} Commission`,
                        "quickCreate": true,
                        "type": "groupinput",
                        "values": [
                            {
                                "label": "Fixed",
                                "value": "fixed",
                                "in_forms": true
                            },
                            {
                                "label": "Percent",
                                "value": "percent",
                                "in_forms": true
                            }
                        ]
                    }
                    levelsfields.push(level)
                })
                setLevels(levelsfields)
            }
        })
    }, [coursesMetadata])

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(financeChannelListGetRequest(id, filters, 0));
        setfinanceJson([])
    }


    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(financeGetRequest(lead.id))
    };

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(financeChannelListGetRequest(id, filterObject, newPageNo));
        setfinanceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            dispatch(financeChannelListGetRequest(id))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(financeDelete(record.id, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const handleType = (type: any, currency: any) => {
        if (type == 'percent') return '%';
        else if (currency) return currency
    }

    useEffect(() => {
        const columns: any = []
        if (staticfields && staticfields.length) {
            staticfields.map((field, index) => {
                if (field.key == 'instituteId') {
                    let newcolumn = {
                        Header: field.label,
                        accessor: field.key,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {
                            return <div className="d-flex align-items-center">
                                <div className="hstack flex-grow-1 gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack gap-2 fw-bold" >
                                        <UserAvatar firstName={cellProps.row.original['instituteId']} />
                                        {cellProps.row.original['instituteId']}
                                    </div>
                                </div>
                            </div>

                        }
                    }
                    columns.push(newcolumn)
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)

    }, [levels, coursesMetadata]);

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)


        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(financeChannelListGetRequest(id, filters));
            setfinanceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);

    };


    const execute = () => {
        let a = isEqual(financeList, financeJson)
        return !a
    }

    useEffect(() => {
        const parsedData: any = financeList && financeList.length ? financeList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): item.valuesJson;
             let values = { ...valuesJson, ...item, id: item.id , createdAt: item.createdAt, instituteId: item?.instituteId, id2: item?.valuesJson?.instituteId}
             return values
        }) : []
        let finance = parsedData && parsedData.length ? parsedData : []
        setFinance(finance)
        setfinanceJson(financeList)
    }, [execute()])

    const createCommission = () => {
        let dataFields: any = []
        let count = 0
         staticfields.map((item: any) => {
            let field =JSON.stringify(item);
            dataFields[count]= {valuesJson : field};
            count++;
         })    
        dispatch(setCommissionFields({key: 'channels', dataFields: dataFields, id2: null}))
        navigate(`/finance/${id}/create` )
    }

    

    const redirect = (lead: any) => {
        let dataFields: any = []
        let count = 0
         staticfields.map((item: any) => {
            let field =JSON.stringify(item);
            dataFields[count]= {valuesJson : field};
            count++;
         })    
        dispatch(setCommissionFields({key: 'channels', dataFields: dataFields}))
        navigate(`/finance/rules/channels/${id}/institutes/${lead.id2}/view` )
    }


    useEffect(() => {
        dispatch(financeChannelListGetRequest(id, filterObject, 0))
    }, [])

    const handleFileClicks = () => {
        setModalFile(!modalFile);
        fileToggle();
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.commissions;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])
    
    useEffect(() => {
        let dataFields: any = []
        let count = 0
        staticfields.map((item: any) => {
            let field = JSON.stringify(item);
            dataFields[count] = { valuesJson: field };
            count++;
        })
        levels && levels.length && levels.map((item: any) => {
            let field = JSON.stringify(item);
            dataFields[count] = { valuesJson: field };
            count++;
        })
        setDatafields(dataFields)
    }, [levels])

    const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);

    document.title = "DTS | Zilter";
    return (
        <>
            {!authLoading ?
                viewAccess ?
                    <React.Fragment>
                        {createModal && <AddEntryModal
                            show={createModal}
                            onCloseClick={() => setCreateModal(false)}
                            dataFields={channeldatafields}
                            props={props}
                        />}
                        {isUpdateModalOpen && <UpdateModal
                            show={isUpdateModalOpen}
                            onCloseClick={() => setUpdateModal(false)}
                            dataFields={channeldatafields}
                            record={record}
                            props={props}
                        />}
                        {isImportCSV && <ImportDataModal
                            show={isImportCSV}
                            onCloseClick={() => setIsImportCSV(false)}
                            props={props}
                        />}
                        {isDeleteOpen && <DeleteModal
                            show={isDeleteOpen}
                            onCloseClick={() => setisDeleteOpen(false)}
                            props={props}
                            record={record}
                            onDelete={onDelete}
                        />}
                        <Row className='h-100'>
                            <Col xl={12} lg={12} md={12} className='h-100'>
                                <Card className='p-4'>
                                    <div>
                                        <Row className='hstack gap-3'>
                                            <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                                <div className="search-box">
                                                    <Input
                                                        type="text"
                                                        size={14}
                                                        className="search"
                                                        placeholder={props.t("finance.search_for")}
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        value={search}
                                                    />
                                                    {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='hstack gap-2 justify-content-end'>
                                                    {
                                                        create ?
                                                            <Button
                                                                onClick={() => createCommission()}
                                                                color="primary"
                                                                className="btn-label">
                                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t("applications.create_entry")}
                                                            </Button>
                                                            :
                                                            <Button
                                                                color="primary"
                                                                disabled
                                                                className="btn-label">
                                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                                {props.t("applications.create_entry")}
                                                            </Button>
                                                    }
                                                    {/* {
                                                        create ?
                                                            <Button
                                                                color="success"
                                                                onClick={() => handleFileClicks()}
                                                                className="btn-label">
                                                                <i className="ri-upload-2-line label-icon align-middle fs-16 me-2"></i>
                                                                Upload file
                                                            </Button> : null} */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="row-cols-xxl-12 row-cols-lg-12 row-cols-1 mt-1">
                                            <TableContainer
                                                columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                                data={finance || []}
                                                customPageSize={pageSize}
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isPageSizeChange={true}
                                                handleLeadClick={handleLeadClick}
                                                setisDeleteOpen={setisDeleteOpen}
                                                pageChanged={pageChanged}
                                                pagination={pagination}
                                                setPagination={setPagination}
                                                onChangePageSize={onChangePageSize}
                                                pageSize={pageSize}
                                                setpreview={setpreview}
                                                props={props}
                                                access={access}
                                                setUpdateModal={setUpdateModal}
                                                redirect={redirect}
                                            />
                                        </Row>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                        <Modal backdrop="static" id="createFileModal" isOpen={modalFile} toggle={fileToggle} modalClassName="zoomIn" centered tabIndex={1}>
                            <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]) }} className="p-3 bg-success-subtle">{"Upload File"}</ModalHeader>
                            <ModalBody>
                                <Upload filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                            </ModalBody>
                        </Modal>
                    </React.Fragment>
                    : <AccessDenied />
                : <ListingLoader
                    columns={columns}
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    thClass="border-bottom-1 table-soft-primary"
                />}
        </>
    );
};
export default withTranslation()(Finance);


