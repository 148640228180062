import React, { useEffect, useState } from 'react';
import { Container} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import RecommendSuccess from './MigrationConfirmationSuccess';
import { isMobile } from 'react-device-detect';
import ReactDOMServer from "react-dom/server";
import { SendEmail } from 'store/applications/action';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps {
    t: (key: string) => string;
}

const Recommendations: React.FC<ResetPasswordProps> = ({ t }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

     const EmailBody: React.FC<any> = () => (
            <div>
                <p>Dear Developer,</p>
                <p>I hope this email finds you well.</p>
                <p>
                    The migration of data request has been approved.
                </p>
            </div>
        );


    useEffect(() => {
            const toemail = 'info@zilter.io';            
        // Convert React component to static HTML
        const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
            <EmailBody />
        );
        const data = {
            studentId:'4877bddd-341a-466d-8b95-0479f9ea9847',
            to: toemail,
            subject: "Data migration approval request",
            body: emailBodyHTML
        }
        const handleSuccess = () => {
        }
        const handleFailure = () => {}
        let token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aWtyYW50aUB6aWx0ZXIuaW8iLCJuYW1lIjoiVmlrcmFudGkgSmFkaGF2Iiwicm9sZSI6IjhiN2UyNGZhLWM3MTctNGU5MC05MjczLWRmZjc1YzQ2YzZmZiIsInRlbmFudF9pZCI6IjJiYzY0NWFiMmFhZDRkYzFhZDJmYTg3MjEwMjlhYjY4IiwidXNlcl9pZCI6IjYzYjJiMjI5LWU3OGEtNDEzNC05NjYxLTUzODY1ZDc1NzYyMyIsImN1cnJlbnRfc3RhZ2UiOiI4Iiwic3Vic2NyaXB0aW9uIjoiIiwiaWF0IjoxNzM0MzUxNjIzLCJleHAiOjE3MzQ2MTA4MjN9.r-aynpVZQMTlVsoqSEUN_VITo4ufz3EeArJn-kyt09s'
        dispatch(SendEmail(data, handleSuccess, handleFailure, token))
    }, [])
    
    return (
        <div>
            {!isMobile ? 
                <div className='page-content'>
                    <Container fluid>
                        <RecommendSuccess />
                    </Container>
                </div>
                :
                <Container fluid>   
                     <ToastContainer />
                    <RecommendSuccess />
                </Container>
            }
        </div>
    );
};

export default withTranslation()(Recommendations);