import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import { courseGetRequest, coursesMetadataGetRequest, courseUpdateRequest } from 'store/course/action';
import FeeStruture from './FeeStruture';
import { useParams } from 'react-router-dom';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

const Details = (props: any) => {    
    const { id, childId} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const details: any= useSelector((state: ApplicationState) => state.courses.details);
    
    const tabList = [{
        title: 'Fee Struture',
        component: FeeStruture
    }]

    
    useEffect(() => {
        dispatch(courseGetRequest(childId || id))
    }, [])

    let count = parseInt(details?.valuesJson?.course_duration) || 0;

    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>       
                <Section 
                    detailsGetAction={courseGetRequest}
                    metaDatagetRequest={coursesMetadataGetRequest}
                    updateAction={courseUpdateRequest}
                    model={"courses"}
                    reducerState={"courses"}
                    translater={"courses"}
                    label={"Courses"}
                    tabList={count > 0 ? tabList : null}
                />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Details);