import { DateTime } from "luxon";

export interface Table {
    table_name: string;
    schema_name: string;
    type: string;
    columns: Column[];
}

export interface Column {
    column_name: string;
    column_type: string;
    is_primary_key: string;
    label: string;
    input_type: string;
    is_json_field: boolean;
    foreign_key_table: string;
    additional_details: any;
}

export interface ReportFeatureStatus {
    current_version: any;
    latest_version: string;
    is_up_to_date: string;
    message: string;
}

export interface Report {
    id: string;
    name: string;
    config: any;
    created_at: DateTime;
    updated_at: DateTime;
}

export interface Layout {
    key: string;
    title: string;
    layout: {
        i: string; // Unique identifier for the layout
        x: number;
        y: number;
        w: number;
        h: number;
    };
    report: Report;
}

export interface GeneratedReport {
    chart_type: string;
    data: any;
    series: any;
    xAxis: any;
    yAxis: any;
    config: string;
    pagination: Pagination;
}

export interface Error {
    status: string;
    message: string;
    error_type: string;
    error_code: number;
    timestamp: string;
    error_details: boolean;
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean,
  first: boolean,
  offset: number,
  hasNext: boolean,
  hasPrevious: boolean,
}

export interface Dashboard {
    id: string;
    name: string;
    config: any;
    created_at: DateTime;
    updated_at: DateTime;
    created_by: string;
    updated_by: string;
    dashboard_reports: any;
}

export enum ReportActionTypes {
    GET_TABLES_REQUEST = "@@report/GET_TABLES_REQUEST",
    GET_TABLES_SUCCESS = "@@report/GET_TABLES_SUCCESS",
    GET_TABLES_ERROR = "@@report/GET_TABLES_ERROR",
    GET_COLUMNS_REQUEST = "@@report/GET_COLUMNS_REQUEST",
    GET_COLUMNS_SUCCESS = "@@report/GET_COLUMNS_SUCCESS",
    GET_COLUMNS_ERROR = "@@report/GET_COLUMNS_ERROR",
    GENERATE_REPORT_REQUEST = "@@report/GENERATE_REPORT_REQUEST",
    GENERATE_REPORT_SUCCESS = "@@report/GENERATE_REPORT_SUCCESS",
    GENERATE_REPORT_ERROR = "@@report/GENERATE_REPORT_ERROR",
    GENERATE_DETAIL_REPORT_REQUEST = "@@report/GENERATE_DETAIL_REPORT_REQUEST",
    GENERATE_DETAIL_REPORT_SUCCESS = "@@report/GENERATE_DETAIL_REPORT_SUCCESS",
    GENERATE_DETAIL_REPORT_ERROR = "@@report/GENERATE_DETAIL_REPORT_ERROR",
    // Report
    POST_REPORT_REQUEST = "@@report/POST_REPORT_REQUEST",
    POST_REPORT_SUCCESS = "@@report/POST_REPORT_SUCCESS",
    POST_REPORT_ERROR = "@@report/POST_REPORT_ERROR",
    GET_REPORT_REQUEST = "@@report/GET_REPORT_REQUEST",
    GET_REPORT_SUCCESS = "@@report/GET_REPORT_SUCCESS",
    GET_REPORT_ERROR = "@@report/GET_REPORT_ERROR",
    GET_REPORTS_REQUEST = "@@report/GET_REPORTS_REQUEST",
    GET_REPORTS_SUCCESS = "@@report/GET_REPORTS_SUCCESS",
    GET_REPORTS_ERROR = "@@report/GET_REPORTS_ERROR",
    PUT_REPORT_REQUEST = "@@report/PUT_REPORT_REQUEST",
    PUT_REPORT_SUCCESS = "@@report/PUT_REPORT_SUCCESS",
    PUT_REPORT_ERROR = "@@report/PUT_REPORT_ERROR",
    DELETE_REPORT_REQUEST = "@@report/DELETE_REPORT_REQUEST",
    DELETE_REPORT_SUCCESS = "@@report/DELETE_REPORT_SUCCESS",
    DELETE_REPORT_ERROR = "@@report/DELETE_REPORT_ERROR",
    GET_REPORTS_FEATURE_STATUS_REQUEST = "@@report/GET_REPORTS_FEATURE_STATUS_REQUEST",
    GET_REPORTS_FEATURE_STATUS_SUCCESS = "@@report/GET_REPORTS_FEATURE_STATUS_SUCCESS",
    GET_REPORTS_FEATURE_STATUS_ERROR = "@@report/GET_REPORTS_FEATURE_STATUS_ERROR",
    GET_REPORTS_FEATURE_ACTIVATION_REQUEST = "@@report/GET_REPORTS_FEATURE_ACTIVATION_REQUEST",
    GET_REPORTS_FEATURE_ACTIVATION_SUCCESS = "@@report/GET_REPORTS_FEATURE_ACTIVATION_SUCCESS",
    GET_REPORTS_FEATURE_ACTIVATION_ERROR = "@@report/GET_REPORTS_FEATURE_ACTIVATION_ERROR",
    // Dashboard
    POST_DASHBOARD_REQUEST = "@@DASHBOARD/POST_DASHBOARD_REQUEST",
    POST_DASHBOARD_SUCCESS = "@@DASHBOARD/POST_DASHBOARD_SUCCESS",
    POST_DASHBOARD_ERROR = "@@DASHBOARD/POST_DASHBOARD_ERROR",
    GET_DASHBOARD_REQUEST = "@@DASHBOARD/GET_DASHBOARD_REQUEST",
    GET_DASHBOARD_SUCCESS = "@@DASHBOARD/GET_DASHBOARD_SUCCESS",
    GET_DASHBOARD_ERROR = "@@DASHBOARD/GET_DASHBOARD_ERROR",
    GET_DASHBOARDS_REQUEST = "@@DASHBOARD/GET_DASHBOARDS_REQUEST",
    GET_DASHBOARDS_SUCCESS = "@@DASHBOARD/GET_DASHBOARDS_SUCCESS",
    GET_DASHBOARDS_ERROR = "@@DASHBOARD/GET_DASHBOARDS_ERROR",
    PUT_DASHBOARD_REQUEST = "@@DASHBOARD/PUT_DASHBOARD_REQUEST",
    PUT_DASHBOARD_SUCCESS = "@@DASHBOARD/PUT_DASHBOARD_SUCCESS",
    PUT_DASHBOARD_ERROR = "@@DASHBOARD/PUT_DASHBOARD_ERROR",
    DELETE_DASHBOARD_REQUEST = "@@DASHBOARD/DELETE_DASHBOARD_REQUEST",
    DELETE_DASHBOARD_SUCCESS = "@@DASHBOARD/DELETE_DASHBOARD_SUCCESS",
    DELETE_DASHBOARD_ERROR = "@@DASHBOARD/DELETE_DASHBOARD_ERROR",
}

export interface ReportState {
    readonly tables: Table[];
    readonly tables_loading: boolean;
    readonly error: Error | null;
    readonly columns: Column[];
    readonly columns_loading: boolean;
    readonly generated_report_loading: boolean;
    readonly generated_report: GeneratedReport | null;
    readonly generated_report_detail_loading: boolean;
    readonly generated_report_detail: GeneratedReport | null;
    readonly pagination_detail: Pagination | null;
    readonly report_feature_status_loading: boolean;
    readonly report_feature_status: ReportFeatureStatus | null;
    readonly report_feature_activation_loading: boolean;
    readonly report_save_loading: boolean;
    readonly report_delete_loading: boolean;
    readonly reports_loading: boolean;
    readonly reports: Report[] | null;
    readonly reports_pagination: Pagination | null;
    readonly report_loading: boolean;
    readonly report: Report | null;

    //Dashboard
    readonly dashboard_save_loading: boolean;
    readonly dashboards_loading: boolean;
    readonly dashboards: Dashboard[] | null;
    readonly dashboards_pagination: Pagination | null;
    readonly dashboard_loading: boolean;
    readonly dashboard: Dashboard | null;
    readonly dashboard_delete_loading: boolean;
}
