import { Reducer } from "redux";
import { AuthActionTypes, AuthState } from "./types";
export const initialState: AuthState = {
    userProfile: {
        id: "",
        name: "",
        tenantId: {
            id: "",
            email: ""
        },
        email: "",
        password: "",
        companyName: "",
        website: "",
        phoneNumber: "",
        role: {
            permissions: [],
            title: "",
            id: ""
        },
        otp: "",
        alternatePhoneNumber: "",
        accountStatus: "",
        profilePic: "",
        confirmationStatus: "",
        createdTime: "",
        updatedTime: "",
        noOfEmployees: "",
        currentStage: undefined,
        tenant: {
            companyName: "",
            email: "",
            noOfEmployees: "",
            website: "",
            id: ""
        },
        subAgent: null,
        salt: [""],
        values_json: null
    },
    errors: undefined,
    loading: false,
    response: {},
    token: localStorage.getItem('dts_token') ? JSON.parse(localStorage.getItem('dts_token') || "") : "",
    isUserLoggedin: false,
    optionData: [],
    optionsLoading: false,
    optionmetaData: [],
    models: [],
    subLevelPermissionsList: [],
    permissionloading: false,
    parentPermmission: [],
    meloading: false,
    MultiToken: null,
    propertiesOptionData: []
};

function loadTokensFromLocalStorage() {
    const tokens: any = {};
    Object.keys(localStorage).forEach(key => {
        if (key.startsWith('dts_token_')) {
            const tenantId = key.replace('dts_token_', '');
            tokens[tenantId] = JSON.parse(localStorage.getItem(key) || "");
        }
    });
    return tokens;
}


const reducer: Reducer<AuthState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case AuthActionTypes.POST_SIGNIN_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_SIGNIN_SUCCESS: {
            let tokens: any = {}; // This will store multiple tokens
            const tokenData = action.payload;
        
            if (Array.isArray(tokenData)) {
                tokenData.forEach(item => {
                    if (item.tenant && item.tenant.id) {
                        const tokenInfo = {
                            token: item.token,
                            companyName: item.tenant.companyName,
                            website: item.tenant.website
                        };
                        tokens[item.tenant.id] = tokenInfo;
                    }
                });
        
                return {
                    ...state,
                    loading: false,
                    isUserLoggedin: true,
                    MultiToken: tokens,
                };
            } else {
                const token = action.payload.token;
        
                return {
                    ...state,
                    loading: false,
                    isUserLoggedin: true,
                    token: token,
                };
            }
        }
         
        case AuthActionTypes.POST_SIGNIN_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.POST_SET_TOKEN: {
            localStorage.setItem('dts_token', JSON.stringify(action.payload));
            return { ...state, token: action.payload };
        } 
        case AuthActionTypes.POST_SIGNUP_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_SIGNUP_SUCCESS: {
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            let accessToken = action.payload.token && action.payload.token !== "null" ? action.payload.token : null
            return { ...state, loading: false, token: accessToken };
        }
        case AuthActionTypes.POST_SIGNUP_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.POST_VERIFY_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_VERIFY_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_VERIFY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_SIGNOUT: {
            localStorage.clear()
            return { ...state, loading: false, userProfile: null, isUserLoggedin: false, token: "", response: {} };
        }
        case AuthActionTypes.RESET_STUDENTS: {
            localStorage.removeItem('invoiceItems');
            localStorage.removeItem('invoiceCustomer');
            return { ...state }
        }
        case AuthActionTypes.SET_INVOICE_STUDENTS: {
            localStorage.setItem('invoiceItems', JSON.stringify(action.payload.students))
            localStorage.setItem('invoiceCustomer', JSON.stringify(action.payload.channel))
            return { ...state };
        }
        case AuthActionTypes.POST_UPDATE_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_UPDATE_USER_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_UPDATE_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.POST_JOIN_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_JOIN_USER_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_JOIN_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.POST_FORGOT_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_FORGOT_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_FORGOT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case AuthActionTypes.POST_RESEND_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_RESEND_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_RESEND_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_USER_REQUEST: {
            return { ...state, meloading: true };
        }
        case AuthActionTypes.GET_USER_SUCCESS: {
            let permissions = action.payload?.role?.permissions;
            const parentNames = permissions.filter((permission: any) => permission.parentPermission === null).map((permission: any) => permission.name.toLowerCase());
            let newpermissionsubpermission: any = []
            parentNames && parentNames.length && parentNames.map((item: any) => {
                newpermissionsubpermission[item] = []
                permissions && permissions.length && permissions.map((permission: any) => {
                    if (permission.parentPermission && permission.parentPermission?.name && permission.parentPermission?.name.toLowerCase() === item && permission.value != "" && permission.value) {
                        newpermissionsubpermission[item].push(permission)
                    }
                    else if (permission.parentPermission == null && permission.name.toLowerCase() === item && permission.value != "" && permission.value) {
                        newpermissionsubpermission[item].push(permission)
                    }
                });
            });
            const result: any = {};
            const parents: any = [];
            for (const [key, value] of Object.entries(newpermissionsubpermission)) {
                if (Array.isArray(value) && value.length > 0) {
                    let istrue = value.some((permission: any) =>
                        permission.value !== "none" && permission.value !== "" && permission.value !== "false" && permission.value !== "FALSE"
                    );
                    if (istrue) {
                        result[key] = value;
                        parents.push(key)
                    }
                }
            }
            return { ...state, userProfile: action.payload, isUserLoggedin: true, subLevelPermissionsList: result, parentPermmission: parents, meloading: false, };
        }
        case AuthActionTypes.GET_USER_ERROR: {
            return { ...state, meloading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_PROPERTIES_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.GET_PROPERTIES_SUCCESS: {
            return { ...state, loading: false, optionmetaData: action.payload.content, isUserLoggedin: true };
        }
        case AuthActionTypes.GET_PROPERTIES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_MODELS_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.GET_MODELS_SUCCESS: {
            return { ...state, loading: false, models: action.payload };
        }
        case AuthActionTypes.GET_MODELS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_OPTIONS_REQUEST: {
            return { ...state, optionsLoading: true, optionData: [] };
        }
        case AuthActionTypes.GET_OPTIONS_SUCCESS: {
            return { ...state, optionsLoading: false, optionData: {...state.optionData, [action.model]:action.payload.content} };
        }
        case AuthActionTypes.GET_OPTIONS_ERROR: {
            return { ...state, optionsLoading: false, errors: action.payload, optionData: [] };
        }
        case AuthActionTypes.GET_PROPERTIES_OPTIONS_REQUEST: {
            return { ...state, optionsLoading: true, propertiesOptionData: [] };
        }
        case AuthActionTypes.GET_PROPERTIES_OPTIONS_SUCCESS: {
            return { ...state, optionsLoading: false, propertiesOptionData: {...state.propertiesOptionData, [action.model]:action.payload.content} };
        }
        case AuthActionTypes.GET_PROPERTIES_OPTIONS_ERROR: {
            return { ...state, optionsLoading: false, errors: action.payload, propertiesOptionData: [] };
        }
        case AuthActionTypes.POST_RESPONSE_SUCCESS: {
            return { ...state, loading: false, response: action.payload };
        }               
        case AuthActionTypes.POST_RESETPASSWORD_USER_REQUEST: {
            return { ...state, permissionloading: true };
        }
        case AuthActionTypes.POST_RESETPASSWORD_USER_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.POST_RESETPASSWORD_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_SUCCESS: {
            return { ...state, loading: false };
        }
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS: {
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            let accessToken = action.payload.token && action.payload.token !== "null" ? action.payload.token : null
            return { ...state, loading: false, token: accessToken };
        }
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as AuthReducer };
