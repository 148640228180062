import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import { tasksMetadataGetRequest } from "store/tasks/action";
import Loader from './FormLoader'

const AddEntry = ({ studentId, applicationId, props, show, onCloseClick, setTriggerApi, createAction, parent, parentId, userProfile }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    
    const metaLoading = useSelector((state: ApplicationState) => state.tasks.metaLoading);
    const dataFields = useSelector((state: ApplicationState) => state.tasks.metaDataFields);
    const [disabled, setDisabled] = useState<any>(false)
    const OnAddEntry = (formValues: any) => {
        setDisabled(true)  
        let newformValues = formValues;
        let assigned_by= userProfile?.id
        if(!newformValues?.assigned_to) {
            newformValues = {...newformValues, assigned_to:  userProfile?.id}
        }
        let data: any = {...newformValues,
            assigned_by: assigned_by,             
            student: studentId,
            application: applicationId ? applicationId : null}
        const handleSuccess = (body: any) => {
            onCloseClick()
            setDisabled(false)
            setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const handleFailure = (body: any) => {
            setDisabled(false)
        }
        dispatch(createAction(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
            let filterObject = {pageSize: 100}
            dispatch(tasksMetadataGetRequest(filterObject))
    }, [])

    return (
        <Modal
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >

            <ModalHeader  className="p-3 bg-primary-subtle" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2">
                    {props.t("student.add_new_entry")}
                </span>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <SimpleBar style={{maxHeight: 450}}>
                            { metaLoading ? <Loader />
                           :  <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Task'} formfor="students"  btndisabled={disabled} />
                            </div>}
                        </SimpleBar>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default AddEntry;

