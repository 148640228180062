import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'reactstrap';

const generateRandomCode = () => Math.floor(1000 + Math.random() * 9000).toString();

const InPersonVerify = ({ onSelfieCapture }: { onSelfieCapture: (selfie: string, code: string) => void }) => {
    const webcamRef = useRef<Webcam | null>(null);
    const [status, setStatus] = useState("Please take a selfie.");
    const [selfie, setSelfie] = useState<string | null>(null);
    const [code, setCode] = useState<string>(generateRandomCode());

    // Capture selfie and send it to parent component
    const captureSelfie = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                setSelfie(imageSrc);
                setStatus("Selfie captured successfully!");
                onSelfieCapture(imageSrc, code); // Send the selfie and code to parent
            } else {
                setStatus("Failed to capture selfie. Please try again.");
            }
        } else {
            setStatus("Webcam is not available. Please check your camera.");
        }
    }, [onSelfieCapture, code]);

    // Allow retaking of selfie and generating a new code
    const retakeSelfie = () => {
        setSelfie(null);
        setStatus("Please take a selfie.");
        setCode(generateRandomCode()); // Generate a new code for retake
    };

    return (
        <div className="page-content">
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h1>Take a Selfie</h1>
                {/* Verification Code is always displayed above */}
                <h2>
                    Verification Code: <span style={{ color: 'blue' }}>{code}</span>
                </h2>
                {!selfie ? (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        style={{ marginBottom: '20px', width: '100%', maxWidth: '800px' }}
                    />
                ) : (
                    <div>
                        <h2>Your Selfie:</h2>
                        <img
                            src={selfie}
                            alt="Captured selfie"
                            style={{ width: '100%', maxWidth: '400px', marginTop: '20px', borderRadius: '10px' }}
                        />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    {!selfie ? (
                        <Button
                            color="primary"
                            onClick={captureSelfie}
                            style={{ padding: '10px 20px', fontSize: '16px', marginBottom: '10px' }}
                        >
                            Capture Selfie
                        </Button>
                    ) : (
                        <Button
                            color="warning"
                            onClick={retakeSelfie}
                            style={{ padding: '10px 20px', fontSize: '16px', marginTop: '10px' }}
                        >
                            Retake Selfie
                        </Button>
                    )}
                </div>
                <p>{status}</p>
            </div>
        </div>
    );
};

export default InPersonVerify;
