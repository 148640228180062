import {ViewActionTypes, View} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const viewGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ViewActionTypes.GET_VIEW_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/views/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, ViewActionTypes.GET_VIEW_SUCCESS, ViewActionTypes.GET_VIEW_ERROR, null, null, null, "GET");
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const viewMetadataGetRequest: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ViewActionTypes.GET_VIEWMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, ViewActionTypes.GET_VIEWMETADATA_SUCCESS, ViewActionTypes.GET_VIEWMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const viewUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ViewActionTypes.PUT_VIEW_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        try { 
            const response = await fetch(
                `${DTS_API_STUDENT}/views/${id}`,
                {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(data)
                }
            );                          
            const error = {
                204: {
                    "message": "View saved Successfully",
                    "success": true
                },
                200: {
                    "message": "View saved Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, ViewActionTypes.PUT_VIEW_SUCCESS, ViewActionTypes.PUT_VIEW_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            dispatch({ type: ViewActionTypes.PUT_VIEW_ERROR, payload: error });
            handleError();
        }
    };
};

export const viewDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ViewActionTypes.DELETE_VIEW_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/views/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "View deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "View deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, ViewActionTypes.DELETE_VIEW_SUCCESS, ViewActionTypes.DELETE_VIEW_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: ViewActionTypes.DELETE_VIEW_ERROR
            });
            handleSuccess();
        }
    };
};

export const viewCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: ViewActionTypes.POST_VIEW_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/views`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "View already exist",
                    "success": false
                },
                201: {
                    "message": "View created Successfully",
                    "success": true
                },
                200: {
                    "message": "View created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, ViewActionTypes.POST_VIEW_SUCCESS, ViewActionTypes.POST_VIEW_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: ViewActionTypes.POST_VIEW_ERROR, payload: error });
            handleError();
        }
    };
};

const viewcreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/views?pageSize=100&`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const ViewListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ViewActionTypes.GET_VIEWS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = viewcreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ViewActionTypes.GET_VIEWS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ViewActionTypes.GET_VIEWS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const storeFilterToLocal: AppThunk = (filterObject,page, size) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: ViewActionTypes.POST_FILTER_SUCCESS,
            payload: filterObject
        });
    };
};

export const storeViewToLocal: AppThunk = (obj) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: ViewActionTypes.POST_STORE_VIEW_SUCCESS,
            payload: obj
        });
    };
};

export const getFilterFromLocal: AppThunk = (filterObject,page, size) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: ViewActionTypes.GET_FILTER_REQUEST
        });  
        dispatch({
            type: ViewActionTypes.GET_FILTER_SUCCESS,
        });
    };
};

export const getViewFromLocal: AppThunk = (filterObject,page, size) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: ViewActionTypes.GET_STORE_VIEW_REQUEST
        });  
        dispatch({
            type: ViewActionTypes.GET_STORE_VIEW_SUCCESS,
        });
    };
};





