import React, { useState, useRef } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../../config"
import { useParams } from 'react-router'
import { userGetRequest } from 'store/auth/action'
import { tenantGetRequest, tenantUpdateRequest } from 'store/user/action'
import { toast } from 'react-toastify'
const { DTS_API_DOMAIN } = config.api;

interface FProps {
    selectedFiles: any;
    setselectedFiles: (event: any) => void;
    fileInfo: any;
    setFileInfo:  (event: any) => void;
    onCloseModal:() => void;
}

const Upload: React.FC<FProps> = ({
    selectedFiles,
    setselectedFiles,
    fileInfo,
    setFileInfo,
    onCloseModal }
) => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    
    const submitUpload = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
        setFileInfo(file)
        if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const config = {
                    headers: {
                        "token": access_token
                    },
                }
                await axios.put(`${DTS_API_DOMAIN}/tenants/uploadLogo`, formData, config)
                .then((res: any) => {      
                    if(res === 'File uploaded successfully.') {                        
                        dispatch(tenantGetRequest())     
                        onCloseModal()   
                    }                   
                })
        }
    }
    
    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} submitUpload={submitUpload} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload