import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row} from 'reactstrap';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Territory from './SubComponent/TerritoryComponent';
import Tier from './SubComponent/TierComponent';
import { ApplicationState } from 'store';
import { coursesMetadataGetRequest } from 'store/course/action';
import { isJson } from 'utils';
import { financeCreateRequest, financeDetailsGetRequest, financeUpdateRequest, getCommissionFields, financeSubagentCreateRequest, financeSubagentUpdateRequest, financeSubagentDetailsGetRequest, financeRulesListGetRequest, financeSubagentRulesListGetRequest } from 'store/finance/action';
import Courses from './SubComponent/Courses';
import { useNavigate, useParams } from 'react-router-dom';
import { universityGetRequest } from 'store/university/action';
import { optionsListGetRequest } from 'store/auth/action';
import basicdynamicFields from './Fields/basicfields.json'
import Select from 'react-select';
import Common from './Common/Common';
import Bonus from './SubComponent/Bonus';
import { ToastContainer } from 'react-toastify';
import { channelGetRequest } from 'store/channels/action';
import { subagentGetRequest } from 'store/subagents/action';
import { contractListGetRequest } from 'store/contracts/action';
import { isEqual } from 'lodash';
import { commissionsMetadataGetRequest } from 'store/commissions/action';


const ComissionCreate = (props: any) => {
    const { universityId, financeId } = useParams()
    const location = useLocation();
    const url = location.pathname;
    let pathParts =  url.split('/');
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const coursesMetadata = useSelector((state: ApplicationState) => state.courses.metaDataFields);
    const dataFields = useSelector((state: ApplicationState) => state.commissions.metaDataFields);
    const commissionFields = useSelector((state: ApplicationState) => state.finance.commissionFields);
    const model = useSelector((state: ApplicationState) => state.finance.commissionFields?.key);
    const subkey = useSelector((state: ApplicationState) => state.finance.commissionFields?.subkey);
    const id2 = useSelector((state: ApplicationState) => state.finance.commissionFields?.id2);
    const details: any = useSelector((state: ApplicationState) => state.finance.ruledetails);
    const universityDetails: any = useSelector((state: ApplicationState) => state.universities.details);
    const channelDetails: any = useSelector((state: ApplicationState) => state.channels.details);
    const subagentsDetails: any = useSelector((state: ApplicationState) => state.subagents.details);
    const contractList: any = useSelector((state: ApplicationState) => state.contracts.list);
    const optionData = useSelector((state: ApplicationState) => state.auth.optionData);
    const [commissionJson, setCommissionJson] = useState<any>({});
    const [isTerritory, setIsTerritory] = useState(false)
    const [isBonus, setIsBonus] = useState(false)
    const [isTierRule, setIsTierRule] = useState(false)
    const [isPromotional, setIsPromotional] = useState(false)
    const [filterId, setFilterId] = useState<any>("")
    const [levels, setLevels] = useState<any>([]);
    const [courseOptions, setCourseOptions] = useState([])
    const [dynamicFields, setDynamicFields] = useState({})
    const [courseLevels, setCourseLevels] = useState([])
    const [aboutDetails, setDetails] = useState<any>({})
    const [commissionMetaData, setCommissionFields] = useState<any>([])
    const [renderComponent, setRenderComponent] = useState<any>(null)
    const [locationarr, setlocationarr] = useState<any>(null)
    const [selectedYears, setSelectedYears] = useState<any>([])
    const [btndisabled, setBtndisabled] = useState(false)
    const [selectedMonths, setSelectedMonths] = useState([])
    const [contractsOption, setContractsOptions]= useState<any>([])
    const [selectRuleType, setSelectedRuleType] = useState<any>()
    const [selectContract, setSelectContract] = useState<any>()
    const [preFilledKey, setPreFilledKey]= useState<any>(null)
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [component, setComponents]  = useState<any>([]);    
    const [search, setSearch] = useState('');
    const [preFilledKeyValue, setPreFilledKeyValue] = useState<any>({});
    const [formFields, setFormField]  = useState<any>([]); 

    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    const months = [
        { label: 'January', value: 'january' },
        { label: 'February', value: 'february' },
        { label: 'March', value: 'march' },
        { label: 'April', value: 'april' },
        { label: 'May', value: 'may' },
        { label: 'June', value: 'june' },
        { label: 'July', value: 'july' },
        { label: 'August', value: 'august' },
        { label: 'September', value: 'september' },
        { label: 'October', value: 'october' },
        { label: 'November', value: 'november' },
        { label: 'December', value: 'december' },
    ];

    useEffect(() => {
        setlocationarr(pathParts)
    }, [url])

    const years = [
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 },
        { label: '2027', value: 2027 },
        { label: '2028', value: 2028 },
        { label: '2029', value: 2029 },
        { label: '2030', value: 2030 },
        { label: '2031', value: 2031 },
        { label: '2032', value: 2032 },
        { label: '2033', value: 2033 },
        { label: '2034', value: 2034 },
        { label: '2035', value: 2035 },
        { label: '2036', value: 2036 },
        { label: '2037', value: 2037 },
        { label: '2038', value: 2038 },
        { label: '2039', value: 2039 },
        { label: '2040', value: 2040 },
    ]

    useEffect(() => {
        dispatch(coursesMetadataGetRequest("courses", { pageSize: 500 }, 0));
        dispatch(commissionsMetadataGetRequest({ pageSize: 500 }, 0));
        dispatch(getCommissionFields());
    }, [])

    useEffect(() => {
            let newFields: any= []
            dataFields.map((item: any) => {
                let valuesJson = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) :  item?.valuesJson;
                 if (model === 'channels') {
                    if(valuesJson?.key === 'channelId' || valuesJson?.key === 'subagentId') return 
                    else newFields.push(item)
                }
                else if (model === 'institutes') {
                    if (model === 'institutes') {
                        if (subkey === 'channels') {
                            if(valuesJson?.key === 'instituteId' || valuesJson?.key === 'subagentId') return 
                            else newFields.push(item)
                        }
                        else if (subkey === 'subagents') {
                            if(valuesJson?.key === 'instituteId' || valuesJson?.key === 'channelId') return 
                            else newFields.push(item)
                        }
                    }
                }
                else if (model === 'subagents') {
                    if(valuesJson?.key === 'subagentId' || valuesJson?.key === 'channelId') return 
                    else newFields.push(item)
                }
            })
            setFormField(newFields)
    }, [dataFields, model, subkey])


    useEffect(() => {
        if (model === 'channels') {
            dispatch(channelGetRequest(universityId))            
            const filtersData: any = {
                "filters": [
                    {
                        quick: [],
                        advance: [],
                        search: null,
                        parent: [{
                            "key": 'channels',
                            "keyLabel": 'channels',
                            "condition": "IN",
                            "conditionLabel": "is any of",
                            "values": [universityId],
                            "valuesLabel": [],
                            "property": {},
                            "quick": true,
                        }]
                    }
                ],
                "sorts": [
                    {
                        "field": "createdAt",
                        "order": "desc"
                    }
                ]
            }
            dispatch(contractListGetRequest(filtersData));
            if(id2) {
                const filters = {
                    channelId: universityId,
                    instituteId: id2,
                    pageSize: 100
                }
                dispatch(universityGetRequest(id2)) 
                dispatch(financeRulesListGetRequest(filters, 0, null));
            }
        }
        else if (model === 'institutes') {
            setFilterId(universityId)
            dispatch(universityGetRequest(universityId))  
            const filtersData: any = {
                "filters": [
                    {
                        quick: [],
                        advance: [],
                        search: null,
                        parent: [{
                            "key": 'institutes',
                            "keyLabel": 'institutes',
                            "condition": "IN",
                            "conditionLabel": "is any of",
                            "values": [universityId],
                            "valuesLabel": [],
                            "property": {},
                            "quick": true,
                        }]
                    }
                ],
                "sorts": [
                    {
                        "field": "createdAt",
                        "order": "desc"
                    }
                ]
            }
            dispatch(contractListGetRequest(filtersData));
            if (model === 'institutes') {
                if (subkey === 'channels') {
                    if(id2) {
                        const filters = {
                            channelId: id2,
                            instituteId: universityId,
                            pageSize: 100
                        }
                        dispatch(channelGetRequest(id2)) 
                        dispatch(financeRulesListGetRequest(filters, 0, null));
                    }
                }
                else if (subkey === 'subagents') {
                    if(id2) {
                        const filters = {
                            subagentId: id2,
                            instituteId: universityId,
                            pageSize: 100
                        }
                        dispatch(subagentGetRequest(id2)) 
                        dispatch(financeSubagentRulesListGetRequest(filters, 0, null));
                    }
                }
            }
        }
        else if (model === 'subagents') {
            dispatch(subagentGetRequest(universityId))   
            const filtersData: any = {
                "filters": [
                    {
                        quick: [],
                        advance: [],
                        search: null,
                        parent: [{
                            "key": 'subagents',
                            "keyLabel": 'Subagents',
                            "condition": "IN",
                            "conditionLabel": "is any of",
                            "values": [universityId],
                            "valuesLabel": [],
                            "property": {},
                            "quick": true,
                        }]
                    }
                ],
                "sorts": [
                    {
                        "field": "createdAt",
                        "order": "desc"
                    }
                ]
            }
            dispatch(contractListGetRequest(filtersData));
            if(id2) {
                const filters = {
                    subagentId: universityId,
                    instituteId: id2,
                    pageSize: 100
                }
                dispatch(universityGetRequest(id2)) 
                dispatch(financeSubagentRulesListGetRequest(filters, 0, null));
            }
        }
        if(pathParts.includes('create')) {
            if(id2) {                
                let key = null
                if (model === 'channels') {
                    key = {instituteId: id2};
                }
                if (model === 'institutes') {
                    if (subkey === 'channels') {
                        key = {channelId: id2};
                    }
                    else if (subkey === 'subagents') {
                        key = {channelId: id2};
                    }
                }
                else if (model === 'subagents') {
                    key = {instituteId: id2};
                } 
                setPreFilledKey(key);
            }
            else {
                setPreFilledKey(null)
            }
        }
    }, [model, id2])


     const execute = () => {
            let a = isEqual(locationarr, pathParts)
            return !a
        }

    useEffect(() => {
        if (model === 'channels') {
            let channel = channelDetails?.valuesJson && isJson(channelDetails?.valuesJson) ? JSON.parse(channelDetails?.valuesJson) : channelDetails?.valuesJson
            setDetails(channel)
        }
        else if (model === 'institutes') {
            let university = universityDetails?.valuesJson && isJson(universityDetails?.valuesJson) ? JSON.parse(universityDetails?.valuesJson) : universityDetails?.valuesJson
            setDetails(university)
        }
        else if (model === 'subagents') {
            let university = subagentsDetails?.valuesJson && isJson(subagentsDetails?.valuesJson) ? JSON.parse(subagentsDetails?.valuesJson) : subagentsDetails?.valuesJson
            setDetails(university)
        }
        
        if(pathParts.includes('create')) {
            if(id2) {                
                let key: any = null
                if (model === 'channels') {
                    key= {instituteId:{label: universityDetails?.valuesJson?.name, value: id2, id: id2}}
                }
                if (model === 'institutes') {
                    if (subkey === 'channels') {
                        key= {channelId:{label: channelDetails?.valuesJson?.name, value: id2, id: id2}}
                    }
                    else if (subkey === 'subagents') {
                        key= {subagentId: {label: subagentsDetails?.valuesJson?.name, value: id2, id: id2}}
                    }
                }
                else if (model === 'subagents') {
                    key= {instituteId:{label: universityDetails?.valuesJson?.name, value: id2, id: id2}}
                } 
                setPreFilledKeyValue(key);
            }
        }
        else if(pathParts.includes('edit')) {
            let key: any = null
            if (model === 'channels') {
                key= {instituteId:{label: commissionJson?.instituteId?.name, value: commissionJson?.instituteId?.id}}
            }
            if (model === 'institutes') {
                if (subkey === 'channels') {
                    key= {channelId:{label: commissionJson?.channelId?.name, value: commissionJson?.channelId?.id}}
                }
                else if (subkey === 'subagents') {
                    key= {subagentId:{label: commissionJson?.subagentId?.name, value: commissionJson?.subagentId?.id}}
                }
            }
            else if (model === 'subagents') {
                key= {instituteId:{label: commissionJson?.instituteId?.name, value: commissionJson?.instituteId?.id}}
            } 
            setPreFilledKeyValue(key);
        }
    }, [model, universityDetails, channelDetails, subagentsDetails, id2, execute(), commissionJson])

    useEffect(() => {
        let levelsfields: any = [];
        let newdynamicfields: any = {};
        coursesMetadata && coursesMetadata.length && coursesMetadata.map((item: any) => {
            let field = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
            if (item.id === "565432a0-1455-11ef-bfb2-0aab495878f3" || (field && field.key === "level")) {
                let options = field.values;
                setCourseLevels(options)
                options && options.map((item: any) => {
                    let level = {
                        "label": item.label,
                        "key": item?.label?.toLowerCase(),
                        "placeholder": `Enter ${item.label} Commission`,
                        "quickCreate": true,
                        "type": "groupinput",
                        "values": [
                            {
                                "label": "Fixed",
                                "value": "fixed",
                                "in_forms": true
                            },
                            {
                                "label": "Percent",
                                "value": "percent",
                                "in_forms": true
                            }
                        ]
                    }
                    let field = JSON.stringify(level);
                    let newField = { valuesJson: field };
                    levelsfields.push(newField)
                    let levelAddOn = [{ valuesJson: field }]
                    let comFilterFeild = basicdynamicFields.filter((field) => field.tier !==true)
                    let newDynamicField = [...levelAddOn, ...comFilterFeild]
                    newdynamicfields = { ...newdynamicfields, [item.label.toLowerCase()]: newDynamicField }
                })
                setLevels(levelsfields)
                setDynamicFields(newdynamicfields)
            }
        })
        let prefields = formFields || []
        let comFilterFeild = basicdynamicFields.filter((field) => field.tier !==true)
        let newCommission: any = [...prefields, ...levelsfields, ...comFilterFeild]
        setCommissionFields(newCommission)
    }, [coursesMetadata, commissionFields, formFields, dataFields])

    useEffect(() => {
        if (details?.valuesJson && details.id === financeId) {
            let valuesJson: any = details?.valuesJson;
            setCommissionJson(valuesJson)
            setName(details?.valuesJson?.name)
            setSelectedMonths(details?.valuesJson?.intake?.months)
            setSelectedYears(details?.valuesJson?.intake?.year || details?.valuesJson?.year)
            if(model === 'institutes'){
                setFilterId(universityId)
            } 
            else {setFilterId(details?.valuesJson?.instituteId?.id || details?.valuesJson?.instituteId)}
            setStartDate(details?.valuesJson?.startDate)
            setEndDate(details?.valuesJson?.endDate)
            setSelectedRuleType(details?.valuesJson?.ruleType)
            let ruleOption = contractsOption?.length ? contractsOption?.find((item: any) => item.value === details?.valuesJson?.contract) : null
            setSelectContract(ruleOption)
        }
        else setCommissionJson({})
    }, [details, contractsOption])

    useEffect(() => {
        if (financeId) {
            if (model === 'channels') {
                dispatch(financeDetailsGetRequest(financeId))
            }
            else if (model === 'institutes') {
                if (subkey === 'channels') {
                    dispatch(financeDetailsGetRequest(financeId))
                }
                else if (subkey === 'subagents') {
                    dispatch(financeSubagentDetailsGetRequest(financeId))
                }
            }
            else if (model === 'subagents') {
                dispatch(financeSubagentDetailsGetRequest(financeId))
            }
        }
    }, [model, financeId])

    const onAddRule = (formdata: any, key: any) => {
        let formValues = formdata;
        if (financeId && details?.valuesJson) {
            let channelId = null;
            let instituteId = null;
            let subagentId = null;
            if (model === 'channels') {
                channelId = universityId;
                instituteId = formValues?.instituteId?.id || formValues?.instituteId?.value || formValues?.instituteId || commissionJson?.basic?.instituteId?.Id || commissionJson?.basic?.instituteId || commissionJson?.instituteId?.Id ;
            }
            else if (model === 'institutes') {
                if (subkey === 'channels') {
                    channelId = formValues?.channelId?.id || formValues?.channelId?.value || formValues?.channelId ||  commissionJson?.basic?.channelId?.Id || commissionJson?.basic?.channelId || commissionJson?.channelId?.Id;
                    instituteId = universityId;
                }
                else if (subkey === 'subagents') {
                    subagentId = formValues?.subagentId?.id || formValues?.subagentId?.value || formValues?.subagentId ||  commissionJson?.basic?.subagentId?.Id || commissionJson?.basic?.subagentId || commissionJson?.subagentId?.Id
                    instituteId = universityId;
                }
            }
            let newCommission = null;
            if (key === 'basic') {
                newCommission = { ...commissionJson, name: name, 
                intake: selectRuleType === 'all' ? 'all' : { months: selectedMonths, year: selectedYears },
                instituteId: instituteId,
                channelId: channelId,
                subagentId: subagentId,
                [key]: { ...formValues,
                    instituteId: instituteId,
                    channelId: channelId,
                    subagentId: subagentId
                },
                ruleType: selectRuleType,
                startDate: startDate, 
                endDate: endDate, 
                contract: selectContract?.value  }
            }
            else {
                let keyData = formValues ? {...formValues,
                    instituteId: instituteId,
                    channelId: channelId,
                    subagentId: subagentId
                } : null
                newCommission = { ...commissionJson, 
                    [key]: key === 'courses' || key === 'territories' || key === 'tier'  || key === 'bonus' ? keyData  : { ...commissionJson[key], ...formValues,                        
                        instituteId: instituteId,
                        channelId: channelId,
                        subagentId: subagentId
                     }, 
                    "channelId": channelId, "instituteId": instituteId, "subagentId": subagentId ,
                     contract: selectContract?.value   }
               }
            setCommissionJson(newCommission)
            let data = {
                "valuesJson": newCommission
            }
            const handleSuccess = (body: any) => {
                setComponents([])
                if (subkey === 'subagents') {
                    dispatch(financeSubagentDetailsGetRequest(financeId))
                    setPreFilledKeyValue(null)               
                }
                else {
                    dispatch(financeDetailsGetRequest(financeId))
                    setPreFilledKeyValue(null)
                }
                setIsTerritory(false);
                setIsBonus(false);
                setIsTierRule(false);
                setIsPromotional(false);
            }
            if (subkey === 'subagents') {
                dispatch(financeSubagentUpdateRequest(financeId, data, handleSuccess, () => { }))
            }
            else {
                dispatch(financeUpdateRequest(financeId, data, handleSuccess, () => { }))
            }


        } else if (key === 'basic' && pathParts.includes('create')) {
            let channelId = null;
            let instituteId = null;
            let subagentId = null;
            if (model === 'channels') {
                channelId = universityId;
                instituteId = formValues?.instituteId?.id || formValues?.instituteId?.value || formValues?.instituteId;
            }
            else if (model === 'institutes') {
                if (subkey === 'channels') {
                    instituteId = universityId;
                    channelId = formValues?.channelId?.id || formValues?.channelId?.value || formValues?.channelId;
                 }
                else if (subkey === 'subagents') {
                    instituteId = universityId;
                    subagentId = formValues?.subagentId?.id || formValues?.subagentId?.value || formValues?.subagentId;
                 }
            }
            let newCommission = { ...commissionJson, 
                [key]: {...formValues,
                instituteId: instituteId,
                channelId: channelId,
                subagentId: subagentId},
                name: name,
                intake: selectRuleType === 'all' ? 'all' : { months: selectedMonths, year: selectedYears }, 
                instituteId: instituteId,
                channelId: channelId,
                subagentId: subagentId,  
                ruleType: selectRuleType, 
                startDate: startDate, endDate: endDate , contract: selectContract?.value   }
                setCommissionJson(newCommission)
            let data = {
                "valuesJson": newCommission
            }
            const handleSuccess = (body: any) => {
                if (subkey === 'subagents') {
                    dispatch(financeSubagentDetailsGetRequest(body.id))
                    setPreFilledKeyValue(null)
               
                }
                else {
                    dispatch(financeDetailsGetRequest(body.id))
                    setPreFilledKeyValue(null)
                }
                navigate(`/finance/${universityId}/${body.id}/edit`)
            }
            const handleError = () => {
            }
            if (subkey === 'subagents') {
                dispatch(financeSubagentCreateRequest(data, handleSuccess, handleError))
            }
            else {
                dispatch(financeCreateRequest(data, handleSuccess, handleError))
            }

        }
    }

    useEffect(() => {
        let options = contractList?.length && contractList?.map((item: any) => {
            return {label: item?.valuesJson?.name, value: item?.id}
        })
        setContractsOptions(options);
    }, [contractList])


    const onAddSubagentRule = (formValues: any, key: any) => {
        if (financeId && details?.valuesJson) {
            let subagentId = universityId;
            let instituteId = commissionJson?.instituteId?.Id || commissionJson?.basic?.instituteId?.id || commissionJson?.basic?.instituteId || commissionJson?.instituteId;
            let newCommission = null;
            if (key === 'basic') {
                newCommission = {
                    ...commissionJson, name: name,
                    intake: selectRuleType === 'all' ? 'all' : { months: selectedMonths, year: selectedYears },
                    instituteId: instituteId,
                    subagentId: subagentId,
                    [key]: { ...formValues,
                        instituteId: instituteId,
                        subagentId: subagentId
                    },
                    ruleType: selectRuleType,
                    startDate: startDate, endDate: endDate, contract: selectContract?.value
                }
            }
            else {
                newCommission = { ...commissionJson, [key]: key === 'courses' || key === 'territories'  || key === 'tier' || key === 'bonus' ? formValues  : { ...commissionJson[key], ...formValues }, "instituteId": instituteId, subagentId: subagentId, contract: selectContract?.value   }
            }
            setCommissionJson(newCommission)
            let data = {
                "valuesJson": newCommission
            }
            const handleSuccess = (body: any) => {
                dispatch(financeSubagentDetailsGetRequest(financeId))
                setIsTerritory(false);
                setIsBonus(false);
                setIsTierRule(false);
                setIsPromotional(false);
                setComponents([])
            }
            dispatch(financeSubagentUpdateRequest(financeId, data, handleSuccess, () => { }))

        } else if (key === 'basic' && pathParts.includes('create')) {
            let instituteId = formValues?.instituteId?.value || formValues?.instituteId;
            let subagentId = universityId;
            let newCommission = { ...commissionJson, [key]: {...formValues, 
                instituteId: instituteId, 
                subagentId: subagentId},
                name: name,
                intake: selectRuleType === 'all' ? 'all' : { months: selectedMonths, year: selectedYears }, 
                instituteId: instituteId, 
                subagentId: subagentId,  
                ruleType: selectRuleType, 
                startDate: startDate, endDate: endDate, contract: selectContract?.value 
            }
            setCommissionJson(newCommission)
            let data = {
                "valuesJson": newCommission
            }
            const handleSuccess = (body: any) => {
                dispatch(financeSubagentDetailsGetRequest(body.id))
                navigate(`/finance/${universityId}/${body.id}/edit`)
                setPreFilledKeyValue(null)
               
            }
            const handleError = () => {
            }
            dispatch(financeSubagentCreateRequest(data, handleSuccess, handleError))
        }
    }

    useEffect(() => {
        let options: any = optionData && optionData?.['courses']?.length ? optionData?.['courses'].map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            let labelvalue = valueJson?.['name']
            return { label: labelvalue, value: item?.id, valuesJson: valueJson }
        }) : []
        setCourseOptions(options)
    }, [optionData?.['courses']])


    useEffect(() => {
        setCourseOptions([])
        if(filterId) {
            const controller = new AbortController();
            const signal = controller.signal;

            // Clear timer and cancel request on unmount
            const cleanup = () => {
                controller.abort("New request");
                clearTimeout(timer);
            };

            // Main logic wrapped in a timer
            const timer = setTimeout(() => {
                if(filterId) {
                    let filter: any = {
                        "filters": [
                            {
                                "quick": [
                                    {
                                        "key": 'institutes',
                                        "condition": "IN",
                                        "property": {
                                            "label": "Institutes",
                                            "value": "institutes",
                                            "type": "datasetselect",
                                            "key": "institutes",
                                            "quick": true,
                                            "valueJson": {
                                                "id": filterId,
                                                "key": "institutes",
                                                "data": {
                                                    "url": "",
                                                    "json": "",
                                                    "custom": "",
                                                    "values": [
                                                        {
                                                            "label": "",
                                                            "value": ""
                                                        }
                                                    ],
                                                    "resource": ""
                                                },
                                                "type": "datasetselect",
                                                "input": true,
                                                "label": "Institutes",
                                                "limit": 100,
                                                "model": "institutes",
                                                "rules": {
                                                    "unique": false,
                                                    "maxLimit": {
                                                        "value": 30,
                                                        "restrictmaxLimit": false
                                                    },
                                                    "minLimit": {
                                                        "value": 1,
                                                        "restrictminLimit": false
                                                    },
                                                    "required": false,
                                                    "show_informs": false,
                                                    "restrictalpha": false,
                                                    "restrictspecialcharacter": false
                                                },
                                                "addons": [],
                                                "filter": true,
                                                "hidden": false,
                                                "idPath": "id",
                                                "prefix": "",
                                                "suffix": "",
                                                "unique": false,
                                                "values": [
                                                    {
                                                        "id": 1,
                                                        "label": "",
                                                        "value": "",
                                                        "in_forms": true
                                                    }
                                                ],
                                                "widget": "choicesjs",
                                                "dataSrc": "values",
                                                "dbIndex": false,
                                                "overlay": {
                                                    "top": "",
                                                    "left": "",
                                                    "style": "",
                                                    "width": "",
                                                    "height": ""
                                                },
                                                "tooltip": "",
                                                "disabled": false,
                                                "lazyLoad": true,
                                                "multiple": false,
                                                "redrawOn": "",
                                                "tabindex": "",
                                                "template": "<span>{{ item.label }}</span>",
                                                "validate": {
                                                    "custom": "",
                                                    "unique": false,
                                                    "multiple": false,
                                                    "required": true,
                                                    "customPrivate": false,
                                                    "onlyAvailableItems": false,
                                                    "strictDateValidation": false
                                                },
                                                "autofocus": false,
                                                "createdAt": "2024-09-20T11:46:34Z",
                                                "createdBy": null,
                                                "encrypted": false,
                                                "hideLabel": false,
                                                "indexeddb": {
                                                    "filter": {}
                                                },
                                                "minSearch": 0,
                                                "modalEdit": false,
                                                "protected": false,
                                                "refreshOn": "",
                                                "tableView": true,
                                                "attributes": {},
                                                "errorLabel": "",
                                                "objectType": "courses",
                                                "persistent": true,
                                                "properties": {},
                                                "validateOn": "change",
                                                "clearOnHide": true,
                                                "conditional": {
                                                    "eq": "",
                                                    "show": null,
                                                    "when": null
                                                },
                                                "customClass": "",
                                                "description": "",
                                                "fuseOptions": {
                                                    "include": "score",
                                                    "threshold": 0.3
                                                },
                                                "ignoreCache": false,
                                                "keyModified": true,
                                                "optionLabel": "name",
                                                "placeholder": "Select Institutes",
                                                "quickCreate": true,
                                                "returnValue": "value",
                                                "searchField": "",
                                                "tablecolumn": true,
                                                "authenticate": false,
                                                "defaultValue": null,
                                                "selectFields": "",
                                                "customOptions": {},
                                                "dataGridLabel": false,
                                                "labelPosition": "top",
                                                "readOnlyValue": false,
                                                "searchEnabled": true,
                                                "showCharCount": false,
                                                "showWordCount": false,
                                                "uniqueOptions": false,
                                                "valueProperty": "",
                                                "calculateValue": "",
                                                "clearOnRefresh": false,
                                                "recommendation": true,
                                                "searchDebounce": 0.3,
                                                "useExactSearch": false,
                                                "calculateServer": false,
                                                "permissionsJson": {},
                                                "selectThreshold": 0.3,
                                                "allowMultipleMasks": false,
                                                "customDefaultValue": "",
                                                "allowCalculateOverride": false
                                            }
                                        },
                                        "values": [
                                            filterId
                                        ],
                                        "quick": true
                                    }
                                ],
                                "advance": [],
                                "search" :null
                            }
                        ],
                        "sorts": [
                            {
                                "field": "createdAt",
                                "order": "desc"
                            }
                        ]
                    }
                    dispatch(optionsListGetRequest('courses', filter, 0, 50, {}, signal));
                }
            }, 2000);
        // Cleanup on unmount or effect rerun
        return cleanup;
        }
    }, [filterId])


    const goback = () => {
        if (pathParts.includes('create')) {
            navigate(-1);
        }
        else {
            navigate(-2);
        }
    }
  

    const RenderComponent = () => {
        return component.length ? component.map((item: any) => {
            switch(item) {
                case 'territories' :
                    if(isTerritory) return <Territory handleRemoveComponent={handleRemoveComponent} setOpen={setIsTerritory} onAddRule={model === "subagents" ? onAddSubagentRule : onAddRule} inputs={commissionJson} />
                break
                case 'courses' :
                    if(isPromotional) return <Courses setSearch={setSearch} handleRemoveComponent={handleRemoveComponent} setCourseOptions={setCourseOptions} dynamicFields={dynamicFields} setOpen={setIsPromotional} onAddRule={model === "subagents" ? onAddSubagentRule : onAddRule} courseOptions={courseOptions} inputs={commissionJson} />
                    break
                case 'tier' :
                    if(isTierRule) return <Tier handleRemoveComponent={handleRemoveComponent} levels={[...levels, ...basicdynamicFields]} setOpen={setIsTierRule} onAddRule={model === "subagents" ? onAddSubagentRule : onAddRule} inputs={commissionJson} />
                    break
                case 'bonus' :
                    if(isBonus) return <Bonus handleRemoveComponent={handleRemoveComponent} setOpen={setIsBonus} onAddRule={model === "subagents" ? onAddSubagentRule : onAddRule} inputs={commissionJson} />
        break
            }
        }) : null
    }

    const handleAddComponent = (component: any) => {
        setComponents((prevComponents: any) => [...prevComponents, component]);
    };

    const handleRemoveComponent = (component: any) => {
        setComponents((prevComponents: any) => prevComponents.filter((item: any) => item !== component));
        setCommissionJson({...commissionJson, [component]: null});
        if(model === "subagents")  {
            onAddSubagentRule(null, component)
        }
        else {
            onAddRule(null, component)
        }
    }


    const onChangeRadio = (value: any) => {
        setSelectedRuleType(value)
    }

    const customStyles = {
        // Customize the style of the dropdown menu
        input: (provided: any) => ({
            ...provided,
            color: layoutModeType === 'light'? 'black' : 'white',
          }),
        control: (provided: any, state: any) => ({
                    ...provided,
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }  
    
    useEffect(() => {
        if(selectRuleType === 'all') {
            setBtndisabled(!name || !selectRuleType);
            // || !selectContract?.value
        }
        else {
            setBtndisabled(!name || !selectRuleType || !selectedMonths?.length || !selectedYears?.value)
        }
    }, [name, selectRuleType, selectedMonths, selectedYears])

    console.log("details?.valuesJson?.tier", details?.valuesJson?.tier)

    return (
        <div className=''>
            <ToastContainer />
            <div className="vstack gap-3">
                <div >
                    <div className='vstack gap-2'>
                        <div>
                            <Button color="primary" className="w-auto btn-sm" onClick={() => goback()}>
                                Back
                            </Button>
                        </div>
                        <Label className='fw-bold fs-20 text-primary'>{aboutDetails?.name}</Label>
                    </div>
                    <hr />
                    <Row className='p-2'>
                        <Col xl={6} md={6} lg={6}>
                            <Label className='fw-semibold fs-16 text-primary'>Rule Name<span id={`starred${name}`} className="fw-bold text-danger">{" "}*</span>:</Label>
                            <Input style={{height: 40}} placeholder='Enter name' name='name' value={name} onChange={(e: any) => setName(e.target.value)} />
                        </Col>
                        <Col xl={6} md={6} lg={6}>
                            <Label className='fw-semibold fs-16 text-primary'>Contract:</Label>
                            <Select
                                placeholder="Select..."
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                name="fields"
                                options={contractsOption || []}
                                value={selectContract}
                                onChange={setSelectContract}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <Row  className='p-2'>
                        <Col xl={6} md={6} lg={6} >
                            <Label className='fw-semibold fs-16 text-primary'>Intake<span id={`starred${name}`} className="fw-bold text-danger">{" "}*</span>:</Label>
                            <div className='hstack ps-3 gap-5'>
                                <div className="ps-4" onClick={(e) => onChangeRadio('all')}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        checked={selectRuleType === 'all'}
                                        onChange={(e) => onChangeRadio('all')}
                                    />
                                    <Label className="form-check-label" htmlFor={name}>
                                        All
                                    </Label>
                                </div>
                                <div  className="ps-4" onClick={(e) => onChangeRadio('intakemonth')}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        checked={selectRuleType === 'intakemonth'}
                                        onChange={(e) => onChangeRadio('intakemonth')}
                                    />
                                    <Label className="form-check-label" htmlFor={name}>
                                        Month-wise
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    {
                        selectRuleType === 'intakemonth' ?
                        <div>
                            <Label className='fw-semibold fs-16 text-primary'>Select Intake:</Label>
                            <Row>
                                <Col xl={3} md={3} lg={3}>
                                    <Select
                                        isMulti
                                        placeholder="Select..."
                                        styles={customStyles}
                                        className="basic-single "
                                        style={{height: 35}}
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={months}
                                        closeOnSelect={true}
                                        value={selectedMonths}
                                        onChange={setSelectedMonths}
                                    />
                                </Col>
                                <Col xl={3} md={3} lg={3}>
                                    <Select
                                        placeholder="Select..."
                                        className="basic-single "
                                        style={{height: 35}}
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={years}
                                        value={selectedYears}
                                        onChange={setSelectedYears}
                                    />
                                </Col>
                            </Row>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="live-preview vstack gap-2">
                    <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                        <CardHeader className='hstack bg-primary-subtle justify-content-between'>
                            <Label className='fw-bold fs-20 text-primary'>Basic Rule</Label>
                        </CardHeader>
                        <CardBody>
                            <Common BtnClassName={"w-auto btn-primary"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={commissionMetaData} btnAction={(values: any) => { model === "subagents" ? onAddSubagentRule(values, 'basic') : onAddRule(values, 'basic') }} btn_text={financeId && details?.valuesJson ? 'Update' : 'Add'} disabled={false} theme={"primary"} data={commissionJson?.basic} preFilledKey={preFilledKey} pathParts={pathParts} btndisabled={btndisabled} preFilledKeyValue={preFilledKeyValue} />
                        </CardBody>
                    </Card>
                </div>
                <RenderComponent />
                {
                    details?.valuesJson && pathParts.includes('edit') ?
                        <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                            <CardBody className='hstack gap-2'>
                                {
                                    isTerritory ? null : <Button
                                        onClick={() => {setIsTerritory(true);handleAddComponent('territories')}}
                                        color="primary"
                                        className="btn-label btn-primary">
                                        <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                        {details?.valuesJson?.territories ? 'Update Territory' : 'Add Territory'}
                                    </Button>
                                }
                                {
                                    isTierRule ? null : <Button
                                        onClick={() => {setIsTierRule(true);handleAddComponent('tier')}}
                                        color="primary"
                                        className="btn-label btn-primary">
                                        <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                        {details?.valuesJson?.tier && Object.keys(details?.valuesJson?.tier).some(key => key.startsWith('slab'))? 'Update Tier' : 'Add Tier'}
                                    </Button>
                                }
                                {
                                    isPromotional ? null : <Button
                                        onClick={() => {setIsPromotional(true);handleAddComponent('courses')}}
                                        color="primary"
                                        className="btn-label btn-primary">
                                        <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                        {details?.valuesJson?.courses ? 'Update courses' : 'Add courses'}
                                    </Button>
                                }
                                {
                                    isBonus ? null : <Button
                                        onClick={() => {setIsBonus(true);handleAddComponent('bonus')}}
                                        color="primary"
                                        className="btn-label btn-primary">
                                        <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                        {details?.valuesJson?.bonus ? 'Update Bonus' : 'Add Bonus'}
                                    </Button>
                                }
                            </CardBody>
                        </Card>
                        : null
                }
            </div>
        </div>
    );
};

export default withTranslation()(ComissionCreate);
