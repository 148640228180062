import React, { useEffect, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Input, Row, UncontrolledAccordion } from 'reactstrap';
import conditionsJson from "../../../Components/Common/Filter/conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "../../../Components/Common/SelectTree/SelectTree";
import ConditionalDefaultValue from "./ConditionalDefaultValue";
import { isJson } from "utils";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

const Conditional = ({ setCreateInputs, createInputs, fieldJson, model, setFieldJson, dataFields, onFilterSelected, selectedGroup, filter, setSelectedGroupIndex }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [selectedProperty, setSelectedProperty] = useState<any>({});
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<PropertyProps[]>([]);
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);
    const [displaystatus, setDisplaystatus] = useState<any>({});
    const [isConditional, setIsConditional] = useState<any>(false);

    const handleselected = (option: any, name: string,) => {
        switch (name) {
            case 'show': setDisplaystatus(option)
                setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, show: option.value } })
                setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, show: option.value } })
                break
            case 'condition': setSelectedCondition(option)
                setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, condition: option.value } })
                setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, condition: option.value } })
                break
            case 'value':
                setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, value: option.value } })
                setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, value: option.value } })
                break
        }
    }

    const displayoption = [
        {
            value: true,
            label: 'Show'
        },
        {
            value: false,
            label: 'Hide'
        }
    ]


    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj?.length) {
            if (selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj.map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj.map((option: any) => {
                    const optionsJson = isJson(option.valuesJson);
                    return { label: optionsJson[selectedProperty?.valueJson?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = JSON.parse(node.valuesJson);

        // Create the base transformed node
        const transformedNode: TransformedNode = {
            label: values.name,
            value: node.id,
            createdBy: node.createdBy,
            createdAt: node.createdAt,
            updatedAt: node.updatedAt,
            deletedAt: node.deletedAt,
            parentId: node.parentId,
            parent: node.parent,
        };

        // Recursively transform children if they exist and are not empty
        if (node.children && node.children.length > 0) {
            transformedNode.children = node.children.map(transformTree);
        }

        return transformedNode;
    }

    // Handle property selection
    const onSelectProperty = (property: PropertyProps) => {
        if (property) {
            setSelectedProperty(property);
            const conditionOptions = conditionsJson[property?.valueJson?.type as keyof typeof conditionsJson];
            if (conditionOptions) {
                setConditions(conditionOptions);
                setSelectedCondition(conditionOptions[0]); // Set the first condition by default
                setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional,property: property, when: property?.value, condition: conditionOptions[0].value } })
                setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional,property: property, when: property?.value, condition: conditionOptions[0].value } })
            }
            else {
                setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, property: property, when: property?.value } })
                setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, property: property, when: property?.value } })
            }
            resetValueStates();
        }
    };

    useEffect(() => {
        setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, values: values } })
        setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, values: values } })
    }, [values])

    
    useEffect(() => {        
        setValue(value)   
        setCreateInputs({...createInputs, conditional:{...createInputs.conditional, value: value}})
        setFieldJson({...fieldJson, conditional:{...createInputs.conditional, value: value}})
    }, [value])
    
    useEffect(() => {        
        setValue(highValue)   
        setCreateInputs({...createInputs, conditional:{...createInputs.conditional, highValue: highValue}})
        setFieldJson({...fieldJson, conditional:{...createInputs.conditional, highValue: highValue}})
    }, [highValue])

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        if (selectedProperty?.valueJson?.model) {
            dispatch(getOptions(selectedProperty.valueJson.model, {}));
        }
    };
    // Render input field based on property type
    const renderValueInput = () => {
        switch (selectedProperty?.valueJson?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={options}
                        setValues={(values: any) => setValues(values)}
                        value={values}
                        loading={optionsLoading}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={selectedProperty?.valueJson?.values}
                        setValues={(values: any) => setValues(values)}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={[]}
                        onChange={(newValue: any) => setValues(newValue)}
                        value={values}
                    />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: value ? value : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => setValue(e.target.value)}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        options={options}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                    />
                );
            default:
                return null;
        }
    };

    const onChange = (value: any) => {
        setIsConditional(value)
        setCreateInputs({ ...createInputs, conditional: { ...createInputs.conditional, isConditional: value } })
        setFieldJson({ ...fieldJson, conditional: { ...createInputs.conditional, isConditional: value } })
    }
    return (
        <div>
            <React.Fragment>
                <Row className="hstack gap-3 flex-column justify-content-center">
                    <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">
                        {createInputs.label}
                    </Col>
                </Row>
                <UncontrolledAccordion toggle={() => { }} defaultOpen={["default"]}>
                    <AccordionItem className='border border-1'>
                        <AccordionHeader targetId={'default'} className='hstack justify-content-around'>
                            Display Default Value
                        </AccordionHeader>
                        <AccordionBody accordionId={'default'} className='p-0 vstack gap-2'>
                            <ConditionalDefaultValue
                                setCreateInputs={setCreateInputs}
                                createInputs={createInputs}
                                fieldJson={fieldJson}
                                setFieldJson={setFieldJson}
                                model={model}
                            />
                        </AccordionBody>
                    </AccordionItem>
                    {/* <AccordionItem className='border border-1'>
                        <AccordionHeader targetId={'dispaly'} className='hstack justify-content-around'>
                            Display Field Conditionally
                        </AccordionHeader>
                        <AccordionBody accordionId={'dispaly'} className='p-0 vstack gap-2'>
                            <div className="vstack gap-2 p-3">
                                <div className="hstack align-content-center gap-1 mt-1">
                                    <div className='hstack my-2 justify-content-around'>
                                        <div className="form-check-primary border-primary form-switch align-items-stretch">
                                            <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                                onChange={(e: any) => onChange(e.target.checked)}
                                                checked={isConditional}
                                            />
                                            <div className='fw-bold fs-14 vstack align-self-center'>
                                                <h6>Show this field conditionally.</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isConditional ?
                                        <div className="vstack gap-2">
                                            <div>
                                                <h6>Display Status<span className="fw-bold text-danger">{" "}*</span></h6>
                                                <Select
                                                    defaultOptions
                                                    placeholder={'Select property type'}
                                                    className="fs-16 fw-medium"
                                                    value={displaystatus}
                                                    onChange={(option: any) => handleselected(option, 'show')}
                                                    options={displayoption}
                                                    name="choices-single-default"
                                                ></Select>
                                            </div>
                                            <div>
                                                <DtsDataSelectInput
                                                    placeholder={'Select Field name'}
                                                    onChange={(option: any) => onSelectProperty(option)}
                                                    label={'When'}
                                                    value={selectedProperty}
                                                    rest={{ ...fieldJson, model: createInputs?.objectType }}
                                                    disabled={!model}
                                                />
                                            </div>
                                            <div>
                                                {selectedProperty && (
                                                    <div className="vstack gap-2">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isSearchable
                                                            name="conditions"
                                                            options={conditions}
                                                            onChange={(option: any) => handleselected(option, 'condition')}
                                                            value={selectedCondition}
                                                        />
                                                        <div className="vstack gap-2 mt-2">
                                                            {renderValueInput()}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        : null}
                            </div>
                        </AccordionBody>
                    </AccordionItem> */}
                    {/* <AccordionItem className='border border-1'>
                        <AccordionHeader targetId={'filter'} className='hstack justify-content-around'>
                            Display Conditionally Filtered DropDown Values
                        </AccordionHeader>
                        <AccordionBody accordionId={'filter'} className='p-0 vstack gap-2'>
                            <ConditionalFilter
                                setCreateInputs={setCreateInputs}
                                createInputs={createInputs}
                                fieldJson={fieldJson}
                                setFieldJson={setFieldJson}
                                model={model}
                            />
                        </AccordionBody>
                    </AccordionItem> */}
                </UncontrolledAccordion>
            </React.Fragment>
        </div>
    );
};

export default Conditional;
