import {CallsActionTypes, Calls} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const callGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: CallsActionTypes.GET_CALL_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/calls/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, CallsActionTypes.GET_CALL_SUCCESS, CallsActionTypes.GET_CALL_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const callsMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.GET_CALLMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        headers.append('Properties-Model', model);
        const searchQuery =   createQueryLink(filterObject)  
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, CallsActionTypes.GET_CALLMETADATA_SUCCESS, CallsActionTypes.GET_CALLMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const callUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.PUT_CALL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/calls/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                
        const error = {
            204: {
                "message": "Call updated Successfully",
                "success": true
            },
            200: {
                "message": "Call updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, CallsActionTypes.PUT_CALL_SUCCESS, CallsActionTypes.PUT_CALL_ERROR, error, handleSuccess, handleError);
   };
};

export const callDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.DELETE_CALL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/calls/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Call deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Call deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, CallsActionTypes.DELETE_CALL_SUCCESS, CallsActionTypes.DELETE_CALL_ERROR, error, handleSuccess, handleError);
       } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CallsActionTypes.DELETE_CALL_ERROR
            });
            handleSuccess();
        }
    };
};

export const callListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.GET_CALLS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/calls?page=${page?page:0}&pageSize=${size?size:50}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CallsActionTypes.GET_CALLS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CallsActionTypes.GET_CALLS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const callCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { auth: { token } } = getState();
    dispatch({ type: CallsActionTypes.POST_CALL_REQUEST });
    try {
      const response = await fetch(
        `${DTS_API_STUDENT}/models/calls`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          body: JSON.stringify(data)
        }
      );
           
      const error = {
        208: {
            "message": "Call already exist",
            "success": false
        },
        201: {
            "message": "Call logged Successfully",
            "success": true
        },
        200: {
            "message": "Call logged Successfully",
            "success": true
        }
    }
    await responseHandler(response, dispatch, CallsActionTypes.POST_CALL_SUCCESS, CallsActionTypes.POST_CALL_ERROR, error, handleSuccess, handleError);

    } catch (error) {
      dispatch({ type: CallsActionTypes.POST_CALL_ERROR, payload: error });
      handleError();
    }
  };
};


export const callsDocumentDownload: AppThunk = (query, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/calls?${query}`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response.blob();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR
            });
            handleSuccess();
        }
    };
};

export const callsSentimentJSON: AppThunk = (query, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.CALLS_SENTIMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/calls?${query}`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CallsActionTypes.CALLS_SENTIMENT_ERROR
                });
                handleError && handleError();
            } else {
                dispatch({
                    type: CallsActionTypes.CALLS_SENTIMENT_SUCCESS,
                    payload: body_1
                });
                handleSuccess && handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CallsActionTypes.CALLS_SENTIMENT_ERROR
            });
            handleError && handleError();
        }
    };
};

export const pushNotification: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallsActionTypes.PUSH_NOTIFICATION_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/calls/push-notification`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: CallsActionTypes.PUSH_NOTIFICATION_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CallsActionTypes.PUSH_NOTIFICATION_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};




