import { Reducer } from "redux";
import { FinanceActionTypes, FinanceState } from "./types";
import { isJson } from "utils";


export const initialState: FinanceState =
{
    metaDataFields: [],
    details: null,
    ruledetails: null,
    channelfinances: null,
    universityfinances: null,
    pagination: null,
    loading: false,
    metaLoading: false,
    dataLoading: false,
    commissionFields: [],
    subagentfinances: [],
    rules: [],
}
const reducer: Reducer<FinanceState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case FinanceActionTypes.GET_FINANCEMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case FinanceActionTypes.GET_FINANCEMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case FinanceActionTypes.GET_FINANCEMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }
        case FinanceActionTypes.GET_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case FinanceActionTypes.GET_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }    
        
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }    
        
        case FinanceActionTypes.GET_FINANCE_DETAILS_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_FINANCE_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ruledetails: action.payload
            };
        }
        case FinanceActionTypes.GET_FINANCE_DETAILS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }   
        
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ruledetails: action.payload
            };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }      
        case FinanceActionTypes.POST_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.POST_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case FinanceActionTypes.POST_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case FinanceActionTypes.GET_CHANNEL_FINANCES_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_CHANNEL_FINANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                channelfinances: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_CHANNEL_FINANCES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCES_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                subagentfinances: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_SUBAGENT_FINANCES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                universityfinances: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_UNIVERSITY_FINANCES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_SUCCESS: {
            return {
                ...state,
                loading: false,
                download: action.payload.content
            };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case FinanceActionTypes.PUT_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.PUT_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case FinanceActionTypes.PUT_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case FinanceActionTypes.DELETE_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.DELETE_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case FinanceActionTypes.DELETE_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        
        case FinanceActionTypes.DELETE_SUBAGENT_FINANCE_REQUEST: {
            return { ...state, loading: true };
        }
        case FinanceActionTypes.DELETE_SUBAGENT_FINANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case FinanceActionTypes.DELETE_SUBAGENT_FINANCE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        
        case FinanceActionTypes.SET_COMMISSION_FIELDS_REQUEST: {    
            localStorage.setItem('commissionFields',  JSON.stringify(action.payload))         
            return { ...state, loading: true };
        }  
        case FinanceActionTypes.SET_COMMISSION_FIELDS_SUCCESS: {     
            return { ...state, loading: false};
        }          
        case FinanceActionTypes.GET_COMMISSION_FIELDS_REQUEST: {    
            let commissionFields = localStorage.getItem('commissionFields') 
            return { ...state, loading: true, commissionFields: commissionFields && isJson(commissionFields) ? isJson(commissionFields) : []};
        }  
        case FinanceActionTypes.GET_COMMISSION_FIELDS_SUCCESS: {     
            return { ...state, loading: false};
        } 
        case FinanceActionTypes.GET_FINANACE_RULES_REQUEST: {
            return { ...state, loading: true, rules: [] };
        }
        case FinanceActionTypes.GET_FINANACE_RULES_SUCCESS: {
            return {
                ...state,
                loading: false,
                rules: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case FinanceActionTypes.GET_FINANACE_RULES_ERROR: {
            return { ...state, loading: false, errors: action.payload, rules: [] };
        }
        default: {
            return state;
        }
    }
};

export { reducer as FinanceReducer };
