import React, { useEffect, useState } from "react";
import { MicrosoftLogin } from 'react-microsoft-login';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import config from "../../config";
import * as msal from '@azure/msal-browser';
import { getGoogleCalendarData, postGoogleCalendarSync } from "store/calendarSync/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const { api } = config;
const url =  api.url;

const ConfirmSyncModal = ({ t, confirmModalOutlook, setConfirmModalOutlook }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    
    const [disabled, setDisabled] = useState(false);

    const tog_animationZoom = () => {
        setConfirmModalOutlook(!confirmModalOutlook);
    }

    const handleMicrosoftCalendarSync = (code: any, scope: any) => {
        setDisabled(true)
        const data = {
            code: code, 
            scope: "email profile openid https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Read",
            provider: 'microsoft',
            isSignup: "false"
        }
        const handleSuccess = (body: any): void => {
            setConfirmModalOutlook(false)
            dispatch(getGoogleCalendarData())
            const message = () => toast(`Account Connected Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            setDisabled(false)
        };
        const handleError = (body: any): void => {             
            setDisabled(false)
        };
        dispatch(postGoogleCalendarSync(data, handleSuccess, handleError));
    };

    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/common",
            redirectUri:`${url}`
        }
    };

    const msalInstance: any = new msal.PublicClientApplication(msalConfig);

    async function initializeMsal() {
        await msalInstance.initialize(); // Ensure MSAL is initialized
    }
    
    async function handleMicrosoftLogin() {
        setDisabled(true)
        await initializeMsal();
        const loginRequest = {
            scopes: [
                "User.Read",            // Basic profile information
                "Calendars.ReadWrite",  // Read and write access to the user's calendar
                "Mail.ReadWrite"        // Read and write access to the user's email
            ],
            prompt: 'select_account',
        };
        const response = await msalInstance.loginPopup(loginRequest);
        if (response.idToken) {
            const accessToken = response.idToken;
            handleMicrosoftCalendarSync(accessToken, 'microsoft')
        }
    }    
   
    const getRedirectUri = () => {
        const hostname = window.location.hostname;
        switch (hostname) {
            case 'app.zilter.io':
                return 'https://app.zilter.io';
            case 'qa.zilter.io':
                return 'https://qa.zilter.io';
            default:
                return 'http://localhost:3000'; // Fallback to current URL if none match
        }
    };

    return (
        <>
            <Modal id="flipModal" isOpen={confirmModalOutlook} modalClassName="zoomIn" centered>
                <ModalHeader className="pb-3 bg-info" toggle={() => { tog_animationZoom() }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_microsoft_account")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para1")}
                        </p>
                        <p>
                            <li>
                                <strong>{t("userpreferences.microsoft_calendar")}</strong>
                                <p className="mx-3">{t("userpreferences.confirm_modal_microsoft_para2")}</p>
                            </li>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para3")}
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para4")}
                            <span>
                                <strong>
                                    <Link to="#">{t("userpreferences.here")}</Link>
                                </strong>
                            </span>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para5")}
                            {t("userpreferences.confirm_modal_microsoft_para4")}
                            <span>
                                <strong>
                                    <Link to="#">{t("userpreferences.privacy_policy")}</Link>
                                </strong>
                            </span>
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                    <MicrosoftLogin  
                        clientId={'9dce3457-ea35-4f69-9c96-aecbda169c12'}                                                              
                        authCallback={handleMicrosoftLogin}
                        redirectUri={getRedirectUri()}
                        debug={true}
                        graphScopes={['user.read', 'openid', 'profile', 'Calendars.ReadWrite', 'Mail.ReadWrite']}
                        children={<Button color="success" disabled={disabled} className="btn btn-success">{t("userpreferences.accept_connect_with_microsoft")}</Button>}
                    />                    
                    <Button
                        color="danger"
                        className="btn btn-danger"
                        onClick={() => {
                            setConfirmModalOutlook(false);
                        }}
                    >
                        {t("userpreferences.cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default withTranslation()(ConfirmSyncModal);
