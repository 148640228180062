import React, { useEffect, useRef, useState } from "react";
import { convert } from "html-to-text";
import ReactQuill, { Quill } from "react-quill";
import quillEmoji from 'quill-emoji';
import "quill-emoji/dist/quill-emoji.css"; // Emoji styles
import "quill/dist/quill.snow.css"; // Quill styles
import { Button } from "reactstrap";
import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import moment from "moment";

// Register quill-emoji with Quill
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
'formats/emoji': EmojiBlot,
'modules/emoji-shortname': ShortNameEmoji,
'modules/emoji-toolbar': ToolbarEmoji,
'modules/emoji-textarea': TextAreaEmoji
}, true);

// Initialize socket connection
// const socket = io("http://localhost:5001");
const socket = io("https://api.dts.whatsapp.zilter.io");

// Define a type for MessageAck keys
type MessageAckType = "-1" | "0" | "1" | "2" | "3" | "4";

// Message Acknowledgment Map
const MessageAck: Record<MessageAckType, { icon: string; color: string }> = {
    "-1": { icon: "ri-close-fill", color: "text-danger" },
    "0": { icon: "ri-time-line", color: "text-warning" },
    "1": { icon: "ri-check-line", color: "text-default" },
    "2": { icon: "ri-check-double-line", color: "text-default" },
    "3": { icon: "ri-check-double-line", color: "text-info" },
    "4": { icon: "ri-play-line", color: "text-info" }
};

// Ensure `message.ack` is typed correctly
interface Message {
    ack?: MessageAckType; // Optional since ack may be undefined
    body: string; // Message content
    fromMe: boolean; // Sent by the user
    type: string; // Message type
    timestamp: number; // Unix timestamp
    [key: string]: any; // Additional properties
}

interface ChatbotProps {
    onClose: (state: boolean) => void;
}

const Chatbot: React.FC<ChatbotProps> = ({ onClose }) => {
    // State and refs
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const studentDetails = useSelector((state: ApplicationState) => state.student.details);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [qr, setQr] = useState<string>("");
    const [loadingState, setLoadingState] = useState<string>("INIT");
    const [student, setStudent] = useState<any>(null);
    const [message, setMessage] = useState<string>("");
    const [messages, setMessages] = useState<Message[]>([]);
    const [contactId, setContactId] = useState<string | null>(null);
    const [ready, setReady] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [waError, setWAError] = useState<boolean>(false);
    const [waAuthError, setWAAuthError] = useState<boolean>(false);
    const [sameNumberError, setSameNumberError] = useState<boolean>(false);

    // Effect to handle socket events based on user and student information
    useEffect(() => {
        if (user?.id && (student?.whatsapp_number || student?.mobile)) {
            if (user?.phoneNumber === (student?.whatsapp_number || student?.mobile)) {
                setSameNumberError(true);
            } else {
                socket.emit("create_session", { id: user.id });
                socket.on("qr", ({ qr }: { qr: string }) => {
                    if (qr) {
                        setQr(qr);
                        setLoadingState("INIT");
                    }
                });
                socket.on("auth_failure", () => setWAAuthError(true));
                socket.on("authenticated", () => setLoadingState("LOAD_MSG"));
                socket.on("loading_screen", () => setLoadingState("AUTH"));
                socket.on("ready", ({ client }: { client: any }) => {
                    const number = user?.phoneNumber?.replace(/\D/g, "");
                    const studentNumber = student.whatsapp_number
                        ? student.whatsapp_number.replace(/\D/g, "")
                        : student.mobile?.replace(/\D/g, "");
    
                    if (number === client?.me?.user) {
                        setQr("");
                        socket.emit("get_chats", { id: user.id, number: studentNumber });
                    } else {
                        setQr("");
                        setError(true);
                        socket.emit("destroy_client", { id: user.id });
                    }
                });
                socket.on("wa_not_registered", () => setWAError(true));
                // socket.on("message_create", ({ message }: { message: any }) => updateMessages(message));
                socket.on("message_ack", ({ message }: { message: any }) => messageACK(message));
                socket.on("all_chats", ({ messages, contactId }: { messages: any[]; contactId: string }) => {
                    setReady(true);
                    setMessages((prevMessages) => [...messages]);
                    setContactId(contactId);
                });
    
                // Cleanup listeners on unmount
                return () => {
                    socket.off("qr");
                    socket.off("ready");
                    socket.off("message_create");
                    socket.off("create_session");
                    socket.off("all_chats");
                    socket.off("message_ack");
                    socket.off("wa_not_registered");
                    socket.off("loading_screen");
                    socket.off("authenticated");
                    socket.off("auth_failure");
                };
            }
        }
    }, [user?.id, student?.whatsapp_number, student?.mobile]);

    // Effect to update student details from Redux store
    useEffect(() => {
        if (studentDetails?.valuesJson) {
            setStudent(studentDetails.valuesJson);
        }
    }, [studentDetails]);

    // Scroll to bottom whenever messages update
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Function to update messages in state
    // const updateMessages = (message: any) => {
    //     const number = student.whatsapp_number
    //         ? student.whatsapp_number.replace(/\D/g, "")
    //         : student.mobile?.replace(/\D/g, "");
    //     const contactId = number.includes("@c.us") ? number : `${number}@c.us`;
    //     console.log("updateMessages message>>>", message);

    //     if (
    //         (("from" in message && message.from === contactId) ||
    //             ("to" in message && message.to === contactId)) &&
    //         !message.isStatus
    //     ) {
    //         // setMessages((prevMessages) => [...prevMessages, message]);
    //     }
    // };

    // Function to handle message acknowledgment updates
    const messageACK = (message: any) => {
        const number = student.whatsapp_number
            ? student.whatsapp_number.replace(/\D/g, "")
            : student.mobile?.replace(/\D/g, "");
        const contactId = number.includes("@c.us") ? number : `${number}@c.us`;

        if (
            (("from" in message && message.from === contactId) ||
                ("to" in message && message.to === contactId)) &&
            !message.isStatus
        ) {
            socket.emit("get_chats", { id: user.id, number });
        }
    };

    const convertHtmlToWhatsApp = (html: string): string => {
        if (!html) return "";
    
        return html
            .replace(/&nbsp;/g, " ") // Replace non-breaking spaces with regular spaces
            .replace(/<strong>(.*?)<\/strong>/g, "*$1*") // Convert bold (<strong>) to WhatsApp bold (*text*)
            .replace(/<b>(.*?)<\/b>/g, "*$1*") // Convert bold (<b>) to WhatsApp bold (*text*)
            .replace(/<em>(.*?)<\/em>/g, "_$1_") // Convert italic (<em>) to WhatsApp italic (_text_)
            .replace(/<i>(.*?)<\/i>/g, "_$1_") // Convert italic (<i>) to WhatsApp italic (_text_)
            .replace(/<u>(.*?)<\/u>/g, "_$1_") // Convert underline (<u>) to WhatsApp italic (_text_)
            .replace(/<s>(.*?)<\/s>/g, "~$1~") // Convert strikethrough (<s>) to WhatsApp strikethrough (~text~)
            .replace(/<del>(.*?)<\/del>/g, "~$1~") // Convert strikethrough (<del>) to WhatsApp strikethrough (~text~)
            .replace(/<br\s*\/?>/g, "\n") // Convert line breaks (<br>) to WhatsApp newlines
            .replace(/<\/p>/g, "\n") // Convert paragraph closing tags to newlines
            .replace(/<p>/g, "") // Remove paragraph opening tags
            .replace(/<ul>/g, "") // Remove unordered list opening tags
            .replace(/<\/ul>/g, "") // Remove unordered list closing tags
            .replace(/<li>(.*?)<\/li>/g, "- $1\n") // Convert list items (<li>) to WhatsApp bullet points
            .replace(/<\/?span[^>]*>/g, "") // Remove span tags (used for inline content)
            .replace(/<[^>]*>/g, "") // Remove any remaining HTML tags
            .replace(/\s{2,}/g, " ") // Replace multiple spaces with a single space
            .trim(); // Remove extra whitespace
    };

    // Updated onSend function
    const onSend = () => {
        if (message.trim()) {

            // Convert HTML to WhatsApp-compatible plain text
            const formattedMessage = convertHtmlToWhatsApp(message);

            if(formattedMessage) {
                // Send the formatted plain text message to WhatsApp
                socket.emit("send_message", { id: user.id, message: formattedMessage, contactId });
    
                // Update the local chat with the formatted message
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        ack: "0", // Default acknowledgment state
                        body: formattedMessage, // Store the converted plain text
                        fromMe: true,
                        type: "chat",
                        timestamp: moment().unix(),
                    },
                ]);
    
                // Clear the editor content
                setMessage("");
            }
        }
    };


    // Function to regenerate QR code on error
    const onRegenerate = () => {
        setError(false);
        socket.emit("create_session", { id: user.id });
    };

    // Render error states
    const renderErrorState = () => {
        if (waAuthError) {
            return (
                <div className="chatboat-error">
                    <i className="ri-error-warning-fill fs-36 text-danger"></i>
                    <h3>Authentication Failed!</h3>
                    <p>The scanned number could not be authenticated. Please try again.</p>
                    <Button onClick={onRegenerate} color="primary" className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Regenerate QR
                    </Button>
                </div>
            );
        }

        if (waError) {
            return (
                <div className="chatboat-error">
                    <i className="ri-error-warning-fill fs-36 text-danger"></i>
                    <h3>Number Not Registered!</h3>
                    <p>The student's number is not linked to WhatsApp. Please verify the number and try again.</p>
                </div>
            );
        }

        if (error) {
            return (
                <div className="chatboat-error">
                    <i className="ri-error-warning-fill fs-36 text-danger"></i>
                    <h3>Number Mismatch!</h3>
                    <p>The scanned number does not match the account. Please use a registered number.</p>
                    <Button onClick={onRegenerate} color="primary" className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Regenerate QR
                    </Button>
                </div>
            );
        }

        if (sameNumberError) {
            return (
                <div className="chatboat-error">
                    <i className="ri-error-warning-fill fs-36 text-danger"></i>
                    <h3>Invalid number!</h3>
                    <p>You cannot start a chat with your own number.</p>
                </div>
            );
        }

        return null;
    };
    
    // convert whatsapp style message to html
    const convertWhatsAppToHtml = (text: string): string => {
        if (!text) return "";
    
        return text
            .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Convert *bold* to <strong>
            .replace(/_(.*?)_/g, "<em>$1</em>") // Convert _italic_ to <em>
            .replace(/~(.*?)~/g, "<s>$1</s>") // Convert ~strikethrough~ to <s>
            .replace(/```(.*?)```/g, "<pre>$1</pre>") // Convert ```monospace``` to <pre>
            .replace(/^- (.*?)(?=\n|$)/gm, "<li>$1</li>") // Convert - bullet points to <li>
            .replace(/(?:^|\n)(<li>.*?<\/li>)(?:\n|$)/g, "<ul>$1</ul>") // Wrap <li> elements in <ul>
            .replace(/\n/g, "<br>"); // Convert newlines to <br>
    };
    

    // Render the main chat view
    const renderChatView = () => {
        if (ready) {
            return (
                <div className="direct-chat-messages d-flex flex-column gap-3">
                    {messages.map((message, index) => {
                        // Safely retrieve the ack value
                        const ack = message.ack ? MessageAck[message.ack] : undefined;
                        const ackIcon = ack ? <i className={`${ack?.icon} ${ack?.color} fs-16`} /> : <i className={`ri-time-line text-warning fs-16`} />;

                        if (message?.fromMe && message?.type === "chat") {
                            return (
                                <div key={index} className="direct-chat-msg right">
                                    <div className="direct-chat-info clearfix">
                                        <span className="direct-chat-name pull-right">{user?.name}</span>
                                        <span className="direct-chat-timestamp pull-left">
                                            {moment.unix(message?.timestamp).format("MMM Do, h:mm a")} {ackIcon}
                                        </span>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div
                                            className="w-100 shadow bg-light p-2 rounded-3 text-wrap"
                                            dangerouslySetInnerHTML={{ __html: convertWhatsAppToHtml(message?.body) }}
                                        ></div>
                                        <i className="ri-user-2-fill fs-1 text-muted" />
                                    </div>
                                </div>
                            );
                        }

                        if (!message?.fromMe && message?.type === "chat") {
                            return (
                                <div key={index} className="direct-chat-msg">
                                    <div className="direct-chat-info clearfix">
                                        <span className="direct-chat-name pull-left">{student?.name}</span>
                                        <span className="direct-chat-timestamp pull-right">
                                            {moment.unix(message?.timestamp).format("MMM Do, h:mm a")}
                                        </span>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <i className="ri-user-3-fill fs-1 text-muted" />
                                        <div
                                            className="w-100 shadow bg-light p-2 rounded-3 text-wrap"
                                            dangerouslySetInnerHTML={{ __html: convertWhatsAppToHtml(message?.body) }}
                                        ></div>
                                    </div>
                                </div>
                            );
                        }

                        return null;
                    })}
                    <div ref={messagesEndRef}></div>
                </div>
            );
        }

        if (qr) {
            return (
                <div className="d-flex flex-column align-items-center p-3 justify-content-center">
                    <h3 className="text-center">Scan QR Code</h3>
                    <p>Use your phone's WhatsApp application to scan the QR code below.</p>
                    <QRCode fgColor="#3b4a54" value={qr} />
                </div>
            );
        }

        return (
            <div className="chatboat-loader d-flex flex-column">
                <i className="ri-whatsapp-line display-1 shimmer"></i>
                <label>{getStatus(loadingState)}</label>
            </div>
        );
    };

    // Get the status text based on the current loading state
    const getStatus = (state: string) => {
        const statusMap: Record<string, string> = {
            LOAD_MSG: "Loading messages..",
            AUTH: "Authenticating...",
            INIT: "Initializing..."
        };
        return statusMap[state] || "Initializing...";
    };

    // Main return for the component
    return (
        <div className="dts-chatbot">
            <div className="row container d-flex justify-content-center">
                <div className="col-md-12">
                    <div className="box border-top-1 border-primary direct-chat direct-chat-warning position-relative border-radius-1 shadow-lg bg-dark-subtle">
                        <div className="box-header d-flex align-items-center justify-content-between shadow">
                            <h3 className="box-title">WhatsApp Messages</h3>
                            <Button color="light" className="btn-icon btn-ghost-dark" onClick={() => onClose(false)}>
                                <i className="ri-close-fill" />
                            </Button>
                        </div>
                        <div className="box-body">
                            {renderErrorState() || renderChatView()}
                        </div>
                        {ready && !(error || waError) ? (
                            <div className="box-footer d-flex flex-column gap-2">
                                <div>
                                    {/* <div ref={quillRef} /> */}
                                    <ReactQuill
                                        placeholder="Compose a message..."
                                        theme="snow"
                                        value={message}
                                        onChange={setMessage}
                                        modules={{
                                            toolbar: [
                                                ["bold", "italic", "strike"], // WhatsApp-supported text formatting
                                                [{ list: "bullet" }], // Bullet list
                                                ["emoji"], // Emoji picker
                                            ],
                                            "emoji-toolbar": true,
                                            "emoji-textarea": false,
                                            "emoji-shortname": true,
                                        }}
                                        formats={["bold", "italic", "strike", "list", "bullet", "emoji"]}
                                    />
                                </div>
                                <Button
                                    size="sm"
                                    disabled={!message.trim() || error || waError || !!qr}
                                    color="primary"
                                    className="btn-label right"
                                    onClick={onSend}
                                >
                                        <i className="ri-send-plane-fill label-icon align-middle fs-16 ms-2"></i> Send
                                </Button>
                                {/* <Button
                                    disabled={!message.trim() || error || waError || !!qr}
                                    color="primary"
                                    className="btn-label right"
                                    onClick={onSend}
                                >
                                    <i className="ri-send-plane-fill" /> Send
                                </Button> */}
                            </div>
                        ) : (
                            <div className="box-footer p-4"></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chatbot;
