export interface coursesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface Course {
    id: string,
    valuesJson: string,
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum CourseActionTypes {
    GET_COURSEMETADATA_REQUEST = "@@course/GET_COURSEMETADATA_REQUEST",
    GET_COURSEMETADATA_SUCCESS = "@@course/GET_COURSEMETADATA_SUCCESS",
    GET_COURSEMETADATA_ERROR = "@@course/GET_COURSEMETADATA_ERROR",
    GET_COURSE_REQUEST = "@@course/GET_COURSE_REQUEST",
    GET_COURSE_SUCCESS = "@@course/GET_COURSE_SUCCESS",
    GET_COURSE_ERROR = "@@course/GET_COURSE_ERROR",
    GET_COURSES_REQUEST = "@@course/GET_COURSES_REQUEST",
    GET_COURSES_SUCCESS = "@@course/GET_COURSES_SUCCESS",
    GET_COURSES_ERROR = "@@course/GET_COURSES_ERROR",
    GET_FILTER_COURSES_REQUEST = "@@course/GET_FILTER_COURSES_REQUEST",
    GET_FILTER_COURSES_SUCCESS = "@@course/GET_FILTER_COURSES_SUCCESS",
    GET_FILTER_COURSES_ERROR = "@@course/GET_FILTER_COURSES_ERROR",
    PUT_COURSE_REQUEST = "@@course/PUT_COURSE_REQUEST",
    PUT_COURSE_SUCCESS = "@@course/PUT_COURSE_SUCCESS",
    PUT_COURSE_ERROR = "@@course/PUT_COURSE_ERROR",
    DELETE_COURSE_REQUEST = "@@course/DELETE_COURSE_REQUEST",
    DELETE_COURSE_SUCCESS = "@@course/DELETE_COURSE_SUCCESS",
    DELETE_COURSE_ERROR = "@@course/DELETE_COURSE_ERROR",
    POST_COURSE_REQUEST = "@@course/POST_COURSE_REQUEST",
    POST_COURSE_SUCCESS = "@@course/POST_COURSE_SUCCESS",
    POST_COURSE_ERROR = "@@course/POST_COURSE_ERROR",
    GET_OPTIONS_REQUEST = "@@course/GET_OPTIONS_REQUEST",
    GET_OPTIONS_SUCCESS = "@@course/GET_OPTIONS_SUCCESS",
    GET_OPTIONS_ERROR = "@@course/GET_OPTIONS_ERROR",
}

export interface CourseState {
    readonly list: Course[] | null;
    readonly details: Course | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: coursesMetadata[];
    readonly loading: any;
    readonly dataLoading: any;
    readonly optionData: any;
    readonly optionsLoading: boolean;
}
