import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { handleSelectValue } from "utils";


const StatusFormModel = ({ form, onToggle, onUpdate, details }: any) => {
    const [formValues, setFormValues] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        setData(details)
    }, [details])

    const onChange = (option: any, key: any, field: any) => {
        if (isNaN(Date.parse(option)) === false) {
            setFormValues({ ...formValues, [key]: option })
        }
        else if (field.type == 'selectboxes' || field.isMulti) {
            setFormValues({ ...formValues, [key]: option })
        }
        else if (typeof option == 'object') {
            setFormValues({ ...formValues, [key]: option.value })
        } else setFormValues({ ...formValues, [key]: option })
    };

    const allMandatoryFieldsFilled = () => {
        for (const field of form?.fields) {
            if (field && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };
    const formdisabled = Object.keys(formerror).length === 0;

    useEffect(() => {
        let newData: any = {};
        if (data) {
            let info = data?.valuesJson || data;
            form?.fields && form?.fields.length && form?.fields.map((item: any, key: any) => {
                let originalFee = parseFloat(info?.['course_fee'])
                let originalCurrency = (info?.['currency']?.toUpperCase().match(/[A-Z]+/g)?.[0])
                let rendervalue = info?.[item.key];
                if (item?.mergeColumns?.columns) {
                    let value = item?.mergeColumns?.columns
                    let values = value.split(",")
                    let newValue = "";
                    values?.length && values.map((item: any) => {
                        newValue = info?.[item] ? `${newValue ? newValue : ""} ${info?.[item]}${item.mergeColumns?.separator || " "}` : ""
                    })
                    rendervalue = newValue;
                }
                if (item.optionLabel) {
                    rendervalue = info?.[item.key]?.[item.optionLabel] ? { label: info?.[item.key]?.[item.optionLabel], value: info?.[item.key]?.["id"] } : null
                }
                else if (item.isMulti || item.type == "selectboxes") {
                    rendervalue = info?.[item.key];
                }
                else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
                    rendervalue = handleSelectValue(info?.[item.key], item.values);
                }
                else if (item.type == 'file') {
                    rendervalue = typeof info?.[item.key] !== 'object' ? info?.[item.key] : '';
                }
                newData[item.key] = rendervalue;
            })
            setFormValues(newData)
            setFieldValues(newData)
        }
    }, [data, form?.fields])

    return (
        <Modal backdrop="static" isOpen={!!form} toggle={onToggle} centered={true}>
            <ModalHeader className="bg-light p-3" toggle={onToggle}>
                Add Details
            </ModalHeader>
            <ModalBody className='text-left p-5'>
                {form?.fields?.length ? form?.fields.map((field: any) => {
                    return <FormBuilder
                        placeholder={field.placeholder}
                        name={field.key}
                        onChange={(e: any) => onChange(e, field.key, field)}
                        options={field.values && field.values.reverse() || []}
                        label={field.label}
                        type={field.type}
                        value={formValues[field.key]}
                        isEditState={true}
                        actionButtons={false}
                        defaultValue={formValues[field.key]}
                        Editable={true}
                        rest={field}
                        invalid={true}
                        formValues={formValues}
                        dataFields={form?.fields}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                    />
                }) : null}
                {/* <QuickForm dataFields={form?.fields} btnAction={onUpdate} btn_text={'Save'} /> */}
            </ModalBody>
            <div className="modal-footer">
                <div className="hstack gap-2 justify-content-end">
                    <Button color="danger" onClick={onToggle}>Cancel</Button>
                    <Button
                        color="primary"
                        disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled}
                        onClick={() => onUpdate(formValues, form)}
                    >
                        Update Status
                    </Button>
                </div>
            </div>
        </Modal>) as unknown as JSX.Element;
};

export default StatusFormModel;