export interface WhatsappMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Whatsapp {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export interface Sentiment {
  sentiment: string
} 

export enum WhatsappActionTypes {
  GET_WHATSAPPMETADATA_REQUEST = "@@whatsapp/GET_WHATSAPPMETADATA_REQUEST",
  GET_WHATSAPPMETADATA_SUCCESS = "@@whatsapp/GET_WHATSAPPMETADATA_SUCCESS",
  GET_WHATSAPPMETADATA_ERROR = "@@whatsapp/GET_WHATSAPPMETADATA_ERROR",
  GET_WHATSAPP_REQUEST = "@@whatsapp/GET_WHATSAPP_REQUEST",
  GET_WHATSAPP_SUCCESS = "@@whatsapp/GET_WHATSAPP_SUCCESS",
  GET_WHATSAPP_ERROR = "@@whatsapp/GET_WHATSAPP_ERROR",
  GET_WHATSAPPS_REQUEST = "@@whatsapp/GET_WHATSAPPS_REQUEST",
  GET_WHATSAPPS_SUCCESS = "@@whatsapp/GET_WHATSAPPS_SUCCESS",
  GET_WHATSAPPS_ERROR = "@@whatsapp/GET_WHATSAPPS_ERROR",
  PUT_WHATSAPP_REQUEST = "@@whatsapp/PUT_WHATSAPP_REQUEST",
  PUT_WHATSAPP_SUCCESS = "@@whatsapp/PUT_WHATSAPP_SUCCESS",
  PUT_WHATSAPP_ERROR = "@@whatsapp/PUT_WHATSAPP_ERROR",
  DELETE_WHATSAPP_REQUEST = "@@whatsapp/DELETE_WHATSAPP_REQUEST",
  DELETE_WHATSAPP_SUCCESS = "@@whatsapp/DELETE_WHATSAPP_SUCCESS",
  DELETE_WHATSAPP_ERROR = "@@whatsapp/DELETE_WHATSAPP_ERROR",
  POST_WHATSAPP_REQUEST = "@@whatsapp/POST_WHATSAPP_REQUEST",
  POST_WHATSAPP_SUCCESS = "@@whatsapp/POST_WHATSAPP_SUCCESS",
  POST_WHATSAPP_ERROR = "@@whatsapp/POST_WHATSAPP_ERROR",  
  WHATSAPP_DOWNLOAD_DOCUMENT_REQUEST = "@@/whatsapp/WHATSAPP_DOWNLOAD_DOCUMENT_REQUEST",
  WHATSAPP_DOWNLOAD_DOCUMENT_SUCCESS = "@@/whatsapp/WHATSAPP_DOWNLOAD_DOCUMENT_SUCCESS",
  WHATSAPP_DOWNLOAD_DOCUMENT_ERROR = "@@/whatsapp/WHATSAPP_DOWNLOAD_DOCUMENT_ERROR",
  WHATSAPP_SENTIMENT_REQUEST = "@@/whatsapp/WHATSAPP_SENTIMENT_REQUEST",
  WHATSAPP_SENTIMENT_SUCCESS = "@@/whatsapp/WHATSAPP_SENTIMENT_SUCCESS",
  WHATSAPP_SENTIMENT_ERROR = "@@/whatsapp/WHATSAPP_SENTIMENT_ERROR",
  PUSH_NOTIFICATION_REQUEST = "@@whatsapp/PUSH_NOTIFICATION_REQUEST",
  PUSH_NOTIFICATION_SUCCESS = "@@whatsapp/PUSH_NOTIFICATION_SUCCESS",
  PUSH_NOTIFICATION_ERROR = "@@whatsapp/PUSH_NOTIFICATION_ERROR"
}

export interface WhatsappState {
  readonly list: Whatsapp[] | null;
  readonly details: Whatsapp | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: WhatsappMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;  
  readonly sentiment: Sentiment | null;
  readonly whatsapploading: boolean
}
