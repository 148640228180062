import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import InvoicesListing from './Invoices';

const Invoices = () => {
	document.title = "DTS | Zilter - Channel Invoices";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title="Invoices" pageTitle="Invoices"   icon={"ri-contacts-book-line"}  />
                    <Row>
                        <InvoicesListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Invoices;