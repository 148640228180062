import React, { useEffect, useState, useCallback} from 'react';
import { withTranslation } from 'react-i18next';
import TableComponent from './PaymentStruture';
import Flatpickr from "react-flatpickr";
import { startCase } from 'lodash';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import { Input } from 'reactstrap';
import { isJson } from 'utils';

const DynamicTable = (props: any) => {
    const { period, setTableData,  tableData, setdisabled } = props;
    const [paymentCount, setPaymentCount] = useState<number>(3)
    const [rowCount, setRowCount] = useState<any>(tableData?.length || 3)
    const [columns, setColumns] = useState<any>([])
    const [isData, setIsData] = useState<any>(false)
    const [updatedRow, setUpdatedRow] = useState<any>(false)

    const onhandleChange = (value: any, key: any, id: any) => {   
        let newData = tableData;
        let index = tableData?.length ? tableData.findIndex((item: any) => item.id === id) : -1
        if(index > -1) { 
            newData[index] = {...newData[index], [key]: value}  
            const paymentKeys = Object.keys(newData[index]).filter(key => key.startsWith('payment') && key !== 'paymentCount');
            const paymentValues = paymentKeys.map((key: any) => {
                if(newData[index][key] && isJson(newData[index][key])) {
                    return parseFloat(JSON.parse(newData[index][key]).value)
                }                    
            });
            const totalPayment = paymentValues.reduce((acc: any, value: any) => acc + parseFloat(value?value:0), 0);      
            newData[index] = {...newData[index], totalPayment: totalPayment}
            setTableData(newData)
            setUpdatedRow(newData[index])
            let isDisabled = newData.some((item: any) => item.totalPayment > 100)
            setdisabled(isDisabled)
        }
    }
    
    useEffect(() => {
        let newData: any = tableData;
        [...Array(rowCount)].map((_, index) => {
            if (index > tableData.length - 1 && rowCount > tableData.length) {
                let periodValue = startCase(period) + ` ${index + 1}`
                let obj: any = { ...tableData[index], id: index + 1, [`${period}`]: periodValue, [`${period}ly`]: index === 0 ? 12 : tableData[index]?.[`${period}ly`] };
                [...Array(paymentCount)].map((_, i) => {
                    obj = { ...obj,paymentCount: tableData[index]?.paymentCount ? tableData[index]?.paymentCount : 3 ,  [`payment${i + 1}`]: tableData[index]?.[`payment${i + 1}`], [`dueDateDays${i + 1}`]: tableData[index]?.[`dueDateDays${i + 1}`] };
                })
                newData.push(obj)
            }
            else if (rowCount < newData.length) {
                newData.pop();
            }
        })
        setTableData(newData)
        setRowCount(newData.length)   
    }, [period, tableData])

   


    const onChangePaymentCount = (count: any, i: any, action: string) => {
        if(action === 'add') {
            let newData = tableData;
            newData[i] = {...newData[i], paymentCount: count+1}
            setTableData(newData)
            setUpdatedRow(newData[i])
        }
        else {
            let newData = tableData;
            newData[i] = {...newData[i], paymentCount: count-1}
            const paymentKeys = Object.keys(newData[i]).filter(key => key.startsWith('payment') && key !== 'paymentCount');
            const dateKeys = Object.keys(newData[i]).filter(key => key.startsWith('dueDateDays') && key !== 'paymentCount');
            const paymentValues = paymentKeys.map((key: any, index: any) => {
                if(newData[i][key] && isJson(newData[i][key])) {
                    if(paymentKeys.length-1 === index) return 0
                    return parseFloat(JSON.parse(newData[i][key]).value)
                }                    
            });
            const totalPayment = paymentValues.reduce((acc: any, value: any) => acc + parseFloat(value?value:0), 0); 
            newData[i] = {...newData[i], totalPayment: totalPayment, [paymentKeys[paymentKeys.length-1]] : null, [dateKeys[dateKeys.length-1]] : null}
            setTableData(newData)
            setUpdatedRow(newData[i])
        }
    }

    const onRowAction = (action: any) => {
        if(action === 'add') {
            let newData: any = tableData;
            let index = tableData.length;
            let periodValue = startCase(period) + ` ${index + 1}`
            let obj: any = { id: index + 1, [`${period}`]: periodValue, [`${period}ly`]: index === 0 ? 12 : tableData[index]?.[`${period}ly`] };
            [...Array(3)].map((_, i) => {       
                obj = { ...obj, paymentCount: 0 ? tableData[index]?.paymentCount : 3 ,  [`payment${i + 1}`]: null, [`dueDateDays${i + 1}`]: null };
          
            })            
            newData.push(obj)
            setUpdatedRow(obj)
            setTableData(newData)
            setRowCount(rowCount + 1)

        }
        else if(action === 'remove') {
            let newTableData = tableData;
            setTableData(newTableData.slice(0, -1))
            setRowCount(rowCount - 1)
        }
    }

    return (
        <React.Fragment>
            <div>
                <TableComponent onRowAction={onRowAction} onhandleChange={(value: any, key: any, id: any) => onhandleChange(value, key, id)} period={period} columns={columns} paymentCount={paymentCount} setPaymentCount={setPaymentCount} data={tableData} tableData={tableData} setRowCount={setRowCount} rowCount={rowCount} onChangePaymentCount={onChangePaymentCount} updatedRow={updatedRow} setdisabled={setdisabled} />
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(DynamicTable);
