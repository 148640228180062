import React, { useState } from "react";
import {
    Breadcrumb,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import UsersTable from "./UsersTable";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

const UsersList = () => {
    const [customActiveTab, setcustomActiveTab] = useState<any>("1");
    const [show, setShow] = useState<boolean>(false); 

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <>
            <Breadcrumb title="Users" pageTitle="Users" />
            {show ? <ToastContainer /> : null}
            <Card>
                <CardBody className="">
                    <Nav
                        tabs
                        className="nav nav-tabs nav-tabs-custom nav-primary fs-18 mb-3"
                    >
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                Users
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={customActiveTab}>
                        <TabPane tabId="1" id="home1">
                            <div>
                                <div className="fs-15 fw-medium mb-3">
                                    Create new users, customize user permissions, and remove users
                                    from your account.{" "}
                                </div>
                            </div>
                        </TabPane>
                        <UsersTable />
                    </TabContent>
                </CardBody>
            </Card>
        </>
    );
};
export default UsersList;
