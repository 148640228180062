import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Label } from "reactstrap";
import conditionsJson from "../Common/Config/MainPanel/ControlPanel/Filter/conditionsJson.json";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { reportGenerateRequest } from "store/report/action";
import { GeneratedReport } from "store/report/types";

interface ReportProps {
    config: any;
    onDetailsReport: (data: any) => void;
}
interface Group {
    currentPeriodCount: number;
    previousPeriodCount: number;
    label: string;
}

const KPIReport: React.FC<ReportProps> = ({ config, onDetailsReport }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [report, setReport] = useState<GeneratedReport | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    
    useEffect(() => {
        if(config) {
            generateReport();
        }
    }, [config])

    const generateReport = () => {
        setLoading(true);
        setError(false);
        const handleSuccess = (response: GeneratedReport) => {
            setLoading(false);
            setError(false);
            setReport(response);
        }
        const handleError = () => {
            setLoading(false);
            setError(true);
        }
        dispatch(reportGenerateRequest(config, handleSuccess, handleError))
    }

    const compareBy: any = config?.compare_by;
    const groupBy: any = config?.group_by;
    const value: any = config?.value;
    const items: Group[] = []
    const { data } = report || {};

    const getGroupByLabel = (row: any, value: any, groupBy: any) => {
        if(groupBy) {
            return groupBy?.data_source?.primary ? row[groupBy.key] : row[groupBy.data_source.value][groupBy.key]
        } else {
            return value?.label || "Total Count"
        }
    }
    const getCompareByValue = (compareBy: any, row: any) => {
        if(compareBy) {
            return compareBy?.data_source?.primary ? row[compareBy.key] : row[compareBy.data_source.value][compareBy.key]
        } else {
            return null
        }
    }
    const getValueValue = (value: any, row: any) => {
        return value?.data_source?.primary ? row[value.key] : row[value.data_source.value][value.key]
    }
    data?.forEach((row: any) => {
        const groupByLabel = getGroupByLabel(row, value, groupBy) || "Other";
        if (!items.find((item: Group) => item.label === groupByLabel)) {
            items.push({ currentPeriodCount: 0, previousPeriodCount: 0, label: groupByLabel });
        }
        const currentItem = items.find((item: Group) => item.label === groupByLabel)
        if(currentItem) {
            const compareByValue = getCompareByValue(compareBy, row);
            const selectedValue = getValueValue(value, row);
            if(compareByValue) {
                const { previous, current } = compareBy?.period || {};
                if(compareByValue === previous) {
                    currentItem.previousPeriodCount += selectedValue;
                } else if(compareByValue === current) {
                    currentItem.currentPeriodCount += selectedValue;
                }
            } else {
                currentItem.currentPeriodCount += selectedValue;
            }
        }
    })
    const onChartClick = (params: any) => {
        const groupBy = config?.group_by;
        if(groupBy) {
            const { key, label: keyLabel, data_source, json_field } = groupBy;
            const conditionOptions = conditionsJson[groupBy.type as keyof typeof conditionsJson];
            const { value: condition, label: conditionLabel } = groupBy.type === 'textfield' ? conditionOptions[0] : conditionOptions[0];
            const filter = {
                key,
                keyLabel,
                condition,
                conditionLabel,
                values: [params?.label],
                valuesLabel: [{label: params?.label, value: params?.label}],
                property: groupBy,
                json_field,
                data_source,
                detail_field: true
            };
            onDetailsReport(filter);
        } else {
            onDetailsReport({all: true});
        }
    };
    
    // Function to render individual KPI Cards for each group
    const renderKPICard = (group: Group) => {
        const { currentPeriodCount, previousPeriodCount, label } = group;

        // Calculate percentage change
        const percentageChange =
            previousPeriodCount === 0
                ? (currentPeriodCount > 0 ? 100 : 0) // 100% increase if current > 0, otherwise 0
                : ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100;


        // Determine the color and icon for percentage change
        const changeColor = percentageChange >= 0 ? "text-success" : "text-danger";
        const changeIcon =
            percentageChange >= 0 ? <i className="ri-arrow-up-s-fill text-success"></i> : <i className="ri-arrow-down-s-fill text-danger"></i>;

        return (
            <div className="d-flex flex-column justify-content-start">
                <p className="text-uppercase fw-bold text-dark text-truncate mb-2 text-center">
                    {label}
                </p>
                <h4 className="fs-3 fw-bold text-center">
                    <a href="#" className="link-primary-subtle" onClick={() => onChartClick(group)}>{currentPeriodCount}</a>
                </h4>
                {compareBy && (
                    <h5 className={`fs-18 fw-bold ff-secondary text-center ${changeColor}`}>
                        {percentageChange.toFixed(2)}% {changeIcon}
                    </h5>
                )}
            </div>
        );
    };

    if(loading) {
        return (
            <Card className="card-animate overflow-hidden m-2 placeholder-glow" style={{ minWidth: "200px" }}>
                {/* <div className="position-absolute start-0" style={{ zIndex: "0" }}>
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                        <path
                            id="Shape 8"
                            style={{ opacity: ".05", fill: "#67b173" }}
                            d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        />
                    </svg>
                </div> */}
                <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-2 text-center placeholder">
                            Count of items
                        </p>
                        <h4 className="fs-22 fw-bold ff-secondary text-center placeholder w-25">
                            100
                        </h4>
                        {compareBy && (
                            <h5 className="fs-18 fw-bold ff-secondary text-center placeholder w-50">100 %</h5>
                        )}
                    </div>
                </CardBody>
            </Card>
        )
    }

    if(error) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 p-5 gap-3">
                <Label className="fw-semibold text-danger fs-5">This report couldn't load. Please refresh or try again later.</Label>
                <Button
                    size="sm"
                    onClick={() => generateReport()}
                    color="primary"
                    className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Refresh
                </Button>
            </div>
        )
    }

    return (
        <div className="d-flex flex-wrap justify-content-evenly overflow-scroll gap-5 align-items-center">
            {items.map(renderKPICard)}
        </div>
    );
};

export default KPIReport;
