import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Select from "react-select";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Label,
    Row,
    UncontrolledAccordion,
} from 'reactstrap';
import { ApplicationState } from 'store';
import { userGetRequest } from 'store/user/action';
import { roleGetRequest, roleNameListRequest } from 'store/role/action';
import { userRoleUpdateRequest } from 'store/user/action';
import { isJson } from 'utils';

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string; // or the appropriate type
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean; // or the appropriate type
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    children: Feature[];
    permissions: any
}

interface MenuItem {
    name: string;
    children: SubItem[];
    permissions: any;
}

interface CreateInputs {
    role: string;
}

interface ReviewProps extends WithTranslation {
    createInputs: CreateInputs;
    accessData: MenuItem[];
}

const Review: React.FC<ReviewProps> = ({ createInputs, accessData, t }) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user.user);
    const [selectedRole, setSelectedRole] = useState(null)
    const [roleTitle, setRoleTitle] = useState<any>(user)
    const [roleId, setRoleId] = useState('')
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [roleList, setRoleList] = useState<any>()
    const roles = useSelector((state: ApplicationState) => state.role.list);
    const [roleUpdate, setRoleUpdate] = useState<boolean>(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [opentab, setOpentab] = useState<string[]>(['CRM Objects', 'CRM Tools','Marketing', 'Sales', 'Reporting', 'Service', 'Automation', 'Settings Access', 'User & Account Access'])
    const renderOption = (options: Options[] | undefined, value: string) => {
        const selectedOption = options && options.find((option) => option.value === value);

        if (selectedOption) {
            let value = selectedOption.label == "ON" ? "" : selectedOption.label 
            return value;
        }
        return '';
    };

    useEffect(() => {
        const roleValues: any = [];
        roles && roles
            .filter((item: any) => item.title !== "Owner").map((item: any) => {
                roleValues.push({ value: item.id, label: item.title });
            });

        setRoleList(roleValues);
    }, [roles]);

    useEffect(() => {
        dispatch(userGetRequest(id))
        accessData && accessData.length &&
            accessData.map((item: MenuItem, index: number) => (
                item.children.map((subItem: SubItem, key: number) => {
                    let name = subItem.name;
                    setOpentab(prevOpentab => [...prevOpentab, name]);
                })))
    }, []);


    useEffect(() => {
        dispatch(userGetRequest(id))
    }, [user?.role?.id])

    const handleEditRole = () => {
        setIsEdit(prevIsEdit => !prevIsEdit);
        setRoleUpdate(prevRoleUpdate => !prevRoleUpdate);
    }

    const updateRole = () => {
        setIsEdit(prevIsEdit => !prevIsEdit);
        const data = {
            "attributes": [
                {
                    "key": "role_id",
                    "value": roleId
                }
            ]
        };

        const handleSuccess = (body: any): void => {
            dispatch(userGetRequest(id))
            // setSelectedRole(selectedRole)

        };

        const handleError = (body: any): void => {
            // Handle error
        };

        dispatch(userRoleUpdateRequest(id, data, handleSuccess, handleError));
    };

    const handleRoleSearch = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        switch (name) {
            case 'searchRole':
                queryParams.set('search', option.toString());
                break;
            default:
                break;

        }
        dispatch(roleNameListRequest(queryParams.toString()));
    }

    const handleRoleSelect = (sortBy: any) => {
        dispatch(roleGetRequest(sortBy.value))
        setRoleTitle(sortBy.label)
        setRoleId(sortBy.value)
        setSelectedRole(sortBy);
        setMenuIsOpen(false); // Close the menu when an option is selected
    };

    const handleInputChange = (inputValue: any) => {
        handleRoleSearch(inputValue, "searchRole");
        handleRoleSearch(inputValue, "searchStatus");
    };

    const renderPermissions = (subItem: any, key: any) => {
        const shouldRenderAccordionItem =
            subItem.permissions &&
            subItem.permissions.some((permission: any) => (permission.value  && permission.value != 'false'));
        return shouldRenderAccordionItem && subItem.name ? (
            <div key={subItem.id}>
                <AccordionItem className="border border-1 border-secoundary">
                    <AccordionHeader
                        targetId={subItem.name}
                        className="border border-1 border-secoundary"
                    >
                        {subItem.name}
                    </AccordionHeader>
                    <AccordionBody
                        className="mt-2 mb-2"
                        accordionId={subItem.name}
                    >
                        {subItem.permissions && subItem.permissions.length ? (
                            subItem.permissions.map((permission: any, index: any) =>
                                permission.value  && permission.value != 'false' && permission.value != 'FALSE' ? (
                                    <div key={permission.id}>
                                        <span className="mt-2 mb-2 fw-bold">
                                            {permission.name}
                                        </span>
                                        <p className="mt-2 mb-2">
                                            {permission.permissions &&
                                                permission.permissions.length ? (
                                                permission.permissions.map(
                                                    (permission: any, index: any) => (
                                                        permission.options && permission.value && permission.value != 'false' && permission.value != 'FALSE' && permission.type == 'dropdown' ? (
                                                            <span key={permission.id}>
                                                                <span className="fw-semibold">
                                                                    {permission.name}:
                                                                </span>{' '}
                                                                &nbsp;
                                                                {permission.value && permission.value != 'false' && (
                                                                    <span>
                                                                        {renderOption(isJson(permission.options),
                                                                            permission.value
                                                                        )}
                                                                    </span>
                                                                )}
                                                                , &nbsp;&nbsp;&nbsp;
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="fw-semibold py-3"
                                                                key={permission.id}
                                                            >
                                                                {permission.value  && permission.value != 'false' ? (
                                                                    <span className="fw-semibold">
                                                                        {permission.name}
                                                                        <br />
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        )
                                                    )
                                                )
                                            ) :
                                                <span className="fw-semibold">
                                                    {
                                                        typeof permission.value === 'string'  && permission.value != 'false' && permission.value != 'FALSE' ?
                                                            <span>
                                                                {renderOption(isJson(permission.options),
                                                                    permission.value
                                                                )}</span>
                                                            : null
                                                    }
                                                    <br />
                                                </span>
                                            }
                                        </p>
                                    </div>
                                ) : null
                            )
                        ) : null}
                    </AccordionBody>
                </AccordionItem>
            </div>
        ) : null;
    }
    
    return (
        <div className="hstack mt-0 mb-4">
        <Row className="p-2 w-100 hstack gap-3 justify-items-center">
          <div className="vstack gap -1 justify-content-center">
            <h4 className="">Access</h4>
            </div>
            <div className='d-flex justify-content-between'>
                                            <div className='fs-16 fw-medium d-flex align-items-center'>
                                                <span>Current Role: </span>
                                                {!isEdit ? (
                                                    <span className='ms-2'><Label className='mb-0'>{user?.role?.title}</Label></span>
                                                ) : (
                                                    <span style={{ width: "300px" }} className="ms-2">
                                                        <Select
                                                            placeholder={user?.role?.title}
                                                            value={selectedRole}
                                                            onInputChange={handleInputChange}
                                                            onChange={handleRoleSelect}
                                                            options={roleList}
                                                            classNamePrefix="js-example-data-array"
                                                            isLoading={false}
                                                            menuIsOpen={menuIsOpen}
                                                            onMenuOpen={() => setMenuIsOpen(true)}
                                                            onMenuClose={() => setMenuIsOpen(false)}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                            <div className='fs-16 fw-medium'>
                                                {isEdit ? (
                                                    <Button
                                                        disabled={user?.role?.title === roleTitle}
                                                        color="success"
                                                        onClick={updateRole}
                                                        className="bg-gradient"
                                                    >
                                                        Save
                                                    </Button>
                                                ) : (
                                                    <Button
                                                    
                                                        onClick={handleEditRole}
                                                        color="primary"
                                                    >
                                                        Edit Role
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
            <UncontrolledAccordion className="custom-accordion-border" toggle={() => { }} defaultOpen={opentab} stayOpen>
                {accessData && accessData.length ? (
                    accessData.map((item: any, index: number) => (
                        <React.Fragment key={item.id}>
                            <>
                                {
                                    item.children && item.children.length ? item.children.map((subItem: SubItem, key: number) => {
                                        return <React.Fragment>
                                            <>{renderPermissions(subItem, key)}</>
                                        </React.Fragment>
                                    }) : (
                                    <React.Fragment>
                                        {renderPermissions(item, index)}
                                    </React.Fragment>)
                                }
                            </>
                        </React.Fragment>
                    ))
                ) : null}
            </UncontrolledAccordion>
            </Row>
        </div>
    );
};

export default withTranslation()(Review);
