import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Button,
    Col,
    Label,
    Input,
} from "reactstrap";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import { isJson } from "utils";
    

const ArrangeFields = ({ props, show, onCloseClick, dataFields, onChange, fields, views }: any) => {  
    const [metadata, setMetadata] = useState<any>([]);
    const [column, setColumn] = useState<any>({});
    const [viewname, setViewname] = useState<any>("");
    const [error, setError] = useState(false)

    useEffect(() => {
        let newmetadata: any = []
        fields && fields.length ? fields.map((settingfield: any) => {
            dataFields && dataFields.map((field: any) => {
                if(settingfield.id == field.id) {
                    let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
                    return newmetadata.push(field)
                }
            })
        })
        : dataFields && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            return newmetadata.push(field)
        })
        setMetadata(newmetadata);
    }, [dataFields])


    const onChangeIndex = (column: any,index: any) => {
        if (index !== -1) {
            const removedColumn = metadata.splice(metadata.indexOf(column), 1)[0];
            metadata.splice(index, 0, removedColumn);
            setMetadata([...metadata]);
        }
    }

    const onApply = () => {
        let sequence: any = []
        metadata && metadata.length && 
        metadata.map((field: any) => {
            let item = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}
            sequence.push({id: field.id, extras: {key:item.key }})
        })
        onChange(sequence, viewname)
        setViewname("")
        onCloseClick()
    }
    
    const onDrop = (index: any) => {
        onChangeIndex(column, index)
    };

    const onDrag = (column: any) => {
        setColumn(column)
    };

    const onDragOver = (e: any) => {
        e.preventDefault();
        const scrollableContainer: any = document.querySelector('.simplebar-track-dark'); // adjust the selector to match your container
        const rect = scrollableContainer.getBoundingClientRect();
        const top = rect.top;
        const bottom = rect.bottom;
        const left = rect.left;
        const right = rect.right;
        const mouseY = e.clientY;
        const mouseX = e.clientX;
      
        if (mouseY < top + 50) { // adjust the threshold value to your liking
          scrollableContainer.scrollBy(0, -10); // scroll up
        } else if (mouseY > bottom - 50) { // adjust the threshold value to your liking
          scrollableContainer.scrollBy(0, 10); // scroll down
        }
      
        if (mouseX < left + 50) { // adjust the threshold value to your liking
          scrollableContainer.scrollBy(-10, 0); // scroll left
        } else if (mouseX > right - 50) { // adjust the threshold value to your liking
          scrollableContainer.scrollBy(10, 0); // scroll right
        }
      }
      useEffect(() => {
          let view = views?.length ? views.filter((item: any) => item.name.toLowerCase() === viewname.toLowerCase()) : []
           setError(view?.length)
      }, [viewname])
      

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show); setViewname("")}}>
                Arrange Fields
            </OffcanvasHeader>
                <OffcanvasBody>
                    <Card>
                        <CardBody className="p-0">
                        <div>
                            <Label>
                                View Name
                            </Label><span className="fw-bold text-danger">{" "}*</span>
                            <Input type={"text"} className="form-control w-100" placeholder={"Enter View name"} value={viewname} onChange={(e: any) => setViewname(e.target.value)} />     
                            {error ? <p className="text-danger p-1">View with same name already exist</p>: null}                 
                        </div>
                            <div className="live-preview vstack gap-2">
                                <SimpleBar autoHide={false} className="simplebar-track-dark py-3" style={{ maxHeight: "600px" }}
                                    onDragOver={(e: any) => {onDragOver(e); e.preventDefault()}}>   
                                        <div className="vstack">
                                            {
                                                 metadata && metadata.map((field: any, index: any) => {
                                                    let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}                                                  
                                                    return <div className="pt-2" onDrop={() => { onDrop(index); }} onDrag={() => { onDrag(field); }}>
                                                    <div key={index} className="hstack gap-1 text-dark">                                            
                                                            <Button size="md" color={"btn-ghost-light"} className="ps-0 form-control justify-content-start hstack border border-opacity-25 cursor-grab" outline={true} draggable>
                                                            <div className="hstack gap-2"><i className="bx bx-grid-vertical align-center justify-content-end fs-18 text-light-emphasis" /><span className="hstack align-items-center justify-content-start text-start">{item.label}</span></div>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                </SimpleBar>
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
                <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
                    <Button
                        className="btn btn-success w-auto"
                        onClick={() => onApply()}
                        disabled={!viewname || error}
                    >
                        Apply Changes
                    </Button>
                </div>
        </Offcanvas>
    );
};

export default ArrangeFields;

