import { useState } from 'react';
import { Card, CardBody, CardHeader, Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import Courses from './Courses';
import ApplicationComponent from './index';
import Recommend from './Recommend/Recommend';

const Applications = (props: any) => {    
    const { isSubAgent } = props;
    // const url = new URL(window.location.href);
    const [activeTab, setActiveTab] = useState<any>("applications");

    // useEffect(() => {
    //     const hash = url?.hash.substring(1); // Remove the '#' symbol
    //     const [tab, subTab] = hash?.split('&');
    //     const subTabIndex = subTab?.replace('subgroup-', '');
    //     if(subTabIndex) setActiveTab(subTabIndex)
    // }, [url?.hash]);

    const tabChange = (tab: any) => {        
        // window.location.hash = `module-applications&subgroup-${tab}`;
        if (activeTab !== tab) setActiveTab(tab);
    };
    document.title = "DTS | Zilter";
    return ( 
        <Card>
            <CardHeader>
                <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 gap-2"
                    role="tablist">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "applications" })}
                            onClick={() => {
                                tabChange("applications");
                            }}
                            type="button" to={''}                        >
                            <i className="ri-contacts-book-line"></i>{" "}
                            <strong>{props.t("student.applications")}</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "shortlist" })}
                            onClick={() => {
                                tabChange("shortlist");
                            }}
                            type="button" to={''}                        >
                            <i className="ri-file-3-line"></i>{" "}

                            {isSubAgent ? (
                                <strong>Applied</strong>
                            ) : (
                                <strong>{props.t("student.shortlist")}</strong>
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "courses" })}
                            onClick={() => {
                                tabChange("courses");
                            }}
                            type="button"
                        >
                            <i className="ri-book-open-line"></i>{" "}
                            <strong>{props.t("student.courses")}</strong>
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardHeader>
            <CardBody className="p-2">
                <TabContent activeTab={activeTab}>
                    {activeTab === "applications" && <TabPane tabId="applications">
                        <ApplicationComponent isSubAgent={isSubAgent} />
                    </TabPane>}
                    {activeTab === "shortlist" && <TabPane tabId="shortlist">
                        <Recommend isSubAgent={isSubAgent} />
                    </TabPane>}
                    {activeTab === "courses" && <TabPane tabId="courses">
                        <Courses isSubAgent={isSubAgent} />
                    </TabPane>}
                </TabContent>
            </CardBody>
        </Card>
    
    );
};
export default withTranslation()(Applications);


