export interface User {
    id: string,
    name: string,
    tenant: {
      id: string,
      email: string,
      companyName: string,
      website: string,
      noOfEmployees: string
    },
    email: string,
    profilePic: string,
    phoneNumber: string,
    role: {
      id: string,
      title: string,
      slug: string,
      description: string,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      permissions: [
        {}
      ]
    },
    confirmationStatus: string,
    createdAt: string,
    updatedAt: string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum UserActionTypes {
    POST_INVITE_REQUEST = "@@user/POST_INVITE_REQUEST",
    POST_INVITE_SUCCESS = "@@user/POST_INVITE_SUCCESS",
    POST_INVITE_ERROR = "@@user/POST_INVITE_ERROR",
    GET_USER_REQUEST = "@@user/GET_USER_REQUEST",
    GET_USER_SUCCESS = "@@user/GET_USER_SUCCESS",
    GET_USER_ERROR = "@@user/GET_USER_ERROR",
    GET_USERS_REQUEST = "@@user/GET_USERS_REQUEST",
    GET_USERS_SUCCESS = "@@user/GET_USERS_SUCCESS",
    GET_USERS_ERROR = "@@user/GET_USERS_ERROR",
    PUT_USER_REQUEST = "@@user/PUT_USER_REQUEST",
    PUT_USER_SUCCESS = "@@user/PUT_USER_SUCCESS",
    PUT_USER_ERROR = "@@user/PUT_USER_ERROR",
    DELETE_USER_REQUEST = "@@user/DELETE_USER_REQUEST",
    DELETE_USER_SUCCESS = "@@user/DELETE_USER_SUCCESS",
    DELETE_USER_ERROR = "@@user/DELETE_USER_ERROR",
    PUT_ROLE_REQUEST = "@@user/PUT_ROLE_REQUEST",
    PUT_ROLE_SUCCESS = "@@user/PUT_ROLE_SUCCESS",
    PUT_ROLE_ERROR = "@@user/PUT_ROLE_ERROR",
    PUT_TENANT_REQUEST = "@@user/PUT_TENANT_REQUEST",
    PUT_TENANT_SUCCESS = "@@user/PUT_TENANT_SUCCESS",
    PUT_TENANT_ERROR = "@@user/PUT_TENANT_ERROR",
    GET_TENANT_REQUEST = "@@user/GET_TENANT_REQUEST",
    GET_TENANT_SUCCESS = "@@user/GET_TENANT_SUCCESS",
    GET_TENANT_ERROR = "@@user/GET_TENANT_ERROR",
    GET_TENANT_OPEN_REQUEST = "@@user/GET_TENANT_OPEN_REQUEST",
    GET_TENANT_OPEN_SUCCESS = "@@user/GET_TENANT_OPEN_SUCCESS",
    GET_TENANT_OPEN_ERROR = "@@user/GET_TENANT_OPEN_ERROR",
    PUT_ACCOUNT_REQUEST = "@@user/PUT_ACCOUNT_REQUEST",
    PUT_ACCOUNT_SUCCESS = "@@user/PUT_ACCOUNT_SUCCESS",
    PUT_ACCOUNT_ERROR = "@@user/PUT_ACCOUNT_ERROR",
}

export interface UserState {
    // readonly loading: boolean;
    readonly users: User[] | null;
    readonly user: User | null;
    readonly pagination: Pagination | null;
    readonly loading: boolean;
    readonly tenant: any;
    readonly tenatDetails: any;
}
