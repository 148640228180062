import React, { useEffect, useState } from 'react'
import avatar7 from "../../../../../assets/images/users/avatar-7.jpg";
import { Link } from "react-router-dom";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Select from "react-select";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { isJson } from 'utils';
import { noteCreateRequest } from 'store/notes/action';
import SimpleBar from 'simplebar-react';

const Comments = ({ t, noteType, transcribing, setTranscribing, record, model, parentField, applicationId, notesFilter, studentId, parentFilter, notesMetadata, props }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const notes = useSelector((state: ApplicationState) => state.notes.list);
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [replyNote, setReplyNote] = useState("")
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ transcribing: transcribing == "child",
        clearTranscriptOnListen: true,  // Clear transcript when microphone is turned on
        commands: [  // Define custom speech commands if needed
            {
                command: 'stop',
                callback: () => stopListening(),
            },
        ]
     });

    useEffect(() => {
        resetTranscript();
    }, [record])


    useEffect(() => {
        if (listening && transcript) {
            const timeout = setTimeout(() => {
                setReplyNote((prevNote) => `${prevNote} ${transcript}`.trim());
                resetTranscript();
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [transcript, listening]);
    

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("child");
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        setTranscribing(false);  // Stop transcribing when listening stops
        SpeechRecognition.stopListening();
        // Append transcript to existing newNote when listening stops
        if (transcript) {
            handleReplyNoteChange(transcript, true);
            resetTranscript();
        }
    }; 

  
    const handleReplyNote = () => {
        stopListening();
        const data =
        {
            shortNote: shortNoteValue,
            parent: replyNoteId,
            parentId: replyNoteId,
            note: replyNote,
            student: studentId,
            application: applicationId ? applicationId : null
        }
        const handleSuccess = (body: any): void => {
            handleReset()            
            notesFilter()            
        };

        const handleError = (body: any): void => {
        };
        dispatch(noteCreateRequest(data, handleSuccess, handleError));
    };

    const handleReset = () => {
        setReplyNote("");
        setSelectedNoteType(null)
        resetTranscript();
        setAddShortNoteValue('')
    };
    const handleReplyNoteChange = ( input: React.ChangeEvent<HTMLTextAreaElement> | string,
        appendSpeech: boolean = false) => {
        setReplyNote((prevNote) => {
            if (typeof input === "string") {
                // Append speech transcript if needed
                return appendSpeech ? `${prevNote} ${input}`.trim() : input;
            } else {
                // Handle manual textarea input
                return input.target.value;
            }
        });
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
    };
    const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
    };

    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };

    
    const textEle = <textarea
        style={{width:300}}
        className="form-control bg-light border-light"
        id="exampleFormControlTextarea1"
        value={replyNote}
        onChange={(e) => handleReplyNoteChange(e)}
        rows={3}
        placeholder={props.t("applications.add_note")}
    ></textarea>

    // const checkkboxEle = <div className="form-check mb-0 align-self-center">
    //     <Input
    //         className="form-check-input"
    //         type="checkbox"
    //         checked={isChecked}
    //         onChange={handleFollowupCheck}
    //         id="formCheck6"
    //     />
    //     <Label className="form-check-label" htmlFor="formCheck6">
    //         Follow-up required?
    //     </Label>
    // </div>

    const renderComments = (notes: any, a: any) => {       
        return (
            <React.Fragment>
                {notes && notes.map((note: any, key: number) => {
                    let valuesJson = note?.valuesJson && isJson(note?.valuesJson) ? JSON.parse(note?.valuesJson) : note?.valuesJson
                    let comment = {...valuesJson, ...note}
                    return <div className="d-flex flex-column mb-4" key={key}>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="hstack flex-wrap gap-2 fs-15 mb-1">
                                    <Link to="/pages-profile">{comment?.created_by?.name}</Link>{" "}
                                        <div>
                                            <span className="badge rounded-pill bg-info-subtle text-info h-auto">{comment?.shortNote}</span>
                                        </div>
                                    </h5>                               
                            </div>                                                           
                        </div>
                        <div className="vstack gap-1"> 
                            <div className="hstack gap-2 justify-content-start" style={{minWidth:500}}>
                                <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                            </div> 
                            <div className="ms-2" style={{minWidth:300}}>                            
                                <p className="text-muted mb-0">{comment?.note}</p>
                            </div>
                        </div>
                        <div className="ms-5 mb-2">
                            {replyNoteId === comment.id ? (
                                <SimpleBar autoHide={false} className="vstack gap-3 py-3" style={{ minWidth: 300 }}>
                                    <Row className="vstack gap-2 mt-1 ms-1">
                                            <Col xs={12}>
                                                {notesMetadata?.length > 0 &&
                                                    notesMetadata.map((item: any, index: number) => {
                                                        if(item.type === "textarea") return (
                                                            <>
                                                                {textEle}
                                                            </>
                                                        )
                                                    })}
                                            </Col>
                                            <Col xs={12} className="mb-1">
                                                <div style={{width:500}} className="d-flex justify-content-start gap-2">
                                                    {/* {notesMetadata?.length > 0 &&
                                                        notesMetadata.map((item: any, index: number) => {
                                                            if(item.type === "checkbox") return (
                                                                <>
                                                                    {checkkboxEle}
                                                                </>
                                                            )
                                                        })} */}
                                                    <div>
                                                        {notesMetadata?.length > 0 &&
                                                            notesMetadata.map((item: any, index: number) => {
                                                                if(item.type === "select") return (
                                                                    <Select
                                                                        key={index} // Unique key for the Select element
                                                                        placeholder="Select Note Type"
                                                                        classNamePrefix="js-example-data-array"
                                                                        isLoading={false}
                                                                        options={item?.values} // Pass all options directly
                                                                        value={selectedNoteType}
                                                                        onChange={handleNoteTypeChange}
                                                                        menuPlacement="auto"
                                                                        menuPosition="fixed"
                                                                    />
                                                                )
                                                            })}
                                                    </div>
                                                    {listening ? (
                                                        <Button
                                                            color="danger"
                                                            className="ms-2 btn-icon"
                                                            outline
                                                            type="button"
                                                            onClick={stopListening}
                                                        >
                                                            <i className="ri-mic-off-line"></i>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            className="ms-2 btn-icon"
                                                            outline
                                                            type="button"
                                                            onClick={startListening}
                                                        >
                                                            <i className="ri-mic-line"></i>
                                                        </Button>
                                                    )}
                                                    {/* <Button
                                                        color="primary"
                                                        className="ms-2 btn btn-primary"
                                                        disabled={
                                                            isAddNoteButtonDisabled ||
                                                            (!shortNoteValue || !selectedNoteType) &&
                                                            (!transcript || !selectedNoteType)
                                                        }
                                                        onClick={handleReplyNote}
                                                    >
                                                        {props.t("applications.reply")}
                                                    </Button> */}
                                                    <button onClick={(e) => {
                                                            handleReplyNote();
                                                            e.preventDefault();
                                                            toggleReply(comment.id);
                                                        }}
                                                            disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                                            className="btn btn-sm btn-success mt-1">{t("applications.reply")}</button>
                                                        <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{t("applications.cancel")}</button>
                                                
                                                </div>
                                            </Col>
                                        </Row>     
                                </SimpleBar>
                            ) :
                                <>
                                    <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                        // e.preventDefault(); 
                                        toggleReply(comment.id)
                                        setReplyNoteId(comment.id)
                                    }}>
                                        <i className="mdi mdi-reply"></i> {t("applications.reply")}
                                    </Link>
                                </>
                            }
                        </div>
                        {/* Render nested comments */}
                        {comment.children && comment.children.length > 0 && (
                            <div className="ms-5">
                                {renderComments(comment.children, true)}
                            </div>
                        )}
                    </div>
                })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className='vstack gap-3'>
            {notes && notes.map((note: any, key: number) => {              
                let valuesJson = note?.valuesJson && isJson(note?.valuesJson) ? JSON.parse(note?.valuesJson) : note?.valuesJson
                let comment = {...valuesJson,...note, id: note?.id}
                return  <div className="vstack gap-2" key={key}>
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                        <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h5 className="hstack flex-wrap gap-2 fs-15 mb-1">
                            <Link to="/pages-profile">{comment?.created_by?.name}</Link>{" "}
                                <div>
                                    <span className="badge rounded-pill bg-info-subtle text-info h-auto">{comment?.shortNote}</span>
                                </div>
                             </h5>                               
                    </div>                                                           
                </div>
                <div className="vstack gap-1"> 
                    <div className="hstack gap-2 justify-content-start" style={{minWidth:500}}>
                        <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                    </div> 
                    <div className="ms-2" style={{minWidth:300}}>                            
                        <p className="text-muted mb-0">{comment?.note}</p>
                    </div>
                </div>
                <div>
                    {replyNoteId === comment.id ? (
                                        <SimpleBar autoHide={false} className="vstack gap-3 py-3" style={{ minWidth: 300 }}>
                                            <div className="vstack gap-2">
                                                <div className="hstack gap-1">
                                                    <textarea style={{minWidth: 280}} className="form-control bg-light border-light w-100 justify-self-end" value={replyNote} onChange={(e)=>handleReplyNoteChange(e)} rows={3} placeholder={t("applications.reply_to_this_note")}></textarea>
                                                </div>
                                                <div className="hstack gap-1">
                                                    <div  style={{minWidth: 150}}>                                            
                                                        <Select
                                                            placeholder="Select type"
                                                            isLoading={false}
                                                            options={noteType}
                                                            value={selectedNoteType}
                                                            onChange={handleNoteTypeChange}
                                                            menuPlacement="auto" // You can also try "bottom" or "top"
                                                            menuPosition="fixed"
                                                            className={"form-select-sm"}
                                                        />
                                                    </div>
                                                    {listening ? (
                                                        <>
                                                            <Button color="danger" size="sm" className="btn-icon p-3" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button color="primary" size="sm" className="btn-icon p-3" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                                        </>
                                                    )}
                                                    <Button color="success" size="sm" type="button"
                                                    onClick={(e) => {
                                                        handleReplyNote();
                                                        e.preventDefault();
                                                        toggleReply(comment.id);
                                                    }}
                                                        disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                                        >
                                                            {t("applications.reply")}</Button>
                                                    <Button color="danger" size="sm" type="button"
                                                            onClick={() => {
                                                                handleReset()
                                                                toggleReply(comment.id)
                                                            }} 
                                                    >
                                                        {t("applications.cancel")}</Button>
                                                </div>
                                            </div>
                                        </SimpleBar>
                                    ) :
                                    <>
                                        <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                            // e.preventDefault(); 
                                            toggleReply(comment.id)
                                            setReplyNoteId(comment.id)
                                        }}>
                                            <i className="mdi mdi-reply"></i> {t("applications.reply")}
                                        </Link>
                                    </>
                                }
                            </div>
                            {/* Render nested comments */}
                            {comment.children && comment.children.length > 0 && (
                                <div className="ms-5">
                                    {renderComments(comment.children, true)}
                                </div>
                            )}
                        </div>
            })}
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Comments);