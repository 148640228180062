import React, { useCallback, useEffect, useState } from "react";
import { Button, Input } from 'reactstrap';
import conditionsJson from "./conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { optionsListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "../SelectTree/SelectTree";
import { getStatusOptions } from "helpers/workflowStatusHelper";
import { convertCurrency, isJson } from "utils";
import { currencyGetRequest } from "store/currency/action";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

const AddFilter = ({ dataFields, onFilterSelected, selectedGroup, filter, setSelectedGroupIndex }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updateCurrecyState = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    // State Variables
    const [properties, setProperties] = useState<PropertyProps[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<PropertyProps | null>(null);
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<PropertyProps[]>([]);
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [parsedRates, setParsedRates] = useState<any>(null)
    const [assignRates, setAssignRates] = useState<any>(null)
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    
    useEffect(() => {
        dispatch(currencyGetRequest())
        }, [])
        
    useEffect(() => {

        if (updateCurrecyState) {
            setSelectedCurrency(updateCurrecyState.selectedCurrency);
            setCurrencySymbol(updateCurrecyState.selectedCurrencySymbol)
        }
    }, [updateCurrecyState]);

    useEffect(() => {
        if (conversionRates) {
            setAssignRates(conversionRates)
        }
    }, [conversionRates])

    // Map data fields to properties on component load
    useEffect(() => {
        const mappedProperties = dataFields?.map((item: any) => ({
            label: item.Header,
            value: item.accessor,
            type: item?.valueJson?.type || "datetime",
            key: item?.valueJson?.key,
            valueJson: item.valueJson || {}
        })) || [];
        setProperties(mappedProperties);
    }, [dataFields]);

    // Clear filter selections
    const clearFilter = useCallback(() => {
        setSelectedProperty(null);
        setConditions([]);
        setSelectedCondition(null);
        resetValueStates();
    }, []);

    useEffect(() => {
        if (filter) {
            const property = filter?.property;
            setSelectedProperty(property)
            const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
            setConditions(conditionOptions);
            setSelectedCondition({ value: filter?.condition, label: filter?.conditionLabel })
            setValues(filter?.valuesLabel);
            setValue(filter?.value);
            setHighValue(filter?.highValue);
        } else {
            clearFilter()
        }
    }, [filter, clearFilter])
    
    useEffect(() => {
        if (assignRates && assignRates?.length > 0) {
            const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

            // Check if `targetCurrencyJson` exists and is a valid JSON string
            const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                ? JSON.parse(targetCurrencyJson)
                : {};

            // Set the parsed rates only if convertJson is valid
            setParsedRates(convertJson?.conversion_rates);
        }
    }, [assignRates]);
    
    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj && optionsObj?.[selectedProperty?.valueJson?.model]) {
            if (selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj?.[selectedProperty?.valueJson?.model].map(transformTree);

                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj?.[selectedProperty?.valueJson?.model].map((option: any) => {
                    const optionsJson = option.valuesJson && isJson(option.valuesJson) ? JSON.parse(option.valuesJson) : option.valuesJson;
                    return { label: optionsJson?.[selectedProperty?.valueJson?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj?.[selectedProperty?.valueJson?.model], selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = node?.valuesJson && isJson(node?.valuesJson) ? JSON.parse(node?.valuesJson) : node?.valuesJson;
        // Create the base transformed node
        const transformedNode: TransformedNode = {
            label: values?.name,
            value: node?.id,
            createdBy: node?.createdBy,
            createdAt: node?.createdAt,
            updatedAt: node?.updatedAt,
            deletedAt: node?.deletedAt,
            parentId: node?.parentId,
            parent: node?.parent,
        };

        // Recursively transform children if they exist and are not empty
        if (node?.children && node?.children.length > 0) {
            transformedNode.children = node?.children.map(transformTree);
        }

        return transformedNode;
    }

    // Handle property selection
    const onSelectProperty = (property: PropertyProps) => {
        if (property) {
            setSelectedProperty(property);
            const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
            if (conditionOptions) {
                setConditions(conditionOptions);
                setSelectedCondition(conditionOptions[0]); // Set the first condition by default
            }
            resetValueStates();
        } else {
            clearFilter();
        }
    };

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        setOptions([])
        if (selectedProperty?.valueJson?.model) {
            let filter ={
                "filters": [
                  {
                    "quick": [],
                    "advance": [],
                    "search": null
                  }
                ],
                "sorts": [
                  {
                    "field": "name",
                    "order": "asc"
                  }
                ]
            }
            let filterObject=null
            if(selectedProperty?.valueJson?.model == 'sources' || selectedProperty?.valueJson?.responseType) filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(selectedProperty?.valueJson?.model, filter, 0, 20, filterObject)); 	
        }
    };

    const onFilterSelectClicked = () => {
        // const isCourseFee = selectedProperty?.value === 'course_fee'
        // const ensureNumeric = (input: number | Date | null): number => {
        //     if (input instanceof Date) {
        //         return input.getTime();
        //     }
        //     return input !== null ? Number(input) : 0;
        // };
    
        // const convertedValue = isCourseFee
        //     ? Number(
        //           convertCurrency(ensureNumeric(value), selectedCurrency, 'GBP', parsedRates)
        //       ).toFixed(2)
        //     : value;
    
        // const convertedHighValue = isCourseFee
        //     ? Number(
        //           convertCurrency(ensureNumeric(highValue), selectedCurrency, 'GBP', parsedRates)
        //       ).toFixed(2)
        //     : highValue;
    
        onFilterSelected(
            {
                key: selectedProperty?.value,
                keyLabel: selectedProperty?.label,
                condition: selectedCondition?.value,
                conditionLabel: selectedCondition?.label,
                values: values.map((val) => val.value),
                valuesLabel: values,
                property: selectedProperty,
                value,
                highValue,
                currency: selectedCurrency
            },
            selectedGroup
        );
        clearFilter();
    };
    

    const handletypetext = (value: any) => {        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            let filter ={
                "filters": [
                {
                    "search": {
                        "term": value,
                        "field": "all"
                    }
                }
                ],
                "sorts": [
                {
                    "field": "name",
                    "order": "asc"
                }
                ]
            }
            let filterObject=null
            if(selectedProperty?.valueJson?.model == 'sources' || selectedProperty?.valueJson?.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(selectedProperty?.valueJson?.model, filter, 0, 20, filterObject)); 	
     
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    // Render input field based on property type
    const renderValueInput = () => {
        switch (selectedProperty?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        styles={customStyles}
                        onInputChange={(inputValue: any) => handletypetext(inputValue)}
                        name="values"
                        onChange={setValues}
                        value={values}
                        isLoading={optionsLoading}
                        
                        options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                let valueOptions = selectedProperty?.valueJson?.values || []
                if (selectedProperty?.valueJson?.process_flows?.length) {
                    const { options: groupOptions } = getStatusOptions(selectedProperty?.valueJson?.process_flows)
                    valueOptions = groupOptions;
                }
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        styles={customStyles}
                        name="values"
                        options={valueOptions}
                        onChange={setValues}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        styles={customStyles}
                        name="values"
                        options={[]}
                        onChange={(newValue: any) => setValues(newValue)}
                        value={values}
                    />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: value ? value : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                styles={customStyles}
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                styles={customStyles}
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            styles={customStyles}
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => {setValue(e.target.value); setHighValue(null)}}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                        options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                    />
                );
            default:
                return null;
        }
    };

    const customStyles = {
        // Customize the style of the dropdown menu
        input: (provided: any) => ({
            ...provided,
            color: layoutModeType === 'light'? 'black' : 'white',
          }),
        control: (provided: any, state: any) => ({
                    ...provided,
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }

    return (
        <div className="d-flex flex-column gap-2 w-50 bg-light p-3">
            <div className="d-flex justify-content-between align-items-center">
                <h4>Add Filter</h4>
                <i className="fs-4 ri-close-line text-muted" onClick={() => setSelectedGroupIndex(null)}></i>
            </div>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                name="properties"
                options={properties}
                styles={customStyles}
                onChange={onSelectProperty}
                value={selectedProperty}
            />
            {selectedProperty && (
                <div className="d-flex flex-column gap-2">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="conditions"
                        styles={customStyles}
                        options={conditions}
                        onChange={(condition: any) => {setSelectedCondition(condition); setHighValue(null)}}
                        value={selectedCondition}
                    />
                    {renderValueInput()}
                </div>
            )}
            <Button
                onClick={onFilterSelectClicked}
                color="primary"
                className="btn-label"
                disabled={
                    !(
                        selectedCondition?.value === 'BETWEEN'
                            ? highValue && value && highValue >= value
                            : values.length || value
                    )
                }
            >
                <i className="ri-filter-3-line label-icon align-middle fs-16 me-2"></i>
                Add Filter
            </Button>
        </div>
    );
};

export default AddFilter;
