import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Popover, PopoverBody } from "reactstrap";
import googleicon from "../../assets/images/googleicon.png";
import outlook from "../../assets/images/outlook.png";
import imap from "../../assets/images/imapsmtp.svg";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { accountAddRequest, userUpdateRequest } from "store/user/action";
import { toast } from "react-toastify";
import { getGoogleCalendarData } from "store/calendarSync/action";

const sortbyname = [
    {
        options: [
            { label: "SSL/TLS (recommended)", value: "ssl" },
            { label: "StartTLS", value: "starttls" },
            { label: "None", value: "none" }
        ],
    },
];

const providers = [
    {
        options: [
            { label: "Google", value: "google" },
            { label: "Others", value: "other" }
        ],
    },
];

const SyncOptionModal = ({ t, modal, setModal, confirmModal, setConfirmModal, setConfirmModalOutlook }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const defaultOption = sortbyname[0].options[0];
    let { id }: any = useParams();
    const [googleSync, setGoogleSync] = useState<boolean>(false)
    const [outlookSync, setOutlookSync] = useState<boolean>(false)
    const [exchangeSync, setExchangeSync] = useState<boolean>(false)
    const [sortBy, setsortBy] = useState<any>(defaultOption);
    const [selectedProvider, setSelectedProvider] = useState<any>(null)
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const [sortBysmtp, setsortBysmtp] = useState<any>(defaultOption)
    const [imapModal, setImapModal] = useState<boolean>(false)
    const [imapServer, setImapServer] = useState<any>("")
    const [smtpServer, setSmtpServer] = useState<any>("")
    const [imapPort, setImapPort] = useState<any>("")
    const [smtpPort, setSmtpPort] = useState<any>("")
    const [password, setPassword] = useState<any>("")
    const [guideLink, setGuideLink] = useState<any>("")
    const [selectedImap, setSelectedImap] = useState('ssl');
    const [selectedSmtp, setSelectedSmtp] = useState('ssl');
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [imapSsl, setImapSsl] = useState<any>(true)
    const [imapStartTsl, setImapStartTsl] = useState<any>(true)
    const [smtpSsl, setSmtpSsl] = useState<any>(true)
    const [smtpStartTsl, setStartTsl] = useState<any>(true)
    const [showPassword, setShowPassword] = useState(false);
    const googleDomains = ["gmail.com", "googlemail.com", "google.com"];
    const microsoftDomains = ["outlook.com", "hotmail.com", "live.com", "microsoft.com", "msn.com"];

    useEffect(() => {
        if (selectedProvider) {
            if (selectedProvider.value === "google") {
                setGuideLink("https://support.google.com/mail/answer/185833?hl=en")
            }
            else if (selectedProvider.value === "outlook") {
                setGuideLink("https://support.microsoft.com/en-us/account-billing/how-to-get-and-use-app-passwords-5896ed9b-4263-e681-128a-a6f2979a7944")
            }
        } else setGuideLink("https://www.fastmail.help/hc/en-us/articles/360058752854-App-passwords")
    }, [selectedProvider])

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const tog_animationZoom = () => {
        setModal(!modal);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const tog_animationZoomImap = () => {
        setImapModal(!imapModal)
        setSelectedProvider("");
        setEmail("");
        setPassword("")
    }

    const handleAccountClick = (provider: "google" | "outlook" | "others") => {
        switch (provider) {
            case "google":
                setGoogleSync(true);
                setOutlookSync(false);
                setExchangeSync(false);
                break;
            case "outlook":
                setGoogleSync(false);
                setOutlookSync(true);
                setExchangeSync(false);
                break;
            case "others":
                setModal(false);
                setImapModal(true);
                break;
            default:
                return null;
        }
    };

    const disableButton = () => {
        setGoogleSync(false)
        setOutlookSync(false)
        setExchangeSync(false)
    }

    const validateEmail = (value: any) => {
        setEmail(value);
        if (!value) {
            setDomain("");
            return;
        }

        const domain = value.split("@")[1];
        if (!/\S+@\S+\.\S+/.test(value)) {
            setDomain("invalid");
        } else if (googleDomains.includes(domain)) {
            setDomain("google");
        } else if (microsoftDomains.includes(domain)) {
            setDomain("microsoft");
        } else {
            setDomain("other");
        }
    };

    const handleProviderSelect = (option: any) => {
        if (option.value === "google") {
            setImapServer("imap.gmail.com")
            setSmtpServer("smtp.gmail.com")
            setImapPort("993")
            setSmtpPort("465")
        }
        else if (option.value === "outlook") {
            setImapServer("outlook.office365.com")
            setSmtpServer("smtp.office365.com")
            setImapPort("993")
            setSmtpPort("587")
        } else if (option.value === "other") {
            setImapServer("")
            setSmtpServer("")
            setImapPort("")
            setSmtpPort("")
        }
    }

    const handleConnectionType = (protocol: any, option: any) => {
        if (protocol == "imap") {
            if (option.value == "ssl") {
                setSelectedImap('ssl')
                setImapSsl(true);
                setImapStartTsl(false)
            } else if (option.value == "starttls") {
                setSelectedImap('starttls')
                setImapSsl(false);
                setImapStartTsl(true)
            } else {
                setImapSsl(false);
                setImapStartTsl(false)
            }
        } else if (protocol == "smtp") {
            if (option.value == "ssl") {
                setSelectedSmtp('ssl')
                setSmtpSsl(true);
                setStartTsl(false)
            } else if (option.value == "starttls") {
                setSelectedSmtp('starttls')
                setSmtpSsl(false);
                setStartTsl(true)
            } else {
                setSmtpSsl(false);
                setStartTsl(false)
            }
        }
    }

    const handleAddAccount = () => {
        const selectedInfo = {
            "mail_settings": {
                "provider": selectedProvider && selectedProvider?.value ? selectedProvider.value : null,
                "credentials": {
                    "username": email,
                    "password": password
                },
                "mail": {
                    "smtp": {
                        "host": smtpServer,
                        "port": smtpPort,
                        "auth": true,
                        ...(selectedSmtp === 'ssl' && { ssl: { enable: smtpSsl } }), // Add SSL if selected
                        ...(selectedSmtp === 'starttls' && { starttls: { enable: smtpStartTsl } })
                    },
                    "imap": {
                        "host": imapServer,
                        "port": imapPort,
                        ...(selectedImap === 'ssl' && { ssl: { enable: imapSsl } }), // Add SSL if selected
                        ...(selectedImap === 'starttls' && { starttls: { enable: imapStartTsl } })
                    }
                }
            }
        };
        const data = {
            "attributes": [
                {
                    "key": "valuesJson",
                    "value": JSON.stringify(selectedInfo)
                }
            ]
        }
        const handleSuccess = (): void => {
            dispatch(getGoogleCalendarData())
            setImapModal(false)
            setSelectedProvider("");
            setEmail("");
            setPassword("")
        };
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(accountAddRequest(id, data, handleSuccess, handleError))
    }
    return (
        <>
            <Modal id="topmodal" isOpen={modal} modalClassName="zoomIn" centered>
                <ModalHeader className="pb-3 bg-primary" toggle={() => { tog_animationZoom() }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_calendar")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-self-center justify-content-between">
                        <div>
                            <Button color="primary" outline className={`avatar-lg ${googleSync ? 'active' : ''}`} onClick={() => handleAccountClick("google")}><img width="35" height="35" src={googleicon}></img>{t("userpreferences.gmail")}</Button>
                        </div>
                        <div>
                            <Button color="primary" outline className={`avatar-lg ${outlookSync ? 'active' : ''}`} onClick={() => handleAccountClick("outlook")}><img width="35" height="35" src={outlook}></img>{t("userpreferences.outlook")}</Button>
                        </div>
                        <div>
                            <Button color="primary" outline className={`avatar-lg ${exchangeSync ? 'active' : ''}`} onClick={() => handleAccountClick("others")}><img width="35" height="35" src={imap}></img>Other Accounts</Button>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center bg-primary-subtle">
                    <div className="mb-0 mt-3 align-items-center">
                        <Button
                            disabled={!googleSync && !outlookSync && !exchangeSync}
                            color="primary"
                            className="btn btn-primary"
                            onClick={() => {
                                if(outlookSync){
                                    setConfirmModalOutlook(true)
                                } else setConfirmModal(true);
                                setModal(false);
                                disableButton()
                            }}
                        >
                            {t("userpreferences.connect_your_calendar")}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal id="topmodal" isOpen={imapModal} modalClassName="zoomIn" centered>
                <ModalHeader className="pb-3 bg-primary" toggle={() => { tog_animationZoomImap() }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">Add other account</h5>
                </ModalHeader>
                <ModalBody>
                    <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                        <div className="p-1">
                            <div className="">
                                <div className="">
                                    <div>
                                        <Label className="mb-0 fw-bold">Credentials</Label>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Label className="fw-medium">Provider&nbsp;<span className="text-danger">*</span></Label>
                                    <div className="form-icon right">
                                        <Select
                                            value={selectedProvider}
                                            onChange={(sortBy: any) => {
                                                handleProviderSelect(sortBy)
                                                setSelectedProvider(sortBy);
                                            }}
                                            options={providers}
                                            id="choices-single-default"
                                            className="js-example-basic-single mb-0"
                                            name="state"
                                        />
                                    </div>
                                    <div>
                                        <Label className="mt-3 fw-medium">Email&nbsp;<span className="text-danger">*</span></Label>
                                        <div className="form-icon right">
                                            <Input onChange={(e) => validateEmail(e.target.value)} type="email" className="form-control form-control-icon" id="iconrightInput" placeholder="Enter email" />
                                            <i className="ri-mail-unread-line"></i>
                                        </div>
                                        {domain && domain === "invalid" ? <><Label className="text-danger">Invalid email address</Label></> : null}
                                    </div>
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-start gap-2">
                                            <div className="d-flex">
                                                <div>
                                                    <Label htmlFor="exampleInputpassword" className="form-label">
                                                        Password
                                                    </Label>
                                                </div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <div className="" id="infoPopover"
                                                onClick={togglePopover}
                                                style={{ display: "inline-block", cursor: "pointer" }}
                                            >
                                                {!popoverOpen ? (
                                                    <i className="text-info ri-information-line"></i>
                                                ) : (
                                                    <i className="text-info ri-close-circle-line"></i>
                                                )}
                                                
                                            </div>
                                            <div>
                                                <Popover
                                                    isOpen={popoverOpen}
                                                    target="infoPopover"
                                                    placement="top"
                                                >
                                                    <PopoverBody>
                                                        {/* <div className="d-flex justify-content-end">
                                                            <div className="text-danger">
                                                                <Button
                                                                    type="button"
                                                                    onClick={togglePopover}
                                                                    className="btn-close"
                                                                    aria-label="Close"
                                                                >
                                                                </Button>
                                                            </div>
                                                        </div> */}
                                                        <div>
                                                            IMAP and POP accounts may need an app password different from email password.&nbsp;
                                                            <span>
                                                                <strong>
                                                                    <a
                                                                        style={{ color: "blue" }}
                                                                        href={guideLink}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        Click here
                                                                    </a>
                                                                </strong>
                                                                &nbsp;for guidelines.
                                                            </span>
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                        </div>
                                        {/* <Label htmlFor="exampleInputpassword" className="form-label">
                                            Password&nbsp;<span className="text-danger">*</span>&nbsp;<span> <div
                                                id="infoPopover"
                                                onMouseEnter={togglePopover}
                                                style={{ display: "inline-block", cursor: "pointer" }}
                                            >
                                                <i className="ri-information-line"></i>
                                            </div>
                                            </span>
                                        </Label> */}
                                        <div className="form-icon right">
                                            <Input
                                                onChange={(e) => setPassword(e.target.value)}
                                                type={showPassword ? "text" : "password"}
                                                className="form-control form-control-icon"
                                                id="iconrightInput"
                                                placeholder="**********"
                                            />
                                            <i
                                                className={`ri-eye${showPassword ? "-off" : ""}-line`}
                                                style={{ cursor: "pointer" }}
                                                onClick={togglePasswordVisibility}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div>
                                        <Label className="mb-0 fw-bold">IMAP Configuration</Label>
                                    </div>
                                    <div className="mt-3 d-flex justify-content-between gap-1">
                                        <div style={{ width: "70%" }}>
                                            <Label>IMAP Incoming server&nbsp;<span className="text-danger">*</span></Label>
                                            <div className="form-icon right">
                                                <Input defaultValue={imapServer} onChange={(e) => setImapServer(e.target.value)} type="email" className="form-control form-control-icon" id="iconrightInput" placeholder="Enter hostname" />
                                            </div>
                                        </div>
                                        <div style={{ width: "30%" }}>
                                            <Label>Port&nbsp;<span className="text-danger">*</span></Label>
                                            <div className="form-icon right">
                                                <Input defaultValue={imapPort} onChange={(e) => setImapPort(e.target.value)} type="email" className="form-control form-control-icon" id="iconrightInput" placeholder="Port" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-between gap-1">
                                            <div style={{ width: "100%" }}>
                                                <Label>Secure connection type</Label>
                                                <div className="form-icon right">
                                                    <Select
                                                        value={sortBy}
                                                        onChange={(sortBy: any) => {
                                                            handleConnectionType("imap", sortBy)
                                                            setsortBy(sortBy);
                                                        }}
                                                        options={sortbyname}
                                                        id="choices-single-default"
                                                        className="js-example-basic-single mb-0"
                                                        name="state"
                                                    // menuPlacement="auto"
                                                    // menuPosition="fixed"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div>
                                        <Label className="mb-0 fw-bold">SMTP Configuration</Label>
                                    </div>
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-between gap-1">
                                            <div style={{ width: "70%" }}>
                                                <Label>SMTP Outgoing server&nbsp;<span className="text-danger">*</span></Label>
                                                <div className="form-icon right">
                                                    <Input defaultValue={smtpServer} onChange={(e) => setSmtpServer(e.target.value)} type="email" className="form-control form-control-icon" id="iconrightInput" placeholder="Enter hostname" />
                                                </div>
                                            </div>
                                            <div style={{ width: "30%" }}>
                                                <Label>Port&nbsp;<span className="text-danger">*</span></Label>
                                                <div className="form-icon right">
                                                    <Input defaultValue={smtpPort} onChange={(e) => setSmtpPort(e.target.value)} type="email" className="form-control form-control-icon" id="iconrightInput" placeholder="Port" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-between gap-1">
                                            <div style={{ width: "100%" }}>
                                                <Label>Secure connection type</Label>
                                                <div className="form-icon right">
                                                    <Select
                                                        value={sortBysmtp}
                                                        onChange={(sortBy: any) => {
                                                            handleConnectionType("smtp", sortBy)
                                                            setsortBysmtp(sortBy);
                                                        }}
                                                        options={sortbyname}
                                                        id="choices-single-default"
                                                        className="js-example-basic-single mb-0"
                                                        name="state"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                </ModalBody>
                <ModalFooter>
                    <div className="mb-0 mt-3 align-items-center">
                        <Button
                            color="primary"
                            className="btn btn-primary"
                            onClick={() => {
                                handleAddAccount()
                            }}
                        >
                            Add Account
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default withTranslation()(SyncOptionModal);