import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledCollapse } from "reactstrap";
import classnames from "classnames";
import Profile from "./User/Profile";
import Review from "./User/Review";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userRoleUpdateRequest, usersListGetRequest, userGetRequest } from 'store/user/action';
import { permissionsGetRequest, roleGetRequest, roleListGetRequest, roleNameListRequest } from 'store/role/action';
import ProfileCompleteness from "./User/ProfileCompleteness";
import CompanyProfile from "./Settings/CompanyProfile";

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string;
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean;
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    features: Feature[];
}

interface MenuItem {
    name: string;
    items: SubItem[];
}

interface CreateInputs {
    role: string;
    access: MenuItem[];
}

const UserProfile = () => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth?.userProfile);
    const roleDetails: any = useSelector((state: ApplicationState) => state.role.roleDetails);
    const [accessData, setAccessData] = useState<any>(null);
    const [verticalTab, setverticalTab] = useState<any>("1");
    const [openBordered, setOpenBordered] = useState<string[]>([]);
    const [cardHeaderTab, setcardHeaderTab] = useState<any>("1");
    const [createInputs, setCreateInputs] = useState<CreateInputs>({
        role: "",
        access: [],
    });

    // const toggleBordered = (id: any) => {
    //     if (openBordered !== id) {
    //         setOpenBordered(id);
    //     }
    // };

    const toggleBordered = (id: string) => {
        if (openBordered.includes(id)) {
            setOpenBordered(openBordered.filter(item => item !== id));
        } else {
            setOpenBordered([...openBordered, id]);
        }
    };

    useEffect(() => {
        dispatch(userGetRequest(id))
        dispatch(roleNameListRequest())
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [])

    useEffect(() => {
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [roleDetails, user])

    useEffect(() => {
        if (user && user.role) {
            dispatch(userGetRequest(id));
            dispatch(roleGetRequest(user.role.id))
            dispatch(permissionsGetRequest());
        }
    }, [user])

    const toggleVertical = (tab: any) => {
        if (verticalTab !== tab) {
            setverticalTab(tab);
        }
    };
    const cardHeaderToggle = (tab: any) => {
        if (cardHeaderTab !== tab) {
            setcardHeaderTab(tab);
        }
    };
    return (
        <>
            <Row className="mx-3">
                <Col xxl={12} className="" style={{ padding: "10px" }}>
                    {/* <Card>
                        <CardBody> */}
                    <Row>
                        <Col md={3} className="bg-light p-1">
                            <Nav pills className="flex-column" id="v-pills-tab">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleVertical("1");
                                        }}
                                        id="v-pills-home-tab"
                                    >
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2");
                                        }}
                                        id="v-pills-profile-tab"
                                    >
                                        Access
                                    </NavLink>
                                </NavItem>
                                {
                                    user?.role?.title === 'Owner' ?
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleVertical("3");
                                        }}
                                        id="v-pills-profile-tab"
                                    >
                                        Workspace Settings
                                    </NavLink>
                                </NavItem>
                                : null}
                            </Nav>
                        </Col>
                        <div className="border-rounnd w-75">
                            <TabContent
                                activeTab={verticalTab}
                                className="d-felx justify-content-center align-items-center text-muted ms-5 mt-0"
                                id="v-pills-tabContent"
                            >
                                <TabPane tabId="1" id="v-pills-home">
                                    <SimpleBar>
                                        <Profile />
                                    </SimpleBar>

                                </TabPane>
                                <TabPane tabId="2" id="v-pills-profile">
                                    {user && user.role.title == 'Owner' || user.role.title == 'owner' ? (
                                        <>
                                            <h4 className="">Access</h4>
                                            <p>Owner has all access and permissions.</p>
                                        </>
                                    ) :
                                        <>
                                            <Review createInputs={createInputs}
                                                accessData={accessData} />
                                        </>
                                    }

                                </TabPane>
                                <TabPane tabId="3" id="v-pills-profile">
                                    <>
                                        <div>
                                            <h4 className="">Settings</h4>
                                        </div>
                                        <div className="vstack gap-2">
                                            <Accordion className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary" id="accordionBordered" open={openBordered} toggle={toggleBordered}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId="2">
                                                        <div>
                                                        <Label className="fs-16">Company Profile</Label>
                                                        <p className="text-muted">Set company profile.</p>
                                                        </div>
                                                        
                                                        </AccordionHeader>
                                                    <AccordionBody accordionId="2">
                                                        <CompanyProfile />
                                                        </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                            <Accordion className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary" id="accordionBordered" open={openBordered} toggle={toggleBordered}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId="1">
                                                        <div>
                                                        <Label className="fs-16">Profile Completeness</Label>
                                                        <p className="text-muted">Set profile completeness criteria by adding fields which should be considered as benchmark for completion.</p>
                                                        </div>
                                                        
                                                        </AccordionHeader>
                                                    <AccordionBody accordionId="1">
                                                        <ProfileCompleteness /></AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>

                                    </>

                                </TabPane>
                            </TabContent>
                        </div>
                    </Row>
                    {/* </CardBody>
                    </Card> */}
                </Col>
            </Row>
        </>
    )
}

export default UserProfile;