import { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Button, Input } from "reactstrap";
// import { DefaultColumnFilter } from "../../Components/Common/filters";
import { withTranslation } from "react-i18next";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { startCase } from 'lodash';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import { isJson } from "utils";
import isEqual from 'lodash/isEqual';

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    pageSize,
    archive,
    handleSort,
    sortOrder,
    sortBy,
    access,
    props,
    setrecord,
    loading,
    paymentCount,
    setPaymentCount,
    setRowCount,
    rowCount,
    period,
    onhandleChange,
    onChangePaymentCount,
    tableData,
    updatedRow,
    onRowAction,
    setdisabled
}: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: 500
            },
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

    const [deleteaccess, setDeleteAccess] = useState<any>(null);
    const [dataState, setDataState] = useState(data);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setDeleteAccess(false)
        }
    }, [access, userProfile])

    useEffect(() => {
             
    }, [updatedRow])
     
      return (
        <div>
            {loading ?
                <DtsTablePlaceholder
                    columns={columns}
                    theadClass={theadClass}
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                :
                <div>
                    <Table responsive={true} {...getTableProps()} className={tableClass + 'm-0'}>
                        <tbody>
                            {
                                [...Array(rowCount)].map((_, index) => {
                                    let rest = {
                                        "label": `${startCase(period)}ly`,
                                        "key": `${period}ly${index + 1}`,
                                        "placeholder": `Enter ${period}ly ${index + 1}`,
                                        "quickCreate": true,
                                        "type": "groupinput",
                                        "values": [
                                            {
                                                "label": "Fixed",
                                                "value": "fixed",
                                                "in_forms": true
                                            },
                                            {
                                                "label": "Percent",
                                                "value": "percent",
                                                "in_forms": true
                                            }
                                        ]
                                    }
                                    let periodValue = startCase(period) + ` ${index + 1}`
                                    let a = [...Array(data?.[index]?.paymentCount)].map((_, i) => {
                                       return <div className="vstack align-items-center">
                                            <div className="hstack gap-2">
                                                <div className="hstack gap-2">
                                                    <div className="hstack fw-bold">
                                                        <FormBuilder
                                                            value={data?.[`${index}`]?.[`payment${i + 1}`]}
                                                            name={`payment${i + 1}`}
                                                            placeholder={`Enter Payment ${i + 1}`}
                                                            onChange={(value: any) => onhandleChange(value, `payment${i + 1}`, data?.[`${index}`]?.id)}
                                                            isEditState={true}
                                                            actionButtons={false}
                                                            defaultValue={data?.[`${index}`]?.[`payment${i + 1}`]}
                                                            Editable={true}
                                                            type={'groupinput'}
                                                            rest={rest}
                                                            invalid={true}
                                                            resetValue={data?.[`${index}`]?.[`payment${i + 1}`]}
                                                        />
                                                    </div>
                                                    <div className="fw-bold align-items-center">
                                                        {/* <Flatpickr className="form-control w-auto" placeholder={`Select Due Date ${i + 1}..`}
                                                            style={{ marginTop: -13, height: 37 }}
                                                            value={data?.[`${index}`]?.[`dueDate${i + 1}`]}
                                                            onChange={(date: any, altdate: any, instance: any) => {
                                                                onhandleChange(date[0], `dueDate${i + 1}`, data?.[`${index}`]?.id);
                                                                instance.close()
                                                            }
                                                            } /> */}
                                                        <Input 
                                                            type="number"
                                                            placeholder={`Enter Payment ${i + 1} Days..`}
                                                            style={{ marginTop: -13, height: 37 }}
                                                            value={data?.[`${index}`]?.[`dueDateDays${i + 1}`]}
                                                            onChange={(e: any) => {
                                                                onhandleChange(e.target.value, `dueDateDays${i + 1}`, data?.[`${index}`]?.id);
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {i > 0 && data?.[index]?.paymentCount !== 1 && data?.[index]?.paymentCount - 1 === i ?
                                                        <button className="btn btn-sm btn-soft-danger remove-list"
                                                            onClick={(e: any) => { onChangePaymentCount(data?.[index]?.paymentCount, index, 'remove'); e.preventDefault() }}
                                                        >
                                                            <i className="ri-delete-bin-fill"></i>
                                                        </button> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    return <tr className="hstack gap-5 border-1">
                                        <td className='border-0' style={{ minWidth: 200 }}><span>{periodValue}
                                            {rowCount !== 1 && rowCount - 1 === index ?
                                                <button className="btn btn-sm btn-soft-danger remove-list"
                                                    onClick={(e: any) => { e.preventDefault(); onRowAction('remove') }}
                                                >
                                                    <i className="ri-delete-bin-fill"></i>
                                                </button> : null
                                            }
                                        </span>
                                        </td>
                                        <td className='border-0'>
                                            <div className="vstack gap-3 text-center">
                                                <span>{`${startCase(period)}ly`}</span>
                                                <div className="hstack fw-bold">
                                                    <FormBuilder
                                                        value={data?.[`${index}`]?.[`${period}ly`]}
                                                        name={`${period}ly`}
                                                        placeholder={'Enter value'}
                                                        onChange={(value: any) => onhandleChange(value, `${period}ly`, data?.[`${index}`]?.id)}
                                                        isEditState={true}
                                                        actionButtons={false}
                                                        defaultValue={data?.[`${index}`]?.[`${period}ly`]}
                                                        Editable={true}
                                                        type={'groupinput'}
                                                        rest={rest}
                                                        invalid={true}
                                                        resetValue={data?.[`${index}`]?.[`${period}ly`]}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='border-0'>
                                            <div className="vstack gap-1">
                                                <div>
                                                    {a}
                                                </div>
                                                <div>   
                                                    {

                                                        data?.[`${index}`]?.totalPayment > 100 ?  <span  className="text-danger fs-12">Total of Payment % is exceeding 100%</span>: null
                                                    }
                                                </div>
                                                <div>
                                                    <Button size="sm" color='primary' onClick={(e: any) => { onChangePaymentCount(data?.[index]?.paymentCount, index, 'add'); e.preventDefault() }}>Add Payment</Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                            <Button size="sm" color='primary' className="mt-2" onClick={() => { onRowAction('add');}}>Add {period}</Button>
                        </tbody>
                    </Table>

                </div>}
        </div>
    );
};

export default withTranslation()(TableContainer);