import React, { useEffect, useState } from "react";
import { Button, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/dtsimg/logo/logo-placeholder.jpg";
import FormRender from "./FormRender";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import Logo from "Components/Common/Logo/Logo";


const Preview = ({ show, onCloseClick,title, formField, cols, formValues, onUpdate, tenant }: any) => {
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setEdit(true) 
        }
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.forms;
            if(access && access?.length) {
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                       }
                   })
               }
            }
        }
    }, [subLevelPermissionsList, userProfile])
    return (
            <Modal size="xl" backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
                <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => {onCloseClick();}}>
                    Preview
                </ModalHeader>
                <ModalBody className='p-5'>
                    <div className="mt-1">     
                    <React.Fragment>
                        <Container>  
                            <Logo tenant={tenant} height={40}/>
                            <Row>
                                <div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
                                    <Label>{title}</Label>
                                </div>
                            </Row>                       
                            <FormRender dataFields={formField || []} md={cols} lg={cols} xl={cols} BtnClassName={"w-auto justify-content-end"}  btn_text={'Submit'} data={formValues} />
                        </Container>
                    </React.Fragment>
                    </div>
                </ModalBody>
                <ModalFooter>
                <div>
                    {
                        edit ? 
                            <Button onClick={() => onUpdate(edit)} className="btn btn-primary w-md mb-3">Update Form</Button> : 
                            <Button disabled={true} className="btn btn-primary w-md mb-3"><i className=" ri-lock-2-line"></i>{" "}Update Form</Button>
                    }                    
                </div>
                </ModalFooter>
            </Modal>
    ) as unknown as JSX.Element;
};

export default Preview;