interface GoogleConfig {
  API_KEY: string;
  CLIENT_ID: string;
  SECRET: string;
}

interface MicrosoftConfig {
  CLIENT_ID: string;
  SECRET: string;
}

interface FacebookConfig {
  APP_ID: string;
}

interface ApiConfig {
  API_URL: string;
  DTS_API_DOMAIN: string;
  DTS_API_STUDENT: string;
  DTS_API_REPORT: string;
  AWS_API: string;
  url:  string;
}


interface LinkConfig {
  PRIVACY_POLICY: string;
  TERMS_OF_USE: String;
  ROLES_LEARN_MORE: string;
  TERMS_AND_CONDITIONS: string;
}

interface StripeConfig {
  SUCCESS: string;
  CANCEL: String;
}

interface Config {
  google: GoogleConfig;
  facebook: FacebookConfig;
  api: ApiConfig;
  link: LinkConfig;
  microsoft: MicrosoftConfig;
  stripe: StripeConfig;
}

const config: Config = {
  google: {
    API_KEY: "AIzaSyASk4RnMVXNULJnlLcgYTlZ0U_CI6-iW3E",
    CLIENT_ID: "132425815305-iqhroqsikvacr9f8nvsr3v3ogjhd2a8q.apps.googleusercontent.com",
    SECRET: "",
  },
  microsoft: {
    CLIENT_ID: "9dce3457-ea35-4f69-9c96-aecbda169c12",
    SECRET: '8c27c2d2-72d7-43d6-bd68-16dba08d4d16'
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-node.themesbrand.website",
    DTS_API_DOMAIN: "https://api.dts.auth.zilter.io",
    DTS_API_STUDENT: "https://api.dts.student.zilter.io",
    DTS_API_REPORT: "https://api.dts.student.zilter.io",
    AWS_API: "https://api.dts.student.zilter.io",
    url: "https://app.zilter.io"
  },
  link: {
    PRIVACY_POLICY: "https://www.zilter.io/privacy-policy.html",
    TERMS_OF_USE: "https://www.zilter.io",
    ROLES_LEARN_MORE: "https://www.zilter.io",
    TERMS_AND_CONDITIONS: "https://www.zilter.io/terms-of-service.html"
  },
  stripe: {
    SUCCESS: "https://app.zilter.io/signin",
    CANCEL: "https://app.zilter.io/signin"
  }
};
export default config;