import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import DeleteModal from './innerComponent/DeleteModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useParams } from 'react-router-dom';
import { recommendApplicationList } from 'store/recommendation/action';
import { applicationDelete, applicationGetRequest, applicationsMetadataGetRequest } from 'store/applications/action';
import { toast } from 'react-toastify';
import AddApplication from './innerComponent/AddApplication';
import axios from 'axios';
import config from "../../../../config"
import { checkTheyOwn, convertCurrency, handleSelectValue, isJson } from 'utils';
import { applicationsCommissions } from 'store/channels/action';
import { currencyGetRequest } from 'store/currency/action';



const { DTS_API_STUDENT } = config.api;

const Applications = (props: any) => {
    const { id } = useParams();
    const { isSubAgent } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.metaDataFields);
    const applicationList = useSelector((state: ApplicationState) => state.recommend.list);
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const paginationDetails = useSelector((state: ApplicationState) => state.recommend.pagination);
    const loading = useSelector((state: ApplicationState) => state.recommend.loading);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [appliedCourses, setAppliedCourses] = useState([]);
    const [create, setCreate] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [record, setRecord] = useState<any>({});
    const [access, setAccess] = useState<any>([]);
    const [metaData, setMetaData] = useState(applicationsMetadata);
    const [applications, setApplication] = useState([]);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [search, setsearch] = useState<string>("");
    const [parsedRates, setParsedRates] = useState<any>(null)
    const [assignRates, setAssignRates] = useState<any>(null)
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )


            useEffect(() => {
                dispatch(currencyGetRequest())
            }, [])
        
            useEffect(() => {
        
                if (updatedCurrency) {
                    setSelectedCurrency(updatedCurrency.selectedCurrency);
                    setCurrencySymbol(updatedCurrency.selectedCurrencySymbol)
                }
            }, [updatedCurrency]);
        
            useEffect(() => {
                if (conversionRates) {
                    setAssignRates(conversionRates)
                }
            }, [conversionRates])
        
            useEffect(() => {
                if (assignRates && assignRates?.length > 0) {
                    const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;
        
                    // Check if `targetCurrencyJson` exists and is a valid JSON string
                    const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                        ? JSON.parse(targetCurrencyJson)
                        : {};
        
                    // Set the parsed rates only if convertJson is valid
                    setParsedRates(convertJson?.conversion_rates);
                }
            }, [assignRates]);
            
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(recommendApplicationList(id, filters, 0));
    }

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
        dispatch(recommendApplicationList(id, filterObject, 0));
        setMetaData(applicationsMetadata)
    }, [])

    useEffect(() => {
        setMetaData(applicationsMetadata);
    }, [applicationsMetadata]);

    const handleLeadClick = (lead: any) => {
        setRecord(lead)
        dispatch(applicationGetRequest(lead.id))
        dispatch(applicationsCommissions(lead.id))     
    };

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(recommendApplicationList(id, filterObject, newPageNo));
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(recommendApplicationList(id, filterObject))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(applicationDelete(record.id, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])

    const handleAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
        if (permissionJson.permissions === "view") {
            return true
        }
        if (permissionJson.permissions === "view_edit") {
            return true
        }
        else if (permissions === 'role_based') {
            const items = permissionJson.item || [];
            let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
            let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
            return isview && isview.length
        }
        else return false
    }
    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

    const fetchAppliedCourses = () => {
        let filter = {
            pageSize: 100
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "token": access_token
            },
        }
        axios.get(`${DTS_API_STUDENT}/applications/student/${id}?`, config)
            .then((res: any) => {
                setAppliedCourses(res?.content)
            })
    }

    useEffect(() => {
        // fetchAppliedCourses()
    }, [])

    const isCourseApplied = (courseId: any, appliedCourses: any) => {
        if (appliedCourses && appliedCourses.length) {
            return appliedCourses.some((course: any) => {
                let valuesJson = course?.valuesJson ? course?.valuesJson : {}
                return valuesJson.course_name === courseId;
            });
        }
    };

    const capitalizeFirstLetterOfEachWord = (input: any) => {
        const exceptions = ['and', 'or', 'the', 'a', 'an', 'but', 'for', 'nor', 'on', 'at', 'to', 'by', 'with']; // Add any other exceptions you need
        return input.split(' ').map((word: any, index: any) => {
            if (exceptions.includes(word.toLowerCase()) && index !== 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        }).join(' ');
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if (access && access.length) {
                    access.map((item: any, index: any) => {

                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let columns: any[] = [];
    
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
    
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson)
                    ? JSON.parse(fieldName.valuesJson)
                    : {};
                let permissionsJson = fieldName.permissionsJson && isJson(fieldName.permissionsJson)
                    ? JSON.parse(fieldName.permissionsJson)
                    : {};
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions
                    ? handleAccess(permissionsJson)
                    : true;
    
                const isCurrencyColumn = fields?.key === "currency";
    
                if (fields.tablecolumn && isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: isCurrencyColumn || true,
                        isPrimary: fields.isPrimary,
                        Cell: (cellProps: any) => {
                            let result = isCourseApplied(cellProps.row.original.id, appliedCourses);
                            let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id);
                            let rendervalue = cellProps.row.original[fields.key];
    
                            // Hyperlink render
                            if (fields.tableRenderType === 'hyperlink' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
                                return (
                                    <div className='ms-3'>
                                        <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.key])} />
                                    </div>
                                );
                            }
    
                            // Tag render
                            else if (fields.tableRenderType === 'tag') {
                                let value = cellProps.row.original?.[fields.key];
                                let intakearray = value.split(",");
                                return (
                                    <div>
                                        {intakearray?.length && intakearray.map((item: any) => {
                                            return (
                                                <div className='ms-0' key={item}>
                                                    <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
    
                            // OptionLabel render
                            else if (fields?.key && fields?.optionLabel) {
                                rendervalue = cellProps?.row?.original?.[fields.key]?.[fields.optionLabel]
                                    ? cellProps?.row?.original?.[fields.key]?.[fields.optionLabel]
                                    : 'NA';
                            }
    
                            // Select/Radio/Checkbox render
                            if (fields.type === 'select' || fields.type === 'radio' || fields.type === 'checkboxes') {
                                rendervalue = handleSelectValue(cellProps.row.original[fields.key], fields.values);
                            }
    
                            // Application Status render
                            if (fields.key === 'application_status') {
                                rendervalue = cellProps.row.original[fields.key].charAt(0).toUpperCase() + cellProps.row.original[fields.key].slice(1).toLowerCase();
                            }
    
                            // Currency conversion for course_fee
                            if (fields.key === "course_fee" && selectedCurrency) {
                                const originalFee = parseFloat(cellProps.row?.original?.course_fee);
                                const originalCurrency = (cellProps.row?.original?.currency)?.toUpperCase().match(/[A-Z]+/g)?.[0];
    
                                if (!originalCurrency || isNaN(originalFee) || originalCurrency === selectedCurrency) {
                                    return (
                                        <div key={cellProps.column.id} style={{ verticalAlign: 'middle' }} className="align-items-center justify-content-between">
                                            <span>{rendervalue}</span>
                                        </div>
                                    );
                                }
                                const convertedFee = convertCurrency(originalFee, originalCurrency, selectedCurrency, parsedRates);
                                return (
                                    <div key={cellProps.column.id} style={{ verticalAlign: 'middle' }} className="align-items-center justify-content-between">
                                        {currencySymbol} {convertedFee?.toFixed(2)}
                                    </div>
                                );
                            }
    
                            // Currency render
                            if (fields.key == "currency") {
                                return (
                                    <div key={cellProps.column.id} style={{ verticalAlign: 'middle' }} className="align-items-center justify-content-between">
                                        {selectedCurrency}
                                    </div>
                                );
                            }
    
                            // Default render
                            return (
                                <div className="d-flex align-items-center justify-content-between">
                                    {
                                        fields.isPrimary
                                            ? <Row style={{ width: 300 }} className='justfy-content-start align-items-center'>
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                    <Link to={`/applications/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                    {
                                                        !isSubAgent && create ?
                                                            <Button
                                                                size='sm'
                                                                color="success"
                                                                className='justfy-self-end hoverbutton'
                                                                disabled={result ? true : false}
                                                                onClick={() => { handleLeadClick(cellProps.row.original); setCreateOpen(true) }}>
    
                                                                {result ? props.t("student.created") : props.t("student.create")}
                                                            </Button> : null
                                                    }
                                                </Col>
                                            </Row>
                                            : <div>{rendervalue}</div>
                                    }
                                </div>
                            );
                        }
                    };
    
                    columns.push(newColumn);
                }
            });
    
            // Ensure the "Name" column is at the beginning
            const nameColumnIndex = columns.findIndex((column) => column.isPrimary);
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0];
                columns.unshift(nameColumn);
            }
    
            // Ensure the "Currency" column is included and positioned correctly
            const currencyColumnIndex = columns.findIndex((column) => column.key === "currency");
            if (currencyColumnIndex === -1) {
                // Add the "Currency" column at the appropriate position (after Name)
                const currencyColumn = {
                    Header: "Currency",
                    accessor: "currency",
                    id: "currency",
                    filterable: true,
                    isSortable: true,
                    disableFilters: false,
                    show: true,
                    Cell: ({ row }: { row: any }) => {
                        const currency = row.original["currency"];
                        return (
                            <div className="d-flex align-items-center justify-content-between" style={{ width: 150 }}>
                                {currency ? selectedCurrency || "N/A" : "N/A"}
                            </div>
                        );
                    },
                    
                };
                columns.splice(1, 0, currencyColumn); // Insert after the "Name" column
            } else {
                // If "Currency" column exists, move it to the second position
                const currencyColumn = columns.splice(currencyColumnIndex, 1)[0];
                columns.splice(1, 0, currencyColumn); // Insert after the "Name" column
            }
    
            // Add the "Created At" column at the end
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: ({ row }: { row: any }) => {
                    const createdAt = row.original["createdAt"];
                    return (
                        <div className="d-flex align-items-center justify-content-between" style={{ width: 200 }}>
                            {createdAt ? moment(createdAt).format("lll") : ""}
                        </div>
                    );
                },
            });
    
            setColumns(columns);
        }
    }, [metaData, selectedCurrency, parsedRates]);
    
    

    useEffect(() => {
        setMetaData(applicationsMetadata)
    }, [applicationsMetadata != undefined])

    useEffect(() => {
        const parsedData: any = applicationList && applicationList.length ? applicationList.map((item: any) => {
            const valuesJson = item?.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let applications = parsedData && parsedData.length && parsedData
        setApplication(applications)
    }, [applicationList])

    useEffect(() => {
        let filters = { ...filterObject, pageSize: pageSize }
        dispatch(recommendApplicationList(id, filters))
    }, [])
    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)


        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(recommendApplicationList(id, filters));
        }, 2000);

        setTypingTimeout(newTimeout);

    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const filters = { ...filterObject }
            dispatch(recommendApplicationList(id, filters));
        }
    };

    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            <AddApplication
                show={iscreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={applicationsMetadata}
                props={props}
                record={record}
                studentId={id}
                fetchAppliedCourses={fetchAppliedCourses}
            />
            <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />
            <Row>
                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                    <div className="search-box">
                        <Input
                            type="text"
                            size={14}
                            className="search"
                            placeholder={props.t("applications.search_for")}
                            onChange={(e) => handleChange(e.target.value)}
                            onKeyDown={handleKeyDown}
                            value={search}
                        />
                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                    </div>
                </Col>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={applications || []}
                                    customPageSize={pageSize}
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    isPageSizeChange={true}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    props={props}
                                    loading={loading}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    );
};
export default withTranslation()(Applications);